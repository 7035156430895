/* GeneralInformation */
:root {
  --fontFamily: Poppins !important;
  --fontFamilykarla: karla !important;
  --fontFamilyNunito: Nunito !important;

}
.generalDetailsText {
  font-size: 22px !important;
  font-weight: 500 !important;
  color: rgb(255, 255, 255) !important;
  margin-bottom: 12px !important;
  margin-top: 44px;
  font-family: var(--fontFamily) !important;
  /* padding: 50px 50px 0px 50px !important; */
}

.example::-webkit-scrollbar {
  display: none;
}

.upload_cover {
  max-width: 500px;
  max-height: 500px;
  text-align: center;
}

.am_pm_buttons {
  display: flex;
  flex-direction: row;
  background-color: rgb(44, 43, 43);
  border-radius: 25px;
  /* padding: 5px; */
  width: 35%;
  /* text-align: center; */
  justify-content: space-between;
}

.button_grid {
  color: white;
}

.button1_am {
  color: white;
  border-color: transparent;
  background-color: rgb(44, 43, 43);
  align-items: flex-start;
  border-radius: 25px;
  margin-right: 5px;
  width: 50%;
}

.button_background {
  border-color: transparent;
  background-color: rgb(67, 32, 124);
}

.button_background_normal {
  border-color: transparent;
  background-color: transparent;
}

.button1_am:active {
  border-color: transparent;
  background-color: rgb(67, 32, 124);
}
.button1_am:focus {
  border-color: transparent;
  background-color: rgb(67, 32, 124);
}

.button2_pm:active {
  border-color: transparent;
  background-color: rgb(67, 32, 124);
}
.button2_pm:focus {
  border-color: transparent;
  background-color: rgb(67, 32, 124);
}

.button2_pm {
  color: white;
  border-color: transparent;
  background-color: rgb(44, 43, 43);
  align-items: flex-start;
  border-radius: 25px;
  width: 50%;
}

.ampm_flex {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /* margin: 2px; */
  padding: 2px;
  justify-content: center;
}

.send-popup {
  display: flex;
  flex-direction: column;
  padding: 12px;
  color: white;
  text-align: center;
  align-items: center;
}

.display_flex_date {
  display: flex;

  padding: 50px;

  text-align: center;
  align-items: center;
  justify-content: center;
}

.top_arrow {
  align-self: top;
  border: 1px solid white;
  background-color: #2c2b2b;
  height: 15%;
  width: 100%;
  border-radius: 10px;
  color: white;
}

.date_select {
  /* max-width: 100px; */
  display: flex;
  flex-direction: column;
  color: white;
  border-radius: 10px;
  font-size: 22px;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  width: 20%;
  margin-bottom: 10px;
  background-color: rgb(27, 25, 25);
}

.date_picker_popup {
  padding: 5px;
  justify-content: space-between;
  max-width: 250px;
  width: 200px;
  max-height: 300px;
  height: 200px;
}

.upload_button {
  background-color: red;
  padding: 2px;
  border-radius: 25px;
  max-width: 400px;
  width: 300px;
  max-height: 100px;
  height: 45px;
  text-align: center;
}

.capture_upload_button {
  background-color: transparent;
  padding: 2px;
  border-radius: 25px;
  max-width: 400px;
  width: 300px;
  max-height: 100px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.upload_date_button {
  background-color: red;
  border-radius: 25px;
  width: 75%;
  text-align: center;
}

.button_adjust {
  max-width: 250px;
  width: 150px;
  max-height: 100px;
  height: 40px;
  border-radius: 25px;
}

.row_button {
  background-color: rgb(34, 33, 33);
  color: rgb(194, 178, 178);
  font-weight: bold;
  text-align: center;
  max-width: 350px;
  justify-items: center;
  width: 250px;
  max-height: 100px;
  height: 50px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
}

.date_row {
  color: rgb(218, 220, 231);
  font-size: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  font-weight: bold;
}

.upload_file {
  max-width: 250px;
  width: 200px;
  max-height: 100px;
  height: 50px;
  background-color: #575252;
}

.pop-up-title {
  color: rgb(194, 178, 178);
}

.subscribe_div {
  padding: 0px 6%;
  max-width: 690px;
}

.subscribe_plane {
  background-color: #3d3c3d;
  border-radius: 15px;
  height: 55px;
  font-weight: 600;
  font-size: 17px;
}

.selling_buttons button:hover {
  background-color: rgb(53, 53, 255);
  transition: 0.5s;
}

.selling_buttons {
  justify-content: space-evenly;
  /* margin: 40px 0; */
  width: 330px;
  padding: 10px;
}

.selling_buttons h1 {
  color: white !important;
  font-weight: bolder;
}

.selling_buttons h2 {
  color: white;
}

.selling_buttons button {
  color: white !important;
  background-color: rgb(63, 63, 63);
  border-radius: 15px;
  border: none;
  /* width: 100%; */
  margin: 10px 0;
  padding: 15px 0;

  transition: 0.5s;
  cursor: pointer;
}

.text_area textarea.ant-input {
  border-radius: 12px !important;
  height: 315px !important;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 50%) !important;
  border: solid 1px #1f1f1f !important;
  background-color: #1a1b1f !important;
  color: white !important;
  /* overflow: scroll; */
  /* overflow-y: scroll ; */
}

.text_area.textarea.ant-input::-webkit-scrollbar-thumb {
  border-radius: 6px !important;
  border: solid 1px #a1a1a1 !important;
  background-color: #a1a1a1 !important;
}

/* .terms::-webkit-scrollbar {
    width: 12px;
    
    border-radius: 6px;
  
    border: solid 1px #908F90;
  
    background-color: #908F90;
   
  } */
.text_area.textarea.ant-input::-webkit-scrollbar-track {
  border-radius: 7px !important;
  background-color: #464546 !important;
}

.trackdiv {
  outline: rgb(151, 151, 151) dashed 1px;
  height: 141px;
  background-color: rgba(216, 216, 216, 0.06);
  border-radius: 14px;
  position: relative;
}

.trackBox {
  margin: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.align-center {
  text-align: center;
}

.add-circle {
  font-size: 30px;
  color: red;
}

.trackText {
  font-size: 22px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  font-family:  var(--fontFamilykarla) !important;
}

.track-subtitle {
  font-size: 12px;
  font-weight: normal;
  color: rgb(159, 175, 181);
  text-align: center;
  font-family:  var(--fontFamilyNunito) !important;
}

.upload-text {
  color: rgb(143, 146, 161);
  font-size: 12px;
  font-weight: normal;
  font-family:  var(--fontFamilykarla) !important;
}

.date-text {
  color: rgb(143, 146, 161);
  font-size: 12px;
  font-weight: normal;
  /* margin: 19px 0px 0px; */
}

.drop_btn {
  text-align: left;
}

.formControl {
  width: -webkit-fill-available;
  margin-bottom: 7px !important;
}

.white {
  color: white !important;
}

.chip-label {
  background-color: rgb(82, 82, 82) !important;
  color: white !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  margin: 0px 0px 0px 5px !important;
}

.title-text {
  color: rgb(143, 146, 161);
  font-size: 12px;
  font-weight: normal;
  margin-top: 18px;
  margin-bottom: 0px !important;
  /* margin: 10px 0px; */
}

/* .title-input{
    border-width: 0px 0px 0.5px;
    background-color: transparent;
    outline: 0px;
    border-color: white;
    width:100%
} */
.title-input .ant-input {
  background-color: transparent !important;
  border-color: #17181c !important;
  border-bottom: 1px solid #7a7e8a !important;
  color: white !important;
  padding: 4px 0px !important;
}

.title-input .ant-input::placeholder {
  padding: 0px !important;
  color: #bfbfbf !important;
}

.title-input .ant-input:focus,
.ant-input-focused {
  box-shadow: none !important;
  border-bottom: 1px solid #7a7e8a !important;
}

.title-input .MuiInput-underline:before {
  border-bottom: 1px solid #7a7e8a !important;
}

.title-input .MuiInput-underline:focus {
  border-bottom: 1px solid #7a7e8a !important;
}

.title-input .MuiInputBase-input:focus {
  border-bottom: 1px solid #7a7e8a !important;
  outline: none !important;
}

.Mui-focused {
  border-bottom: 1px solid #7a7e8a !important;
}

/* .title-input  */

.playList-label {
  color: white !important;
  margin-left: 34px !important;
}

.release-parent-div {
  display: flex;
  justify-content: space-between;
}

.release-date-text {
  color: rgb(143, 146, 161);
  font-size: 12px;
  font-weight: normal;
  /* margin: 18px 0px 0px; */
  text-align: start;
}

/* CoverUpload */
.relative {
  position: relative;
}

.baseline {
  display: flex;
  align-content: baseline;
}

.track_playIcon .anticon svg {
  fill: #3959f9 !important;
  height: 24px !important;
  width: 24px !important;
  margin-left: -1px !important;
  /* border-radius: 50% !important;
    background-color: white !important; */
}

.addtrack_playIcon .anticon svg {
  fill: #0072ff !important;
  height: 24px !important;
  width: 24px !important;
  margin-left: -1px !important;
}

.track-list {
  height: 40px;
  padding: 8px 12px 8px 11px;
  border-radius: 12px;
  border: 1px solid rgb(82, 82, 82);
  background-color: rgb(36, 36, 36);
  margin: 10px 0px !important;
  display: flex;
  max-width: 700px;
}

.trackDetailsDiv {
  outline: rgb(151, 151, 151) dashed 1px;
  height: 141px;
  background-color: rgba(216, 216, 216, 0.06);
  border-radius: 14px;
  position: relative;
  margin: 24px 0px !important;
  padding: 28px 102px 24px 107px;
}

.trackDetails-sub {
  margin: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.track-name {
  font-size: 14px;
  font-weight: bold;
  color: rgb(255, 255, 255);
}

@media screen and (min-width: 250px) and (max-width: 350px) {
  .track-name {
    margin-left: 12px;
  }
}

.timestamp {
  font-size: 14px;
  color: rgb(93, 108, 118);
}

.absolute {
  position: relative;
  right: 0px;
  bottom: 0px;
  /* height: 100%; */
  margin-top: 100%;
}


.next {
  width: 100%;
  height: 60px;
  padding: 19px 78.8px 19px 78px;
  border-radius: 30px !important;
  background-color: rgb(255, 0, 0) !important;
  text-align: center;
  font-size: 16px !important;
  font-weight: bold !important;
  color: rgb(255, 255, 255) !important;
  margin-bottom: 10px !important;
}

.saveAsDraft {
  width: 100%;
  height: 60px;
  padding: 19px 78.8px 19px 78px;
  border-radius: 30px !important;
  background-color: #707070 !important;
  text-align: center;
  font-size: 16px !important;
  font-weight: bold !important;
  color: rgb(255, 255, 255) !important;
}

.add_track_btn .ant-btn {
  font-size: 16px !important;
}

/* HorizontalStepper */
.addTrack_stepper .MuiStepper-alternativeLabel {
  margin-bottom: 35px !important;
  max-width: 530px !important;
  min-width: 260px !important;
}

.stepper-div {
  background-color: rgb(18, 18, 18) !important;
  padding: 0px !important;
  width: -webkit-fill-available !important;
}

.description {
  height: 216px;
  border-radius: 12px;
  background-color: rgb(26, 27, 31);
  color: white;
  border: 1px solid black;
  padding: 10px;
  width: -webkit-fill-available !important;
}

.p-50_coverUpload_div {
  padding: 30px 50px 50px 30px !important;
}

/* @media screen and (max-width:1277px) {

    .stepper-div{
        margin-top: 63px !important;
    }
} */
/* selling preference */

.download-grid {
  width: 275px;
  height: 162px;
  padding: 13px 26px;
  border-radius: 16px;
  /* border: 4px solid rgb(57, 89, 249); */
  /* background-color: rgb(57, 89, 249) !important; */
}

.selected-pre {
  background-color: rgb(57, 89, 249) !important;
}

.download-text {
  font-size: 32px !important;
  font-weight: bold;
  color: rgb(255, 255, 255);
  text-align: center;
}

.description-text {
  font-size: 16px;
  text-align: center;
  color: rgb(255, 255, 255);
}

.subscription-div {
  width: 275px;
  height: 162px;
  padding: 13px 26px;
  border-radius: 16px;
  /* background-color: #3d3c3c !important; */
}

/* trackSplit */

.trackSplit-parentdiv {
  padding: 12px 17px 15px 16px;
  border-radius: 8px;
  background-color: rgb(26, 27, 31);
  width: 587px;
  min-height: 260px;
}

.track-subtitles {
  font-size: 12px;
  font-weight: normal;
  color: rgb(159, 175, 181);
}

.single_select .ant-select-arrow {
  color: white !important;
}

.error_msg .ant-form-item {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  margin-bottom: 0px !important;
  vertical-align: top;
}

.trackSplit_input .ant-input {
  background-color: rgb(26, 27, 31) !important;
  border-color: rgb(26, 27, 31) !important;
  border-bottom: 1px solid #7a7e8a !important;
  color: white !important;
  padding: 4px 0px !important;
  width: 100% !important;
}

.trackSplit_input .ant-input::placeholder {
  padding: 0px !important;
  color: #bfbfbf !important;
}

.trackSplit_input .ant-input:focus,
.ant-input-focused {
  box-shadow: none !important;
}

.trackSplit_input .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.trackSplit_input
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding-bottom: 0px !important;
}

.trackSplit_input .ant-form-item-explain-error {
  text-align: start !important;
  text-align: left !important;
  /* margin-top: -8px !important; */
}

/* .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector{
    border-bottom: 1px solid #7a7e8a !important
  } */
.trackSplit_input .ant-form-item-explain,
.ant-form-item-extra {
  color: white !important;
  text-align: end !important;
  margin-top: 0px !important;
  cursor: pointer !important;
  align-self: start !important;
}

.trackSplit_input .ant-input-affix-wrapper {
  background-color: #17181c !important;
  border-color: #17181c !important;
  border-bottom: 1px solid #7a7e8a !important;
  padding: 0px !important;
}

.trackSplit_input .ant-input-affix-wrapper > input.ant-input {
  border-bottom: none !important;
}

.trackSplit_input .ant-form-item-label > label {
  color: #8f92a1 !important;
}

.addsplit {
  /* width: 88px; */
  /* height: 22px; */
  font-size: 13px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.83; */
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  background-color: #757678 !important;

  /* width: 94px !important;
height: 28px !important;
font-size: 12px !important;
font-weight: bold !important;
border-radius: 30px;
background-color: rgb(255, 255, 255) !important;
float: right !important;
margin-top: 10px !important;
display: block !important; */
}

.addsplit button:hover {
  background-color: #757678 !important;
}

.artist-list {
  border-radius: 8px;
  background-color: rgb(26, 27, 31);
  padding: 11px 18px 10px;
  margin: 24px 0px 2px !important;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
  .artistName {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: rgb(255, 255, 255);
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 265px;
  }
}

@media screen and (min-width: 1400px) {
  .artistName {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: rgb(255, 255, 255);
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 430px;
  }
}

@media screen and (min-width: 680px) and (max-width: 992px) {
  .artistName {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: rgb(255, 255, 255);
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
  }
}

@media screen and (min-width: 480px) and (max-width: 680px) {
  .artistName {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: rgb(255, 255, 255);
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
  }
}

@media screen and (min-width: 250px) and (max-width: 340px) {
  .artistDetail {
    display: contents !important;
  }
}

.artistPhoto {
  width: 47px;
  height: 47px;
}

@media screen and (min-width: 350px) and (max-width: 480px) {
  .artistPhoto {
    width: 40px;
    height: 40px;
  }
}

@media screen and (min-width: 345px) and (max-width: 480px) {
  .artistName {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 680px) {
  .artistName {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: rgb(255, 255, 255);
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 100px;
    max-width: 200px;
  }
}

.artistText {
  color: rgb(255, 255, 255);
  text-align: left;
  font-size: 12px;
}

.percentagediv {
  text-align: center;
  color: rgb(57, 89, 249);
  font-size: 18px;
  font-weight: bold;
  float: right;
}

.cross button {
  font-size: 18px;
  background-color: transparent !important;
  color: white;
  float: right;
  margin-top: 2px !important;
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

/* uploadComplete */

.rental-manager-progress-bar-container div {
  background-color: #0d0e11;
  font-weight: bold;
  color: #fff;
  border: 2px solid #212428;
  margin-top: -45px;
  width: 45px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border-radius: 50%;
  z-index: 99;
}

.rental-manager-progress-bar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #212428;
  padding-top: 18px;
  margin-top: 55px;
}

/* .complete-parentDiv{
    display: flex;
    position: relative;
    justify-content: center;
  } */

.pd70 {
  padding-top: 70px;
}

.upload-complete {
  font-size: 28px;
  font-weight: 800;
  /* text-align: center; */
  color: rgb(255, 255, 255);
}

/* .complete-button-div{
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
} */

.myDashobard-button {
  width: 303px;
  height: 60px;
  padding: 19px 78.8px 19px 78px;
  border-radius: 30px !important;
  background-color: rgb(255, 0, 0) !important;
  text-align: center;
  font-size: 16px !important;
  font-weight: bold !important;
  color: rgb(255, 255, 255) !important;
  margin-bottom: 10px !important;
}

.myProfile-div {
  width: 303px;
  height: 60px;
  padding: 19px 78.8px 19px 78px;
  border-radius: 30px !important;
  background-color: rgb(36, 36, 36) !important;
  text-align: center;
  font-size: 16px !important;
  font-weight: bold !important;
  color: white !important;
}

.coverImg_uploader_addTrack .ant-upload.ant-upload-select-picture-card {
  width: 100% !important;
  height: 100px !important;
  margin-right: 0px !important;
  object-fit: fill;
  border: 0px !important;
  border-radius: 15px !important;
}


.coverImg_uploader_addTrack
  .ant-upload.ant-upload-select-picture-card
  > .ant-upload {
  background-color: rgb(48, 48, 48) !important;
  border-radius: 13px !important;
  /* border: 1px solid white !important;
    border-style: dashed !important;
    border-radius: 15px !important; */
  /* background-color: rgb(64 64 64) !important; */
  /* border: 1px solid white !important; */
}

.parent-grid {
  background-color: #171717;
  display: flex;
  justify-content: center;
}

.parent-grid-height {
  height: 860px;
}

@media screen and (max-width: 900px) {
  .parent-grid-height {
    height: 500px;
  }
}

.parent-sub-grid {
  border: solid 1px #1f1f1f;
  /* border: solid 1px red; */
  margin: 51px;
  background-color: #121212;
  border-radius: 20px;
  /* padding: 28px 45px 28px 49px; */
}

.bgColor {
  background-color: #1a1b1f !important;
}

.borderRadius {
  border-radius: 50% !important;
}

.date_picker .ant-picker {
  width: 100% !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
  padding: 0px !important;
}

.date_picker .ant-picker-input > input::placeholder {
  color: #bfbfbf !important;
  padding: 0px !important;
}

.date_picker .ant-picker-suffix {
  /* color: white !important; */
  display: none !important;
}

.date_picker .ant-picker-clear {
  background-color: transparent !important;
}

.date_picker .ant-picker-input > input:hover {
  border: none !important;
  border-bottom: 0px solid rgb(129, 129, 129) !important;
}

.date_picker .ant-picker-input {
  border-bottom: 1px solid rgb(129, 129, 129) !important;
}

.date_picker .ant-picker-input > input:focus {
  border-color: 0px solid rgb(129, 129, 129) !important;

  border-bottom: 0px solid rgb(129, 129, 129) !important;
  /* border-bottom: transparent !important; */
}

.date_picker .ant-picker-input > input {
  border-bottom: 0px !important;
  border: 0px !important;
}



.add_track_btn {
  float: right !important;
  margin-top: 23em !important;
}

#trackList_in_trackSplitPage > div > div > .timestamp {
  float: right;
  margin-right: -8px !important;
}

@media screen and (max-width: 1345px) {
  /* Track Split Page */
  #trackList_in_trackSplitPage {
    height: 75px;
  }

  #trackList_in_trackSplitPage > div {
    display: grid;
    grid-template-columns: 12% 88%;
  }

  #trackList_in_trackSplitPage > div > div > .timestamp {
    margin-right: -80px !important;
  }
}


.play-button-width{
    width: 10%;
}

.track-name-width{
    width: 60%;
}

.playtime-width{
  width: 10%
}

@media screen and (min-width: 1000px) and (max-width: 1210px) {
    .track-name-width{
        width: 80%;
    }

    
}


@media screen and (min-width: 350px) and (max-width: 960px) {
    .track-name-first{
        width: 90px;
    }

    
}



@media screen and (min-width: 600px) and (max-width: 1279px) {
    .cover-image-in-medium{
       width: 250px;
       margin-left: 32%;
    } 
}

@media screen and (min-width: 350px) and (max-width: 360px) {
    .track-list{
        height: 65px;
    }
}

@media screen and (min-width: 350px) and (max-width: 380px) {
    
    .track-name{
        width: 125px;
    }
}


@media screen and (min-width: 350px) and (max-width: 960px) {
    
    .track-name{
        width: 125px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1210px) {
    
    .track-name{
        width: 105px;
    }
}

@media screen and (max-width:1279px) {
    .add_track_btn {
        margin-top: 50px !important;
        float: none !important;
        justify-content: center !important;
    }

  .add_track_btn_innerDiv {
    width: 50% !important;
    margin-left: 90px;
  }

  /* Track Split Page */
  #trackList_in_trackSplitPage > div {
    display: grid;
    grid-template-columns: 15% 85%;
  }

  #trackList_in_trackSplitPage > div > div > .timestamp {
    margin-right: -85px !important;
  }
}

@media screen and (max-width: 1150px) {
  /* Track Split Page */
  #trackList_in_trackSplitPage > div {
    display: grid;
    grid-template-columns: 18% 82%;
  }
}

@media screen and (max-width: 960px) {
  /* Track Split Page */
  #trackList_in_trackSplitPage {
    height: 40px !important;
    max-width: 500px !important;
  }

  #trackList_in_trackSplitPage > div {
    display: flex;
  }

  #trackList_in_trackSplitPage > div > div > .timestamp {
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 771px) {
  .add_track_btn_innerDiv {
    margin-left: 60px;
  }
}

@media screen and (max-width: 625px) {
  .add_track_btn {
    margin-top: 25px;
  }

  .add_track_btn_innerDiv {
    margin-left: 25px;
  }

  .add_track_btn .ant-btn {
    font-size: 12px !important;
    height: 50px;
  }
}

@media screen and (max-width: 501px) {
  .add_track_btn {
    margin-top: 15px;
  }

  .add_track_btn_innerDiv {
    margin-left: -18px;
  }

  .add_track_btn .ant-btn {
    font-size: 12px !important;
    width: 28vw !important;
    height: 52px;
  }

  /* Track Split Page */
  #trackList_in_trackSplitPage {
    height: 60px !important;
  }

  #trackList_in_trackSplitPage > div {
    display: grid;
    grid-template-columns: 12% 88%;
  }

  #trackList_in_trackSplitPage > div > div > .track-name {
    text-align: left;
  }

  #trackList_in_trackSplitPage > div > div > .timestamp {
    margin-right: -80px !important;
  }
}

@media screen and (max-width: 403px) {
  .add_track_btn .ant-btn {
    font-size: 12px !important;
    height: 50px !important;
    width: 40vw !important;
  }

  .add_track_btn_innerDiv {
    margin-left: -50px;
  }
}

.absoluteTrackDetails {
  float: right !important;
  margin-top: 32em;
  margin-right: 25px;
    right: 0px;
  position: relative;
  /* margin-top: 100%; */
}

@media screen and (max-width: 993px) {
  .absoluteTrackDetails .ant-btn {
    font-size: 13px !important;
    height: 54px !important;
  }
}

@media screen and (max-width: 960px) {
  .absoluteTrackDetails {
    float: none !important;
    margin-top: 75px !important;
  }

  .nextBtns_InnerDiv {
    width: 50% !important;
    margin-left: 60px !important;
  }
}

@media screen and (max-width: 675px) {
  .nextBtns_InnerDiv {
    width: 60% !important;
    margin-left: 25px !important;
  }
}

@media screen and (max-width: 536px) {
  .nextBtns_InnerDiv {
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 360px) {
  .nextBtns_InnerDiv {
    width: 70% !important;
    margin-left: -25px !important;
  }
}

@media screen and (max-width: 321px) {
  .absoluteTrackDetails .ant-btn {
    font-size: 10px !important;
    height: 48px !important;
  }

  .nextBtns_InnerDiv {
    width: 75% !important;
  }
}

#tracksplits_outerDiv {
  margin-top: 275px !important;
  margin-right: 0 !important;
}

.tracksplits_innerDiv {
  margin-left: 16px !important;
}

@media screen and (max-width: 960px) {
  #tracksplits_outerDiv {
    margin-top: 75px !important;
  }

  .tracksplits_innerDiv {
    width: 50% !important;
    margin-left: -16px !important;
  }
}

@media screen and (max-width: 516px) {
  .tracksplits_innerDiv {
    width: 60% !important;
    margin-left: -35px !important;
  }
}

.coverupload_innerdiv {
  margin-top: 300px;
}

@media screen and (max-width: 960px) {
  .sellingpref_tabsWrapper {
    justify-content: center !important;
    margin-left: 0 !important;
  }

  .coverupload_innerdiv {
    width: 40% !important;
    margin-top: 75px !important;
  }
}

@media screen and (max-width: 526px) {
  .coverupload_innerdiv {
    width: 60% !important;
  }
}

@media screen and (max-width: 352px) {
  .coverupload_innerdiv > button {
    font-size: 13.5px !important;
  }

  .trackList_playerWrapper {
    display: grid;
    grid-template-columns: 10% 90%;
  }

    .track-name,
    .timestamp {
        width: 100px;
    }

    .track-list {
        height: 65px;
        width: 100px;
    }

  .timestamp {
    margin-left: 30px;
  }
}

@media screen and (max-width: 1500px) {
  #track-list-col_trackDetailsPage {
    height: 65px;
    width: 225px;
  }

  #trackList_in_trackDetailsPage {
    display: grid;
    grid-template-columns: 10% 90%;
  }

  #trackList_in_trackDetailsPage > div > .track-name {
    width: 12.5em;
    padding: 0 !important;
    text-align: left;
    margin-left: 5px;
  }

  #trackList_in_trackDetailsPage > div > .timestamp {
    width: 4.7em;
    padding-left: 2.25em;
  }
}

@media screen and (max-width: 1494px) {
  .sellingPrefPage_HorizontalStepper > div {
    margin-left: -40px !important;
  }

  #trackListCol_in_CoverUpload > div {
    width: 300px !important;
  }

  #trackListCol_in_CoverUpload {
    margin-left: -120px !important;
  }

  #trackListCol_in_CoverUpload > div > div > .timestamp {
    float: right;
  }
}

@media screen and (max-width: 1445px) {
  .sellingPrefPage_HorizontalStepper > div {
    margin-left: -50px !important;
  }
}

@media screen and (max-width: 1340px) {
  .sellingPrefPage_HorizontalStepper > div {
    margin-left: -60px !important;
  }
}

@media screen and (max-width: 1220px) {
  .sellingPrefPage_HorizontalStepper > div {
    margin-left: -80px !important;
  }

  .trackDetailsPage_HorizontalStepper > div {
    padding-left: 25px;
  }

  #track-list-col_trackDetailsPage {
    margin-right: -32px !important;
  }

  #trackList_in_trackDetailsPage > div > .track-name {
    width: 10.5em;
    padding: 0 !important;
    text-align: left;
    margin-left: 10px;
  }

  #trackList_in_trackDetailsPage > div > .timestamp {
    width: 3em;
    padding-left: 2.25em;
  }
}

@media screen and (max-width: 1130px) {
  .trackDetailsPage_HorizontalStepper > div {
    padding-right: 32px;
  }

  .sellingPrefPage_HorizontalStepper > div {
    margin-right: 0px !important;
    margin-left: -55px !important;
  }

  #trackListCol_in_CoverUpload > div {
    width: 250px !important;
  }

  #trackListCol_in_CoverUpload {
    margin-left: -60px !important;
  }
}

@media screen and (max-width: 1000px) {
  .trackDetailsPage_HorizontalStepper > div {
    padding-right: 46px;
  }

  #trackListCol_in_CoverUpload > div {
    width: 200px !important;
  }

  #trackListCol_in_CoverUpload {
    margin-left: -25px !important;
  }

  .sellingPrefPage_HorizontalStepper > div {
    margin-left: -60px !important;
  }
}

@media screen and (max-width: 1066px) {
  #track-list-col_trackDetailsPage {
    margin-right: -35px !important;
  }

  #trackList_in_trackDetailsPage > div > .track-name {
    width: 8em;
    padding: 0 !important;
    text-align: left;
    margin-left: 15px;
  }

  #trackList_in_trackDetailsPage > div > .timestamp {
    width: 1.5em;
    padding-left: 2.25em;
  }
}

@media screen and (max-width: 960px) {
  #track-list-col_trackDetailsPage {
    height: 40px;
    margin-right: 15px !important;
  }

  #trackList_in_trackDetailsPage {
    display: inline-flex;
  }

  #trackList_in_trackDetailsPage > div > .track-name {
    margin-left: 4px;
  }

  #trackList_in_trackDetailsPage > div > .timestamp {
    width: 1.5em;
    float: right;
    padding-left: 0em;
    margin-right: 16px;
  }

  /* Selling Pref Page */
  .sellingPrefPage_HorizontalStepper {
    width: 100%;
    margin-left: 25px !important;
  }

  .sellingPrefPage_HorizontalStepper > div {
    float: right;
  }

  #trackListCol_in_CoverUpload > div {
    width: 500px !important;
  }

  #trackListCol_in_CoverUpload {
    margin-left: 25px !important;
    max-width: 500px !important;
  }
}

@media screen and (max-width: 545px) {
  #trackListCol_in_CoverUpload > div {
    width: 300px !important;
  }

  #trackListCol_in_CoverUpload {
    margin-left: 25px !important;
    max-width: 300px !important;
  }
}

@media screen and (max-width: 800px) {
  .trackDetailsPage_HorizontalStepper > div {
    padding-right: 50px;
  }

  #track-list-col_trackDetailsPage {
    margin-right: 25px !important;
  }
}

@media screen and (max-width: 545px) {
  .trackDetailsPage_HorizontalStepper > div {
    padding-right: 28px;
  }
}

@media screen and (max-width: 526px) {
  .trackDetailsPage_HorizontalStepper > div {
    padding-right: 30px;
    padding-left: -15px;
  }

  #track-list-col_trackDetailsPage {
    height: 65px;
  }

  #trackList_in_trackDetailsPage {
    display: grid;
    grid-template-columns: 10% 90%;
  }

  #trackList_in_trackDetailsPage > div > .track-name {
    padding: 0 !important;
    margin-left: 8px !important;
  }

  #trackList_in_trackDetailsPage > div > .timestamp {
    padding-left: 100% !important;
    margin-right: -32px;
  }
}

@media screen and (max-width: 450px) {
  .trackDetailsPage_HorizontalStepper > div {
    padding-right: 15px;
    padding-left: 0px;
  }
}

@media screen and (max-width: 352px) {
  #track-list-col_trackDetailsPage {
    height: 65px;
    margin-left: 0px !important;
  }

  #trackList_in_trackDetailsPage {
    display: grid;
    grid-template-columns: 10% 90%;
  }

  #trackList_in_trackDetailsPage > div > .track-name {
    padding: 0 !important;
    margin-left: 15px !important;
  }

  #trackList_in_trackDetailsPage > div > .timestamp {
    padding-left: 100% !important;
    margin-right: -38px;
  }

  /* Selling Pref */
  #trackListCol_in_CoverUpload {
    height: 60px !important;
    width: 200px !important;
  }

  #trackListCol_in_CoverUpload > div > div > .track-name {
    margin-left: 8px;
  }

  /* Track Split Page */
  #trackList_in_trackSplitPage > div {
    display: grid;
    grid-template-columns: 18% 82%;
  }

  #trackList_in_trackSplitPage > div > div > .timestamp {
    margin-right: -90px !important;
  }
}

@media screen and (max-width: 320px) {
  /* Selling Pref Page */
  .sellingPrefPage_HorizontalStepper {
    margin-left: 10px !important;
  }

  /* Track Split Page */
  #trackList_in_trackSplitPage > div {
    display: grid;
    grid-template-columns: 20% 80%;
  }
}
