/* .playList-body {
  margin: 15px;
} */
.playlist-label {
  display: flex;

  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.title-part {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 10px;
  align-items: center;
}
.title-part h1 {
  color: white;
  font-size: xx-large;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: bolder;
}

.title-part button {
  background-color: rgb(31, 31, 31);
  border: solid 1px rgba(151, 151, 151, 0.18);
  border-radius: 15px;
  color: white;
}

.title-part button:hover {
  background: #274af4;
  border-color: #274af4;
}
.title-part button:focus {
  background: #274af4;
  border-color: #274af4;
}
.add-playlist button,
.add-playlist button:hover,
.add-playlist button:focus {
  border-radius: 15px;

  background-color: #d8d8d8;
  color: black;
  border: none;
  padding-left: 10px;
  padding-right: 10px;
}

.song-list {
  margin: 18px 0 0 4px;
  /* padding: 18px 14px 26px 18px;   */
  padding: 12px 10px 10px 12px;
  border-radius: 20px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #1f1f1f;
  background-color: #121212;
}

.song-list .song-div {
  /* height: 478px; */
  /* max-height: 240px; */
  overflow-y: scroll;
  padding-right: 15px;
  margin-left: 0px;
  display: flex;
  flex-direction: column;
}
.track_listing {
  /* margin: 18px 0 0 4px; */
  padding: 12px 10px 10px 12px;
 
}
.track_listing .trackList_div {
  margin-left: 0px;
  display: flex;
  flex-direction: column;
}

.trackList_div {
  margin-left: 0px;
  display: flex;
  flex-direction: column;
}
.allplaylist_listing {
max-height: 81%;
  /* height: 28vh; */
  
  overflow-x: hidden;
  overflow-y: auto;
}

.height-playlist_listing {
  /* height: 44vh; */
  /* height: 18vh; */
max-height: 32% !important;
  /* max-height: 240px; */
  /* overflow-y: scroll; */
  overflow-x: hidden;
  overflow-y: auto;
}

.trackList_div_audioplayer {
  padding-right: 15px;
  margin-left: 0px;
  display: flex;
  flex-direction: column;
}
/* .mostly-customized-scrollbar::-webkit-scrollbar {
  width: 12px;

  border-radius: 6px;

  border: solid 1px #222;

  background-color: #191919;
}


.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 6px;
  border: solid 1px #d3c9c9;
  background-color: #8e8d8d;
} */

.mostly-customized-scrollbar::-webkit-scrollbar-track {
  /* width: 12px;
  
    border: solid 1px rgba(151, 151, 151, 0.18);
    background-color: #121212; */
  background: #585858;
  border-radius: 10px;
}

/* Add a thumb */
.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
  /* border-radius: 6px;
    border: solid 1px #d3c9c9;
    background-color: #8e8d8d; */
  background-color: rgb(43, 43, 46);
  border-radius: 10px;
}


.showPlaylistDiv{
  height: 90vh !important;
  overflow-y: scroll;
}

.showPlaylistDiv::-webkit-scrollbar-thumb {
  background-color: red!important;
}

@media screen and (max-width: 700px) {
  .playlist-label {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 20px;
  }
  .title-part h1 {
    color: white;
    font-size: large;
  }
}
