.INMainPanel {
    overflow-y: auto;
}

.INMainPanel .ColumnCenter {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.INMainPanel .AICenter {
    align-items: center;
}

.INMainPanel .MuiButton-label {
    white-space: nowrap;
}

.INBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    border: solid 5px #212121;
    background-color: #070707;
    width: 351px;
    padding: 138px 24px 129px 24px;
}

.INBox .WaitBox {
    width: 151px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background-color: #212121;
    margin-bottom: 16px;
}

.INBox .WaitText {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #ff0000;
}

.INBox .SOBox .SOText {
    font-family: "Inter", sans-serif;
    font-size: 42px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}
.INBox .CNCBox {
    margin-bottom: 48px;
}

.INBox .CNCBox .CNCText {
    font-family: "Inter", sans-serif;
    font-size: 22px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.INBox .NTCBox {
    width: 246px;
}

.INBox .NTCBox .NTCText {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

@media screen and (max-width: 1079px) {

    .INMainPanel {
        margin-top: 48px;
    }

}

@media screen and (max-width: 599px) {

    .INMainPanel {
        margin-top: 32px;
    }

    .INBox {
        width: 328px;
    }
}