.TermCard {
    display: flex;
    justify-content: space-between;
    width: 163px;
    height: 96px;
    align-items: center;
    padding: 6px 0 7px 7px;
    border-radius: 10px;
    border: solid 1px #212121;
    background-color: #070707;
    cursor: pointer;
}

.TermCard.SelectedTerm{
    background-color: #3959f9;
    cursor: none;
}

.TermDurationBox {
    display: flex;
    justify-content: center;
    width: 59px;
    height: 83px;
    align-items: center;
    border-radius: 10px;
    background-color: #212121;
}

.TermMonthBox{
    width: 70px;
}

.CommonText.TermDuration {
    font-size: 62px;
    color: white;
    font-weight: 900;
}

.CommonText.TermDurationType {
    font-size: 16px;
    color: white;
    font-weight: 800;
}

.CommonText.TermText {
    font-size: 14px;
    color: white;
    font-weight: normal;
}

.TermGrid{
    height: 108px;
    display: flex;
    align-items: center;
}

.TermOddGrid{
    justify-content: flex-end;
    padding-right: 16px;
}


@media screen and (max-width: 599px) {

    .TermOddGrid {
        padding-right: 8px;
    }

    .TermEvenGrid{
        padding-left: 4px;
    }

}