
  
  .example::-webkit-scrollbar {
    display: none;
  }
  
  .hoverloc:hover {
    border-radius: 7px !important;
    padding: 5px;
    padding-left: 3px;
    background-color: rgb(29, 28, 28);
    border: 1px solid rgb(102, 102, 102);
  }

.typehove{
    background-color: rgb(40, 40, 40);
}

  .typehove:hover{
    background-color: rgb(69, 36, 146);
  }
  

  .button-upload-types{
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: white;
    border-radius: 20px;
    padding: 3px 20px;
    margin: 3px 3px;
    text-transform: capitalize;
    border-color: rgba(0, 3, 3, 0);
  }

  .hide_for_MVP_release{
    display: none !important;
  }

  @media screen and (max-width: 599px) {
    .height_mobile_filter{
      max-height: 340px;
    }
     
  }
