.dialog-list-div {
  margin: 10px 0px !important;
}

.repost-dialog-btn {
  padding: 8px 62px 8px 62px !important;
  border-radius: 30px !important;
  background-color: #3959f9 !important;
  font-size: 15px !important;
  color: white !important;
}

.repost--btn-parent {
  display: flex !important;
  justify-content: center !important;
  padding-bottom: 25px !important;
}

.repost-dialog-img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 8px !important;
}

.repost-dialog-songName {
  font-size: 18px !important;
  font-weight: bold !important;
}

.repost-dialog-artist {
  font-size: 14px !important;
  font-weight: normal !important;
  color: #8f92a1 !important;
}

.repost-head {
  font-size: 16px !important;
  font-weight: normal !important;
  color: #fff !important;
}

/*****/

.material-textfield {
  position: relative;
}

.labelcaption {
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 50%;
  transform: translateY(50%) !important;
  background-color: rgb(18, 18, 18);
  color: #8f92a1;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}
.inputCapation {
  width: 100%;
  font-size: 1rem;
  outline: none;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 1px solid #ffffff !important;
  border-radius: 0px !important;
  padding: 1rem 0.7rem;
  color: #ffffff;
  background-color: rgb(18, 18, 18);
  transition: 0.1s ease-out;
}
.inputCapation:focus {
  border-color: #ffffff;
}
.inputCapation:focus + .labelcaption {
  color: #8f92a1 !important;
  top: 0;
  transform: translateY(-50%) scale(0.9) !important;
}
/* .inputCapation:not(focus) + .labelcaption {
  color: #8f92a1 !important;
  top: 0;
  transform: translateY(-50%) scale(0.9) !important;
} */
.inputCapation:not(:placeholder-shown) + .labelcaption {
  top: 0;
  transform: translateY(-50%) scale(0.9) !important;
}
/* .inputCapation:placeholder-shown + .labelcaption {
  top: 0;
  transform: translateY(-50%) scale(0.9) !important;
} */
