:root {
  --fontFmaily: Inter;
}

.PlanDialog {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.update_plan {
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.update_plan h1 {
  /* font-size: 32px; */
  color: #ffffff;
  font-weight: 900;
}

.paperBack {
  width: 75%;
  height: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* border: 2px solid; */
  /* border-radius: 33px; */
  align-items: center;
  background: black url(../../assets/background.png) no-repeat center center;
  background-size: 100% 100%;
}

.plan_description {
  padding: 8px 82px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.fontWeight {
  display: inline-block;
  width: 250px;
  /* border: 2px solid red; */
  font-size: 16px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

.canceledPlan {
  padding-bottom: 10px;
  cursor: pointer;
  /* width: 74%; */
}

.my_plan_Btn {
  width: 300px;
  height: 50px;
  border-radius: 30px;
  font-family: var(--fontFmaily);
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  /* background-color: #3959f9; */
  cursor: pointer;
  border: none;
}

@media screen and (max-width: 1160px) {
  .my_plan_Btn {
    width: 85%;
  }
}

@media screen and (max-width: 1150px) {
  .updatePlanBtn img {
    width: 85%;
  }
}

@media screen and (max-width: 750px) {
  .fontWeight {
    width: 243px;
  }
}

@media screen and (max-width: 650px) {
  .updatePlanBtn img {
    width: 60%;
  }
  .paperBack {
    width: 75%;
    height: 75%;
    display: flex;
    flex-direction: column;

    background: black url(../../assets/mobileBackground.png) no-repeat center
      center;
    background-size: 100% 100%;
  }
  .update_plan {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    height: 100%;
    padding: 20px;
  }

  .my_plan_Btn {
    height: 45px;
    font-size: 18px;
  }
}

@media screen and (max-width: 450px) {
  .updatePlanBtn img {
    width: 70%;
  }
}

@media screen and (max-width: 400px) {
  .updatePlanBtn img {
    width: 80%;
  }
}

@media screen and (max-width: 350px) {
  .updatePlanBtn img {
    width: 90%;
  }
  .update_plan h1 {
    font-size: 20px;
  }

  .fontWeight {
    width: 180px;
  }
}

/* @media screen and (max-width: 326px) {
  .update_plan h1 {
    font-size: 20px;
  }
} */
