.searchPlaylist_btn Button,
.searchPlaylist_btn Button:hover,
.searchPlaylist_btn Button:focus {
    font-size: 12px;
    background-color: #424b5a;
    border-radius: 13px;
    border: none;
    color: #a7a8aa;
    font-weight: 500;
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
}

.main-scroll-playlist {
    width: 100%;
    min-height: 60px;
    max-height: 600px;

    overflow-y: auto;
    overflow-x: hidden;
}


.playlist-scroll-wih-audio::-webkit-scrollbar {
    width: 15px;
  }
  
  /* Track */
.playlist-scroll-wih-audio::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
.playlist-scroll-wih-audio::-webkit-scrollbar-thumb {
    background: red; 
    border-radius: 10px;
  }

.playlist-scroll-wih-audio {
    width: 100%;
    height: 100%;
    /* height: 650px; */
    /* min-height: 60px; */
    /* max-height: 600px; */
    /* background-color: #fff; */
    /* border: 1px solid red; */
    overflow-y: auto;
    overflow-x: hidden;
}

@media screen and (min-width:1200px) and (max-width: 1300px) {
    .main-scroll-playlist {
        max-height: 400px;
    }
}

@media screen and (min-width:1300px) and (max-width: 1400px) {
    .main-scroll-playlist {
        max-height: 430px;
    }
}
@media screen and (min-width:1400px) and (max-width: 1500px) {
    .main-scroll-playlist {
        max-height: 500px;
    }
}

@media screen and (min-width:1500px) and (max-width: 1600px) {
    .main-scroll-playlist {
        max-height: 550px;
    }
}

/* 
.cover_img {
    width: 100%;
    max-width: 110px;
    height: 110px;
}

@media screen and (max-width: 1274px) {
    .music_btn {
        flex-direction: column;
        align-items: unset;
    }

    .play_btn {
        justify-content: end;
    }

    .play_btn_icon {
        margin-bottom: 5px;
    }
}

@media screen and (max-width: 960px) {
    .song_detail {
        flex-direction: column;
    }

    .wave_img {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .play_btn_icon {
        display: none;
    }

    .searchPlaylist_btn {
        flex-direction: column;
    }

    .searchPlaylist_btn button{
       font-size: 11px;
    }

    .play_btn_1 {
        margin-bottom: 8px;
    }

    .play_btn_1, .play_btn_2{
        justify-content: space-between;
    }

    .play_btn_1 button, .play_btn_2 button{
        width: 49%;
        margin-right: 0;
    }
} */

@media screen and (max-width: 991px) {
    .play_icon {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 767px) {
    .marginBottom-search{
        margin: 4px 0 ;
    }
    .searchPlaylist_btn Button{
        font-size: 10px;
    }
}


@media screen and (max-width: 600px){
    .wave-small{
      display: none !important;
    }

    /* .hide-playicons-in-mobile-view{
        display: none !important;
    } */
}




.follow-add-user-in-medium{
    justify-content: space-between;
}

@media screen and (max-width: 800px){
   
}

.mobile-playlist-cover{
    height: 80%;
}

@media screen and (max-width: 600px) {
    .mobile-playlist-cover{
        height: 100px;
        width: 100px;
    }
}

@media screen and (min-width: 600px) and (max-width: 800px) {
    .mobile-playlist-cover{
        height: 95%;
        width: 20%;
    }
}

@media screen and (max-width: 430px){
    .hide-playicons-in-mobile-view{
        display: none !important;
    }
}



.profile-image{
    width: 40px;
     height: 40px;
}

.playlist-image{
    height: 100%;
}

.artist-name-medium{
    font-size: 14px;
}

.add-user-button{
    height:27px;
    width:75px;
}

@media screen and (min-width:900px) and (max-width:1200px) {
    .medium-screen-width-for-image{
        width: 30%
    }
    
    .medium-screen-width-for-names{
        width: 70%;
    } 
    
    .profile-image{
        width: 35px;
         height: 35px;
    }

    .playlist-image{
        height: 120px;
    }
    .artist-name-medium{
        font-size: 10px;
    }

    
}

@media screen and (min-width: 900px) and (max-width:1050px) {
    .add-user-button{
        height:20px;
        width:45px;
        margin-left: -75px;
    }
}

@media screen and (min-width: 1049px) and (max-width:1100px) {
    .add-user-button{
        height:20px;
        width:45px;
        margin-left: -55px;
    }
}

@media screen and (min-width: 1099px) and (max-width:1200px) {
    .add-user-button{
        height:20px;
        width:45px;
        margin-left: -75px;
    }
}

.repost-button-width{
  width: 50px;
}

.share-button-width{
    width: 50px;
}

.addplaylist-button-width{
    width: 100px;

}

.follow-user-button-margin{
    margin: '5px'
}

.div1-width{
  width: 5%;
}

.div2-width{
    width: 10%;

}
.div3-width{
    width: 29%;

}
.div4-width{
    width: 26%;

}
.div5-width{
    width: 25%;

}

.div6-width{
    width: 5%;

}

@media screen and (min-width: 900px) and (max-width: 1000px) {
    .add-user-button{
        height:25px;
        width:60px;
        margin-left: -400px;
    }

    .follow-user-button-margin{
        margin-left: -400px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .add-user-button{
        height:25px;
        width:60px;
        margin-left: -95px;
    }

    .follow-user-button-margin{
        margin-left: -60px;
    }
}

@media screen and (min-width: 1450px) and (max-width: 1800px) {
    .add-user-button{
        height:25px;
        width:60px;
        margin-left: -150px;
    }

    .follow-user-button-margin{
        margin-left: -150px;
    }
}

@media screen and (min-width: 1800px) {
    .add-user-button{
        height:25px;
        width:60px;
        margin-left: -200px;
    }

    .follow-user-button-margin{
        margin-left: -200px;
    }
}

@media screen and (min-width: 800px) and (max-width: 959px) {
    .medium-screen-width-for-image{
        width: 15%;
        height: 120px;
    }
}

@media screen and (min-width: 1200px) {
    .medium-screen-width-for-image{
        width: 30%;
        height: 130px;
    }

    .medium-screen-width-for-names{
        width: 70%;
    } 

    .repost-button-width{
        width: 75px;
      }
      
      .share-button-width{
          width: 75px;
      }
      
      .addplaylist-button-width{
          width: 120px;
      
      }
      
      /* .follow-user-button-margin{
          margin-left: -95px;
      } */

      /* .add-user-button{
        height:25px;
        width:60px;
        margin-left: -95px;
    } */

    .div3-width{
        width: 22%;
    
    }
    .div4-width{
        width: 33%;
    
    }
}



.mobile-view-div{
    display: flex;
    margin-top: 10px;
    margin-left: 25px;
    justify-content: space-around;
    background-color: #161616;
    border-radius: 20px;
}

@media screen and (min-width: 431px){
    .mobile-playicons{
      display: none !important;
    }
}

.main-grid-playlist{
    display: flex;
}

@media screen and (min-width: 1550px) {
    .main-grid-playlist{
        display: flex;
        flex-direction: column;
    }
}

@media screen and (min-width: 431px) and (max-width: 550px) {
    .hide_playlist_wave{
        display: none !important;
    }
    
    .div1-width{
        width: 7%;
      }
      
      .div2-width{
          width: 15%;
      
      }
      .div3-width{
          width: 35%;
      
      }
      .div4-width{
          width: 5%;
      
      }
      .div5-width{
          width: 35%;
      
      }
}

@media screen and (min-width: 960px) and (max-width: 1050px) {
    .hide_playlist_wave{
        display: none !important;
    }
    
    .div1-width{
        width: 7%;
      }
      
      .div2-width{
          width: 15%;
      
      }
      .div3-width{
          width: 35%;
      
      }
      .div4-width{
          width: 5%;
      
      }
      .div5-width{
          width: 35%;
      
      }
}