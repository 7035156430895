:root {
  --fontFamily: Inter !important;
  --karlafontFamily: karla !important;
}

.p-banner-artwork{
border-radius: 10px !important;
    width: 180px !important;
    height: 180px !important;
}
.p-card {
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  padding: 15px;
  margin-top: 15px;
  border-radius: 15px;
  display: grid;
  grid-template-columns: 1fr 4fr 3fr;

  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #1f1f1f;
  background-color: #121212;
}
.playlistName_hidden {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 325px;
  text-transform: capitalize;
  /* width:217px;  */
  /* width: 70%; */
}
.playlist-video-title{
  font-family: var(--fontFamily) !important;
    font-size: 16px;
    font-weight: bold;
    color: white;
}

.playlist-video{
  color: white;
    font-family: var(--fontFamily) !important;
    font-size: 30px;
    font-weight: bold;
}

.playlist-track-video{
  font-family: var(--fontFamily) !important;
  font-size: 16px;
  font-weight: bold;
  color:white
}
.p-img {
  position: relative;
  display: inline-block;
}
.p-img .cover {
  height: 146px;
  width: 146px;
}
.play-icon_playlist img {
  position: absolute;
  height: 45px;
  width: 45px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.play-icon img {
  position: absolute;
  height: 45px;
  width: 45px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.p-container-head{
  padding: 90px 27px 90px !important;
  background-position: center !important;
    margin: 0px auto !important;
    background-size: cover !important;
}
/* desc */

.p-desc {
  margin-left: 20px;
  margin-bottom: auto;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
}
.p-desc h1 {
  font-size: x-large;
  font-weight: 600;
  color: #274af4;
  margin-bottom: 0;
}
.p-desc #tracks {
  color: #a7a8aa;
  font-weight: bolder;
  margin-bottom: 0;
}
.p-profile {
  /* display: grid;
    grid-template-columns: 2fr 6fr 5fr; */
  display: flex;
  gap: 20px;
}
.p-profile #profileImg {
  height: 50px !important;
  width: 50px !important;
  border-radius: 25px !important;
}
.p-profile #title {
  color: #d6d7db;
  font-size: larger;
  font-weight: bold;
  margin-bottom: 0;
}
.p-profile #artist {
  color: #a7a8aa;
  font-size: small;
  font-weight: bolder;
  margin-bottom: 0;
}
/* .button-group{
    display: flex;
    gap: 10px;
    margin-top: -10px;
} */
.button-group button,
.button-group button:hover,
.button-group button:focus {
  background-color: #30353f;
  border-radius: 15px;
  border: none;
  color: #a7a8aa;

  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .height_playlistCard {
    height: auto;
  }
}



/* 960 1048 */
@media screen and (max-width: 518px) {
  .p-banner-artwork{
    border-radius: 10px !important;
        width: 143px !important;
        height: 143px !important;
    }
    .p-container-head{
      padding: 27px 27px !important
    }

  .p-desc{
    margin-left: 0px !important;
  }
  .playlist_img_center {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .play-icon_playlist img {
    position: absolute;
    height: 45px;
    width: 45px;
    top: 64%;
    left: 50%;
    transform: translate(-44%, -111%);
  }

  .playlist_desc_center {
    width: 100%;
  }
  .single-playlist-banner{
    display: flex;
    justify-content: center;
    max-width: 100%;
  }

}

@media screen and (max-width: 1033px) {
  .p-icons {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .p-icons {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: flex-end;
    top: 10px;
    /* justify-content: flex-start; */
  }
  .button-group {
    display: flex;
    gap: 10px;
    margin-top: 23px;
  }
}
@media screen and (min-width: 767px) and (max-width: 960px) {
  .p-icons {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: flex-end;
    /* top: 25px; */
    /* right: 13%; */
    /* justify-content: start; */
  }
  .button-group {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
}
@media screen and (min-width: 960px) and (max-width: 1048px) {
  .p-icons {
    display: flex;
    gap: 7px;
    align-items: flex-end;
    top: 15px;
  }
  .button-group {
    display: flex;
    gap: 7px;
    margin-top: 25px;
  }
}

@media screen and (min-width: 1048px) and (max-width: 1200px) {
  .p-icons {
    display: flex;
    gap: 20px;
    align-items: flex-end;
    /* top: 25px; */
    justify-content: flex-end;
  }
  .button-group {
    display: flex;
    gap: 10px;
    margin-top: -10px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1320px) {
  .p-icons {
    display: flex;
    gap: 20px;
    align-items: flex-end;
    top: 25px;
  }
  .button-group {
    display: flex;
    gap: 10px;
    margin-top: -10px;
  }
}
@media screen and (min-width: 1320px) {
  .p-icons {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: flex-end;
    top: 24px;
  }
  .button-group {
    display: flex;
    gap: 10px;
    margin-top: -10px;
  }
}
.p-icons img {
  height: 45px;
  width: 45px;
}
.p-icons p {
  color: #d6d7db;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
  font-size: medium;
  font-weight: bold;
}
@media screen and (max-width: 600px) {
  .hidden_btnGrp {
    display: none;
  }
}

@media screen and (min-width: 110px) {
  .hidden_p_icons {
    display: none;
  }
}
@media screen and (min-width: 518px) {
  .profileDetails_bottom {
    display: flex;
  }
}

@media screen and (min-width: 600px) {
  .showBtn {
    display: none;
  }
}
