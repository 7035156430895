.date_select {
    /* max-width: 100px; */
    display: flex;
    flex-direction: column;
    color: white;
    border-radius: 10px;
    font-size: 22px;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    width: 20%;
    margin-bottom: 10px;
    background-color: #020202 !important;
    border: 3px solid #141414;
}


@media screen and (min-width: 540px) and (max-width: 630px) {
    .date_select {
        width: 25%;
    }
}

@media screen and (min-width: 376px) and (max-width: 540px) {
    .date_select {
        width: 30%;
    }
}

@media screen and (max-width: 376px) {
    .date_select {
        width: 29%;
    }
}

.time_select {
    /* max-width: 100px; */
    display: flex;
    flex-direction: column;
    color: white;
    border-radius: 10px;
    font-size: 22px;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    width: 20%;
    margin-bottom: 10px;
    background-color: #020202 !important;
    border: 3px solid #141414;
}


@media screen and (min-width: 540px) and (max-width: 630px) {
    .time_select {
        width: 25%;
    }
}

@media screen and (min-width: 376px) and (max-width: 540px) {
    .time_select {
        width: 32%;
    }
}

@media screen and (max-width: 376px) {
    .time_select {
        width: 35%;
    }
}


.display_flex_rlease_date {
    display: flex;
  
    padding: 50px;
  
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 430px) {
    .display_flex_rlease_date {
    padding: 25px;
    }
  }


.arrow_up_width{
    width: 80px;
}

@media screen and (min-width: 425px) and (max-width: 470px) {
    .arrow_up_width{
        width: 65px;
    }
    
}

@media screen and (min-width: 376px) and (max-width: 425px) {
    .arrow_up_width{
        width: 65px;
    }
    
}

@media screen and (max-width: 376px) {
    .arrow_up_width{
        width: 50px;
    }
    
}


.arrow_time_width{
    width: 80px;
}

@media screen and (min-width: 425px) and (max-width: 470px) {
    .arrow_time_width{
        width: 65px;
    }
    
}

@media screen and (min-width: 376px) and (max-width: 425px) {
    .arrow_time_width{
        width: 65px;
    }
    
}

@media screen and (max-width: 376px) {
    .arrow_time_width{
        width: 65px;
    }
    
}



.width-date-settime{
    width: 100%;
}


.width-time-main-settime{
        width: 45%;
}



@media screen and (max-width: 500px) {
    .width-date-setdate{
      width: 140%;
    } 

    .width-time-main-settime{
        width: 70%;
}
}


.am_pm_buttons {
    display: flex;
    flex-direction: row;
    background-color: rgb(44, 43, 43);
    border-radius: 25px;
    /* padding: 5px; */
    width: 35%;
    /* text-align: center; */
    justify-content: space-between;
}

@media screen and (max-width: 600px) {
    .am_pm_buttons {
        width: 50%;
    }
}

@media screen and (max-width: 400px) {
    .am_pm_buttons {
        width: 60%;
    }
}