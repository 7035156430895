:root {
  --fontFamily: Inter !important;
}

.TermsAndConditions {
  /* height: 120vh; */
  height: 100%;
  background-size: cover;
  background-color: black;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.term-back-btn button {
  text-align: left;
  background-color: #181818 !important;
  color: #8b8b8b !important;
  width: 80px;
  border: none;
  border-radius: 20%;
  cursor: pointer;
}
.logo img {
  width: 40px;
  /* height: auto; */
  left: 20px;
  position: absolute;
  margin: 30px 0;
  padding: 0;
}
.container {
  background-color: black;
  width: 100%;
  border-radius: 20px;
  padding: 10px 13px 10px 30px;
  margin: auto;
  border: solid 5px #141414;
}

.container h1 {
  color: white !important;
  margin: 10px 0;
}
.terms {
  max-height: 55vh;
  /* overflow: scroll; */
  overflow-y: scroll;
  overflow-x: hidden;
  color: white;
  height: 59vh;
}
/* .terms::-webkit-scrollbar {
  display: none;
} */
.terms h3 {
  padding: 10px 0 10px 0;
  font-family: var(--fontFamily) !important;
  font-size: 18px;
  font-weight: 800;
  color:white
}
.terms p {
  line-height: 1.2rem;
  font-family: var(--fontFamily) !important;
  font-size: 12px;
  color: white;
}
.agree-btn button {
  background-color: red;
  color: white;
  width: 40%;
  height: 50px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}
.agree-btn {
  text-align: center;
}

.tc_acc{
  font-family: var(--fontFamily) !important;
  font-size: 20px !important;
  font-weight: 900 !important;
}

.termsandcondition-parent{
  display: flex !important;
    flex-direction: row !important;
    height: 100% !important;
    /* justify-content: space-between; */
    align-items: center !important;
    padding: 20px !important;
}

.termsandcondition-head{
  justify-content: center !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}
.term-back-btn span {
  /* padding: 5px; */
  padding: 0px 8px 4px 0px;
}
@media screen and (max-width: 1024px) {
  .TermsAndConditions {
    padding-bottom: 25px;
  }
  .agree-btn button {
    width: 80%;
  }
  .container {
    margin-top: 50px;
  }
}
@media screen and (max-width: 786px) {
  .TermsAndConditions {
    padding: 0 !important;
  }
  .agree-btn button {
    width: 100%;
  }
  .container {
    margin-top: 80px;
  }
}
@media screen and (max-width: 425px) {
  .TermsAndConditions {
    padding: 0 !important;
  }
  .agree-btn button {
    width: 100%;
  }
  .terms {
    height: auto;
  }
  .container {
    margin-top: 80px;
  }
}
/* .terms::-webkit-scrollbar-thumb {
  border-radius: 6px;
  border: solid 1px #a1a1a1;
  background-color: #a1a1a1;
} */
/* .terms::-webkit-scrollbar {
  width: 12px;
  
  border-radius: 6px;

  border: solid 1px #908F90;

  background-color: #908F90;
 
} */
/* .terms::-webkit-scrollbar-track {
  border-radius: 7px;
  background-color: #464546;
} */

.terms::-webkit-scrollbar-thumb {
  /* border-radius: 6px;
  border: solid 1px #a1a1a1;
  background-color: #a1a1a1; */
  background-color: rgb(43, 43, 46);
  border-radius: 10px;
}

.terms::-webkit-scrollbar-track {
  /* border-radius: 7px;
  background-color: #464546; */
  background: #585858;
  border-radius: 10px;
}

.terms::-webkit-scrollbar-thumb:hover {
  background: #272829;
}
