.label-profile-top-grid {
    position: relative;
    min-height: 30vh !important;
  }

  .stats-margin{
    margin: 0px;
  }

  .custom-grid-item {
    margin: 0 !important; /* Remove default margin */
    height: 100%;
  }

  .label-mainBody-audio-withoutaudio{
    height: 90vh !important;
    overflow: auto;

  }

  .label-top-song{
    border: none
 }

 .label-top-song:hover {
   border: solid 3px #212121;
   border-radius: 5px;
 }

 .label-scroll {
  /* max-height: 224px !important; */
  overflow-y: scroll !important;
  /* overflow-x: scroll !important; */
  /* max-width: 1500px !important; */
 }



  .label-private {
    /* padding: 71px 10px 70px 10px !important; */
    /* height: 90%; */
    margin: 15px auto;
  
  }
  .label-follow-handle {
    min-height: 20% !important;
  }

@media screen and (max-width: 600px) {
    .custom-grid-item {
        margin-top: 40px !important;
    }
    /* .label-profile-top-grid {
        height: 50vh !important;
      } */
}

@media screen and (max-width: 699px){
  .hide_in_mobile{
    display: none;
  }
}

@media screen and (min-width: 700px) {
  .hide_in_medium_and_screen{
    display: none;
  }
}
