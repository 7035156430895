:root {
  --fontFmailyInter: Inter;
  --fontFamilyKarla: karla !important;
}


.image {
  position: relative;
  width: 100%;
}

.image img {
  width: 100%;
  height: 40vh;
  border-radius: 12px;
  background-attachment: fixed;
  object-fit: cover !important;
}

.image .slider-content {
  position: absolute;
  top: 50%;
  left: 35%;
  right: 0%;
  /* left: 10%; */
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  /* white-space: nowrap; */
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  /* white-space: break-spaces; */
}

.slider-content h1 {
 /* padding-left: 1vw; */
 font-size: 38px;
 font-stretch: normal;
 font-style: normal;
 line-height: normal;
 letter-spacing: -0.76px;
 /* width: 30rem; */
 width: 60rem;
 color: #fff;
 text-overflow: ellipsis !important;
 white-space: nowrap !important;
 display: block !important;
 overflow: hidden !important;
 font-family:  var(--fontFamilyKarla) !important;
 font-weight: 700 !important;
margin-bottom: 0px !important;
margin-top: -5px !important;
text-transform: capitalize !important;
}

.slider-content p {
  font-family:  var(--fontFamilyKarla) !important;

  padding-left: 1vw;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.18px;
  color: rgb(194, 194, 190);
  width: 60rem;
  text-overflow: ellipsis !important;
  word-break: break-word !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize !important;
}

.slider-content button {
  font-size: 14px;
  padding-left: 16px;
  margin-top: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.28px;
  text-align: center;
  color: #fff;
  background-color: red;
  border: none;
  outline: none;
  font-family:  var(--fontFamilyKarla) !important;
  height: 37px;
  border-radius: 23.5px;
text-transform: uppercase !important;
width: 200px !important;
}

.ant-carousel .slick-dots li button {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.ant-carousel .slick-dots-bottom {
  bottom: -27px;
}

.ant-carousel .slick-dots li.slick-active {
  width: 40px;
}

.ant-carousel .slick-dots li.slick-active button {
  width: 35px;
  height: 7px;
  border-radius: 5px;
}

/* .slick-slide {
  margin: 0 -26px;
} */
/* the parent
.slick-list {
  margin: 0 -30px;
} */
@media screen and (min-width: 800px) and (max-width: 1399px) {
  .image .slider-content {
    font-size: 100%;
    padding: 0px;
  }

  .slider-content h1 {
    font-size: 30px !important;
    text-overflow: ellipsis !important;
    /* white-space: nowrap !important; */
    display: block !important;
    overflow: hidden !important;
    width: 30rem !important;
    word-break: break-word !important;
  }
  .slider-content p {
    font-family: "Helvetica Neue";
    padding-left: 1vw;
    margin-bottom: 4px;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.18px;
    color: rgb(194, 194, 190);
    /* max-height: 75px; */
    /* width: 30rem; */
    width: 30rem;
    text-overflow: ellipsis !important;
    /* white-space: nowrap !important; */
    /* display: block !important; */
    word-break: break-word !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }

  .slider-content button {
    height: 45px !important;
    width: 200px !important;
    padding: 5px 20px !important;
    font-size: 14px !important;
    font-weight: 700 !important;

  }

}
@media screen and (min-width: 1400px) and (max-width: 1735px) {
  .image .slider-content {
    font-size: 100%;
    padding: 0px;
  }

  .slider-content h1 {
    font-size: 32px !important;
    text-overflow: ellipsis !important;
    /* white-space: nowrap !important; */
    display: block !important;
    overflow: hidden !important;
    width: 40rem !important;
    word-break: break-word !important;
    font-weight: 700 !important;
    margin-bottom: 0px !important;
    margin-top: -5px !important;
  }
  .slider-content p {
    font-size: 18px !important;
    text-overflow: ellipsis !important;
    /* white-space: nowrap !important; */
    /* display: block !important; */
    overflow: hidden !important;
    width: 40rem !important;
    word-break: break-word !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }

  .slider-content button {
    height: 45px!important;
    width: 200px!important;
    font-size: 18px!important;
    font-weight: 700 !important;
    width: 200px !important;
    padding: 5px 20px !important;
  }

}

@media screen and (min-width: 418px) and (max-width: 790px) {
  .image .slider-content {
    font-size: 100%;
    padding: 0px;
  }

  .slider-content h1 {
    font-size: 28px !important;
    text-overflow: ellipsis !important;
    /* white-space: nowrap !important; */
    display: block !important;
    overflow: hidden !important;
    width: 18rem !important;
    word-break: break-word !important;
  }
  .slider-content p {
    font-size: 18px !important;
    text-overflow: ellipsis !important;
    /* white-space: nowrap !important; */
    /* display: block !important; */
    overflow: hidden !important;
    width: 18rem !important;
    word-break: break-word !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    -webkit-box-orient: vertical;
  }

  .slider-content button {
    height: 30px !important;
    width: 85px !important;
    padding: 5px 20px !important;
    font-size: 12px !important;
  }

}

@media screen and (max-width: 416px) {
  .slider-content h1 {
    font-size: 28px !important;
    /* text-overflow: ellipsis !important; */
    white-space: nowrap !important;
    display: block !important;
    overflow: hidden !important;
    width: 14rem !important;
    /* margin-left: 37px !important; */
    /* margin-left: 18px !important; */
    word-break: break-word !important;
  }
  .slider-content p {
    font-size: 14px !important;
    text-overflow: ellipsis !important;
    /* white-space: nowrap !important; */
    /* display: block !important; */
    width: 14rem !important;
    margin-left: 37px !important;
    word-break: break-word !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
  .slider-content button {
    height: 30px !important;
    width: 85px !important;
    padding: 5px 20px !important;
    font-size: 12px !important;
    /* margin-left: 37px !important; */
  }
}

@media screen and (min-width: 1400px) and (max-width: 1700px) {
  #playlist-id .slick-slider {
    margin: 0 -15px !important;
  }

  #playlist-id .slick-list {
    margin: 0 5px !important;
  }
}

@media screen and (min-width: 1700px) {
  #playlist-id .slick-slider {
    margin: 0 -20px !important;
  }

  #playlist-id .slick-list {
    margin: 0 15px !important;
  }
}

@media screen and (max-width: 1225px) {
  .image .slider-content {
    margin-left: 50px;
    right: 0%;
    left: 29%;
    /* white-space: nowrap; */
  }
}

@media screen and (max-width: 512px) {
  .image .slider-content {
    /* margin-left: 74px; */
    margin-left: 0% !important;
  left: 50% !important;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    /* width: 336px; */
    width: 100%;
    /* border: 1px solid wheat; */
    height: auto;
    /* padding-left: 20px !important; */
    padding-left: 35px !important;
  }
}
/* 
@media screen and (max-width: 488px) {
  .image .slider-content {
    margin-left: 84px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    width: 336px;
    /* border: 1px solid wheat; */
/* height: auto; */
/* } */
/* }  */
