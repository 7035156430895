:root {
  --fontFamily: Inter !important;
}

.Sms_form .ant-input {
  background-color: #020202 !important;
  border-color: #020202 !important;
  border-bottom: 4px solid #242528 !important;
  color: white !important;
  padding: 4px 0px !important;
}
.Sms_form .ant-input:focus,
.ant-input-focused {
  box-shadow: none !important;
}
.Sms_form .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}
.Sms_form
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding-bottom: 0px !important;
}
.Sms_form .ant-form-item-explain-error {
  text-align: start !important;
  margin-top: 0px !important;
}
.Sms_form .ant-form-item-explain,
.ant-form-item-extra {
  color: white !important;
  text-align: end !important;
  margin-top: 0px !important;
  cursor: pointer !important;
  align-self: start !important;
}
.Sms_form .ant-input-affix-wrapper {
  background-color: #17181c !important;
  border-color: #17181c !important;
  border-bottom: 4px solid #242528 !important;
  padding: 0px !important;
}
.Sms_form .ant-input-affix-wrapper > input.ant-input {
  border-bottom: none !important;
}
.Sms_form .ant-form-item-label > label {
  color: #8f92a1 !important;
}

.dialog-Paper-message {
  background-color: #020202 !important;
  border-radius: 40px !important;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5) !important;
  border: solid 5px #1f1f1f !important;
  width: 380px !important;
  overflow-y: hidden !important;
  /* height: 250px !important; */
}

.red_custom_btn_sms {
  background-color: #fe0000 !important;
  border-color: #fe0000 !important;
  color: white !important;
  width: 250px;
  height: 45px;
}

.grey_custom_btn {
  background-color: #1f1f1f !important;
  border-color: #1f1f1f !important;
  color: white !important;
  border-radius: 30px;
  font-size: 16px;
  width: 250px;
  height: 45px;
}

.margin-left-div {
  margin-left: 0px;
}

.messageLink-grid {
  justify-content: center !important;
  padding: 25px 0px !important;
  flex-direction: column !important;
  align-items: center !important;
}

.messageLink-parent-div {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  width: 300px !important;
}

.messageLink-child-div {
  font-size: 14px !important;
  font-weight: bold !important;
  color: white !important;
  margin-top: 10px !important;
}

.messageLink-send {
  width: 108px !important;
  height: 33px !important;
  border-radius: 33px !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

.messageLink-inputBtn {
  width: 100% !important;
  margin: 20px 0px !important;
}

.messageLink-loader {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 80vh !important;
}

.messageLink-loadersize {
  width: 10% !important;
}

.messageLink-select-user {
  width: 100% !important;
  overflow-y: scroll !important;
  height: 55vh !important;
}

.messageLink-select-user::-webkit-scrollbar-thumb {
  background: red; 
  border-radius: 10px;
}

.messageLink-user {
  display: flex !important;
  flex-direction: column !important;
  margin-top: 5px !important;
  padding-right: 10px !important;
}

.messageLink-list {
  color: white !important;
  margin-top: 10px !important;
}

.messageLink-userid {
  display: flex !important;
  width: 100% !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.messageLink-circle-grid {
  display: flex !important;
  align-items: center;
}

.messageLink-img {
  width: 20px !important;
  height: 20px !important;
}

.messageLink-default {
  margin-left: 10px !important;
  color: white !important;
}

.messageLink-nodata {
  font-size: 20px !important;
  font-weight: bold !important;
  color: white !important;
  font-family: var(--fontFamily) !important;
  display: flex !important;
  justify-content: center !important;
}
@media screen and (min-width: 380px) and (max-width: 410px) {
  .margin-left-div {
    margin-left: -20px;
  }
}

@media screen and (min-width: 340px) and (max-width: 380px) {
  .margin-left-div {
    margin-left: -35px;
  }
}

@media screen and (max-width: 350px) {
  .margin-left-div {
    margin-left: -50px;
  }
}

.ant-upload.ant-upload-select-picture-card {
  border: 5px solid #1f1f1f;
  border-radius: 15px;
  width: 135px;
  height: 135px;
}

.search_message_link .ant-input-affix-wrapper {
  border-radius: 20px;
  background-color: rgb(40, 40, 40);
  border: 2px solid rgb(40, 40, 40);
}
.search_message_link .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}
.search_message_link
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: none !important;
}
.search_message_link .ant-input-affix-wrapper > input.ant-input {
  background-color: rgb(40, 40, 40) !important;
  color: white !important;
  padding: 4px;
}
