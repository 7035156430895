/* accountTypes.js css  */
:root {
  --fontFamily: karla !important;
}

.accbtn {
  background-color: rgb(35 36 40);
}
.accType-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: wrap;
}
.accbtn:hover {
  background-color: rgb(74 22 230 / 85%);
}

.acctype-btn {
  width: 85%;
  margin: auto;
}

.acctype-btn button {
  width: 100%;
  position: relative;
  padding: 10px;
  border-radius: 50px;
  font-weight: 600px;
  font-size: 16px;
  border: none;
  color: white;
  margin-bottom: 10px;
}

.accType-text h2 {
  margin-top: 15px;
  margin-bottom: 20px;
  color: white;
}
.accType-text p {
  color: white;
  margin-bottom: 20px;
  line-height: 20px;
}
.accType-text {
  margin-bottom: 43px;
}
.button-back button {
  color: #8b8b8b !important;
  width: 80px;

  text-align: left;
  background-color: #17181c;
  border: none;
  border-radius: 20%;
}
.button-back {
  margin: 47px 20px;
}
.button-back button span {
  padding: 5px;
}
.logo-img {
  height: 42px;
  margin: 40px 20px;
}


.account-type-grid{
  display: flex !important;
  /* justify-content: space-around; */
  align-items: center;
  /* margin: auto !important; */
}

.logo-acc-grid{
  display: flex !important;
  flex-direction: column;
  align-items: center;
  /* margin: auto !important; */
}

.select-acc-type{
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  /* margin-bottom: 35px !important; */
}


.account-type-subscription{
  display: flex !important;
  justify-content: space-around;
  align-items: center;
flex-direction: column !important;
}

.subscription-list-acc{
  background-color: black;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    border: solid 3px #212121;
    width: 100%;
    cursor: pointer;
}

.subscription-list-acc-active{
  background-color: rgb(16, 16, 16);
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    border: solid 3px white;
    width: 100%;
    cursor: pointer;
}
/* .subscription-list-acc */
.acc-next-btn{
  background-color: red;
  padding: 10px 0px 10px 0px;
  border-radius: 30px;
  color: white;
  font-family: var(--fontFamily) !important;
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  border: none;
  display: block;
    width: 100%;
    height: 100%;
  /* margin-bottom: 15px; */

}

.acc-back-btn{
  padding: 10px 0px 10px 0px;
  color: white;
  font-family: var(--fontFamily) !important;
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 15px;
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-color: black;
  border: none;

}
/* end accountTypes.js css  */
.acc-next-back-parent{
  display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    margin: auto !important;
    text-align: center !important;
    align-items: center !important;
}