:root {
  --fontFmaily: Inter;
  --fontSize: 20px;
}

.confirmMain {
  /* height: 550px; */
  /* width: 850px; */
  background-color: #000000;
  border-radius: 25px;
  border: solid 5px #323232;
  padding: 50px;
  justify-content: space-between !important;
}

.current_Info {
  /* width: 300px; */
  height: 350px;
  border-radius: 20px;
  border: solid 6px #212121;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;
}

.pay_button {
  /* width: 300px; */
  height: 50px;
  border-radius: 60px;
  border: none;
  font-size: var(--fontSize);
  font-family: var(--fontFmaily);
  font-weight: 900;
  color: #ffffff;
  background-color: #ff0000;
  cursor: pointer;
}

.keepmyplan_button {
  /* width: 300px; */
  height: 50px;
  border-radius: 30px;
  background-color: #212121;
  font-family: var(--fontFmaily);
  font-size: var(--fontSize);
  font-weight: 900;
  color: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
}

@media only screen and (max-width: 960px) {
  .current_Info {
    margin: 10px !important;
    height: 200px;
    padding: 10px;
  }
}
