/* Listener css */

:root {
  --fontFamily: Inter !important;
  --fontFamilykarla: karla !important;
}

.infoforListener {
  margin: 6px auto;
  /* margin-left: 180px; */
}
.listenerBgDark {
  background-color: black !important;
  /* height: 100vh; */
  height: 100%;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  max-width: 100% !important;

}

.aligncolumn{
  display: flex !important;
    margin-bottom: 2% !important;
}
@media screen and (max-width: 600px) {
  .aligncolumn{
    display: flex !important;
      margin-bottom: 2% !important;
      flex-direction: column !important;
  }
}
.create-account-title{
  font-family: var(--fontFamily) !important;
    font-size: 20px !important;
    font-weight: 900 !important;
}
.change-username{
  color: #8f92a1 !important;
}

.text-align{
  text-align: center !important;
}
@media screen and (max-width: 768px) {
  .listenerBgImg-welcome {
    display: none !important;
  }
  .creativeBgImg-welcome {
    display: none !important;
  }
}
.listenerBgImg-welcome {
  background-image: url('listenerRight.png');
  background-size: cover;
  height: 130vh;
}

.creativeBgDark {
  background-color: #17181c !important;
  /* height: 100vh; */
  /* height: 100%; */
  overflow-x: hidden !important;
  /* overflow-y: auto !important; */
  /* max-width: 100% !important; */
}

.fullname_acc{
  font-family: var(--fontFamily) !important;
  font-size: 16px !important;
  font-weight: bold !important;
  color:white;
  width:100% !important;
}

.account_sub_title{
  font-size: 12px !important;
  font-family: var(--fontFamilykarla) !important;
  font-weight: normal;
  width: 98% !important;
}

.next_back_account_create{
  width: 50% !important;
    margin: auto;
    /* text-align: center; */
    justify-content: center !important;
    display: flex !important;
    align-items: center !important;
    color:white !important
}

.back_btn{
  background-color: black !important;
  border: black !important;
  font-weight: 700 !important;
}
.creativeBgImg-welcome {
  background-image: url('creativeRight.png');
  background-size: cover;
  /* height: 100%; */
  background-position: center;
  background-repeat: no-repeat;
  height: 140vh;
}
.infoforListener input {
  background-color: rgb(24, 24, 24);
  border-bottom: 1px solid rgb(189, 189, 189);
  border-top: none;
  border-left: none;
  border-right: none;
  color: white;
  /* width: 500px; */
  height: 20px;
}

.infoforListener label {
  color: rgb(121, 121, 121);
  float: left;
}

.infoforListener button {
  width: 100%;
  position: relative;
  padding: 10px;
  border-radius: 50px;
  font-weight: 600px;
  font-size: 16px;
  border: none;
  background-color: red;
  color: white;
  margin-top: 20px;
  margin-bottom: 10px;
}

.formListener {
  width: 85%;
  margin: auto;
}

.listener-text h2 {
  margin-top: 15px;
  color: white;
  margin-bottom: 20px;
}
.listener-text p {
  color: white;
  margin-bottom: 20px;
  line-height: 20px;
}
.listener-text {
  margin-bottom: 43px;
}
.back-btn button {
  background-color: #262626;
  color: white;
  width: 50px;
  border: none;
  border-radius: 20%;
}

.button-back button {
  color: #8b8b8b !important;
  width: 80px;

  text-align: left;
  background-color: #17181c;
  border: none;
  border-radius: 20%;
}
.button-back {
  margin: 40px 27px 10px !important;
}
.button-back button span {
  padding: 5px;
}
.logo-img {
  height: 42px;
  margin: 40px 20px;
}
.bg_colorImg {
  background-color: #17181c !important;
  background-image: url('AccountImage.png');
  background-size: 50%;
  background-repeat: no-repeat;
  background-size: auto;
  /* height: 100%; */
}
/* Listener.js css ends */


.listener_acc_parent {
  height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    margin: auto !important;
    justify-content: space-around !important;
    align-items: center !important;
    padding: 15px 0px !important;
}