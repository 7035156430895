.red_custom_btn_sms {
    /* background-color: #fe0000 !important; */
    border-color: #fe0000 !important;
    color: white !important;
    /* width: 250px; */
    /* height: 45px; */

    width: 301px;
    height: 60px;
    object-fit: contain;
    border-radius: 60px;
    background-color: #ff0010;

    font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  cursor: pointer;
  }

  .grey_custom_btn {
    width: 301px;
  height: 60px;
  object-fit: contain;
  border-radius: 60px;
  border: solid 5px #121212;
  background-color: var(--color-1-100);

  font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: white !important;
  cursor: pointer;

  }

  .dialog-Paper-delete-popup {
    background-color: #020202 !important;
    border-radius: 40px !important;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5) !important;
    border: solid 5px #1f1f1f !important;
    width: 373px !important;
    height: 263px !important;
  }

  @media screen and (max-width: 400px){
    .grey_custom_btn {
        width: 275px;
    }
    .red_custom_btn_sms{
        width: 275px;
    }
  }