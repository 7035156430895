/* .videos-orginal-content .ant-carousel .slick-list{
    padding:0 20% 0 0 !important;
} */

.videoMain {
  /* style={{
        // maxWidth: "100vw",
        // overflowX: "hidden",
        // backgroundColor: "rgb(31, 31, 31)",
        // height: "120vh",
      }} */

  max-width: 100vw;
  height: 100vh;
  background-color: rgb(31, 31, 31);
  overflow: hidden;
}

.videoLayout {
  background-color: rgb(31, 31, 31);
  height: 100vh;
}

.videoContent {
height: 100%;
  margin: 0px;
  background-color: black;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
