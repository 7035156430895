:root {
  --fontFmaily: Inter;
}

.updateCard_Container {
  border-radius: 20px;
  background-color: #090909;
  height: 700px;
  display: flex;
  /* flex-direction: column;     */
  justify-content: center;
  align-content: center;
  text-align: center;
}

.card_title {
  display: flex;
  flex-direction: column;
}

.new_card {
  font-family: var(--fontFmaily);
  font-size: 20px;
  font-weight: 900;
  color: #ffffff;
}

.details {
  font-family: var(--fontFmaily);
  font-size: 40px;
  font-weight: 900;
  color: #ffffff;
}

.textAlign_start {
  text-align: start;
}

.textAlign_end {
  text-align: end;
}

.save_card {
  /* height: 56px;
  background-color: red;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%; */
}

.save_card button {
  font-family: var(--fontFmaily);
  font-size: 20px;
  font-weight: 900;
  color: #ffffff;
  background-color: red;
  border: none;
  width: 75%;
  border-radius: 60px;
  height: 56px;
}

@media only screen and (max-width: 600px) {
  .card_textAlign {
    text-align: center;
  }
}
