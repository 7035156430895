:root {
  --fontFamily: Inter !important;
  --fontFamilyKarla: Karla !important;
  --fontFamilySans: sans-serif !important;
}

.App {
  font-family: var(--fontFamilySans) !important;

}

.height100per {
  height: 100% !important;
}

.slider-arrow {
  position: absolute;
  height: 100%;
  width: 100%;
}

.arrow-btn {
  top: 45%;
  z-index: 1;
}

.nextarrow {
  float: right;
}

.appbar-home {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */

}

.appbar-height {
  height: 68% !important;
}

.appbar-height-playlist {
  /* height: 95% !important; */
  height: 100% !important;


}

.demo-loadmore-list {
  min-height: 350px;
}

.profile-count-grid {
  color: white;
  /* justify-content: center; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.profile-count-value {
  padding: 1px 16px;
  border-radius: 19px;
  border: solid 1px white;
  font-family: var(--fontFamily) !important;
  font-size: 12px;
  font-weight: bold;
  /* width: 50%; */

}

.original-play-width{
  width: 65px;
}

.original-repost-width{
  width: 82px;
}

.profile-count-value-60 {

  width: 60% !important;

}

.profile-count-value-50 {

  width: 50% !important;

}

.profile-count-value-65 {

  width: 65% !important;

}

.profile-count-values {
  font-family: var(--fontFamilyKarla) !important;
  font-size: 32px;
  font-weight: bold;
  margin-top: -5px !important;
}

.profile-count-percentage {
  color: #8f92a1;
  font-family: var(--fontFamilyKarla) !important;
  font-size: 26px;
  font-weight: 800;
  margin-top: -10px !important;
  display: flex !important;
  align-items: center !important;
}

.home_counting_plus {
  width: 15px !important;
  height: 13px !important;
}

.home_counting_minus {
  width: 15px !important;
  height: 8px !important;
}

.tab-content {
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  color: #fff;
  font-family: var(--fontFamily) !important;
}

.filter_profile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 19.5px;
  background-color: #d8d8d8;
  padding: 2px 10px 2px 10px;
  color: #121212;
  /* margin-top: 8px; */
  /* margin-top: 16px; */
  cursor: pointer;
}

.filter_design p {
  color: #121212;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 14px;
  font-weight: 500;
}

.mainTabpannel {
  height: 45vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px 20px 0px 0px;
  margin-left: 20px;
}

.filter-div {
  background-color: black;
  /* margin: auto */
}

.listener-filter-div {
  background-color: black;
  /* margin: auto */
}

.slick-list {
  margin: 0px !important;
}

.filter {
  display: flex;
  /* justify-content: end; */
  justify-content: flex-end;
  width: 136px;
  height: 35px;
  padding: 4px 15px 4px 16px !important;
  border-radius: 20px !important;
  background-color: #d8d8d8;
}

.MuiTab-root {
  min-height: 34px !important
}

.MuiTabs-flexContainer {
  display: block !important;
}

.active-tab {
  /* font-size: 22px !important; */
  font-weight: bold !important;
  text-transform: capitalize !important;
  min-width: 92px !important;
  /* border-radius: 35px; */
  background-color: red !important;
  font-size: 14px !important;
  /* padding: 7px 22px !important; */
  border-radius: 35px !important;
  margin: 0px 6px !important;
}

.non-active-tab {
  font-size: 14px !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
  min-width: 92px !important;
  /* padding: 7px 22px; */
  border-radius: 35px;
  background-color: #070707;
  margin: 0px 6px !important;

}

.buttonsgroup {
  float: right !important;
  margin-right: 15px !important;
  border-radius: 20px !important;
}

.video-button {
  border-bottom-right-radius: 20.5px !important;
  border-top-right-radius: 20.5px !important;
  color: #ffffff !important;
  /* background-color: #171717 !important; */
  background-color: #424b5a !important;
  width: 91px !important;
  cursor: pointer !important;
  height: 35px !important;
  margin-left: -14px !important;
  /* height: 33px !important; */
}

.video-button:disabled {
  background-color: blue !important;
  cursor: not-allowed !important;
}

.audio-button-home {
  border-radius: 20px !important;
  /* border-top-left-radius: 20.5px !important; */
  color: #ffffff !important;
  background-color: #424b5a !important;
  width: 68px !important;
  z-index: 2 !important;
  height: 35px !important;
  /* margin-left: 14px !important; */
}

.audio-button-home:disabled {
  background-color: #274af4 !important;
  cursor: not-allowed !important;
}


.video-button-home:disabled {
  background-color: #274af4 !important;
  cursor: not-allowed !important;
}

.video-button-home {
  border-bottom-right-radius: 20.5px !important;
  border-top-right-radius: 20.5px !important;
  color: #ffffff !important;
  /* background-color: #171717 !important; */
  background-color: #424b5a !important;
  width: 68px !important;
  cursor: pointer !important;
  height: 35px !important;
  margin-left: -14px !important;
  /* height: 33px !important; */
}

.audio-button:disabled {
  background-color: blue !important;
  cursor: not-allowed !important;
}


.box {
  border-radius: 20px;
  height: 122px;
  margin: 0px 10px;
}

.mybox {
  position: relative;
  padding: 20px 12px 19.8px 18px;
}

.mybox:after {
  content: "";
  position: absolute;
  left: 0px;
  top: 18%;
  height: 65%;
  border-left: 2px solid red;
}

.box-label {
  color: #8f92a1;
  font-size: 14px;
}

.box-number {
  color: #fff;
  font-size: 22px;
  font-weight: bold;
}

.profile-count-parent {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between;
  padding: 15px 45px;
}


/* .original_dropdown .ant-dropdown-menu {
  position: static !important;
} */

.appbar-div {
  /* background-color: #171717 !important; */
  background-color: black !important;
  color: white !important;
  font-size: 22px !important;
  font-weight: bold !important;
  box-shadow: none !important;
}

.appbar-div_creative {
  /* background-color: #171717 !important; */
  background-color: black !important;
  color: white !important;
  font-size: 22px !important;
  font-weight: bold !important;
  box-shadow: none !important;
  /* height: 48% !important; */
}

.appbar-div_creative_audio {
  /* background-color: #171717 !important; */
  background-color: black !important;
  color: white !important;
  font-size: 22px !important;
  font-weight: bold !important;
  box-shadow: none !important;
   /* height: 32% !important; */
   /* height: 34% !important; */
}

.tabs {
  background-color: black !important;
  color: white !important;
  margin-bottom: 7px;
  margin-right: 3% !important;
}

.home_withoutaudio {
  /* height: 52% !important; */
  overflow-y: auto;
}

.home_withoutData{
  height: 100%;
}

.home_audio {
    /* height: 33% !important; */
  overflow-y: auto;
}


.render-arrow-div {
  position: relative !important;
}



.playlist-div {
  display: flex !important;
  align-items: center !important;
}

.activity-parentDiv {
  display: flex;
  justify-content: space-around;
}

.self-profile {
  display: flex;
  border: "1px solid red"
}

/* Responsive Media Query */


@media (min-width: 1233px) {
  .tabpanel-scroll {
    /* height: 93%; */
    overflow: hidden auto;
    background-color: black;
  }
  .tabpanel-scroll-noData {
    /* height: 93%; */
    overflow: hidden;
    background-color: black;
  }

  .tabpanel-scroll-listener {
    /* height: 53%; */
    overflow: hidden auto;
    background-color: black;
  }

}

/* @media (max-width: 1233px) {

  .listener_div_creative {
    overflow-y: scroll !important;
  }
} */
.profile-tab-Container{
  display: flex !important;
}

@media (max-width: 960px) {
  .appbar-div_creative {
    background-color: black !important;
    color: white !important;
    font-size: 22px !important;
    font-weight: bold !important;
    box-shadow: none !important;
    /* height: 55% !important; */
  }

  .home_withoutaudio {
    /* height: 45% !important; */
    overflow-y: auto;
  }
 

  .home_withoutData{
    /* height: 45% !important; */
    /* height: 60% !important; */
    /* overflow-y: auto; */
    height: 100%;
  }

 
}


@media (max-width: 600px) {
  .filter-div {
    margin: 0px !important;
    /* margin: 0px !important; */
    display: flex !important;
    justify-content: flex-end !important;
  }
}


@media screen and (min-width: 600px) and (max-width: 959px) {
  .filter-div {
    margin: 4px 10px !important;
    /* margin: 0px !important; */
    display: flex !important;
    justify-content: flex-start !important;

  }
}



@media (max-width: 959px) {


  .listener-filter-div {
    margin: 4px 10px !important;
    display: flex !important;
    justify-content: flex-end !important;
  }

  .playlist-filter {
    /* margin-top: 14px !important; */
    display: flex !important;
    margin-left: 1% !important;
    /* justify-content: end !important; */
    /* justify-content: flex-end !important; */
  }

  @media screen and (max-width: 600px) {
    .filter_align_right{
      justify-content: flex-end;
    }
  }

  .audio-div {
    /* margin-top: 14px !important; */
    display: flex !important;
    justify-content: flex-start !important;
  }
}

.home-filter {
  /* width: 109px !important; */
  justify-content: space-around;
  height: 33px !important;
}

.listener-home-filter {
  /* width: 109px !important; */
  justify-content: space-around;
  height: 33px !important;
}

@media (max-width: 600px) {

  .listener-filter-div {
    margin: 4px 10px !important;
    display: flex !important;
    justify-content: flex-end !important;
  }
  

  .tab-content {
    margin: 0px;
  }

  .audio-button {
    width: 68px !important;
  }

  .video-button {
    width: 68px !important;
  }

  .audio-button-home {
    width: 58px !important;
  }

  .video-button-homw {
    width: 58px !important;
  }

  .audio-text {
    font-size: 12px !important;
  }

  .activity-parentDiv {
    flex-direction: column-reverse;
  }

  .activity-mr {
    margin-top: 10px !important;
  }

}

@media (max-width: 400px) and (min-width: 600px) {
  .active-tab {
    font-size: 14px !important;
  }

  .non-active-tab {
    font-size: 14px !important;
  }
}

@media (max-width: 399px) {
  .active-tab {
    font-size: 11px !important;
  }

  .non-active-tab {
    font-size: 11px !important;
  }
}

@media (max-width: 960px) {
  .home-filter {
    height: 33px !important;
    margin-top: 6px !important;
    /* width: 109px !important; */
    margin-right: 13px;
  }

  .listener-home-filter {
    height: 33px !important;
    margin-right: 13px;
  }
}

@media (max-width: 430px) {
  .home-filter {
    /* width: 109px !important; */
    height: 33px !important;
    margin-top: 6px !important;
    /* width:30% !important; */
    /* margin-right: 13px !important; */
    margin-right: 0px !important;
  }

  .listener-home-filter {
    /* width: 109px !important; */
    height: 33px !important;
    margin-top: 6px !important;
    /* width:30% !important; */
    margin-right: 13px !important;
  }
}

@media (max-width: 599.95px) {
  .MuiTabs-scrollButtonsDesktop {
    display: flex !important;
  }
}

.MuiTabScrollButton-root {
  width: 8px !important;
  opacity: 0.8;
  flex-shrink: 0;
  margin-bottom: 1%;
}

@media screen and (min-width: 1056px) and (max-width: 1150px) {
  .profile-count-values {
    /* font-size: 40px; */
    font-size: 35px;

  }

  .profile-count-percentage {
    /* font-size: 26px; */
    /* font-size: 24px; */
    font-size: 20px;
  }
}


@media screen and (min-width: 960px) and (max-width: 1150px) {
  .profile-count-value-65 {
    width: 100% !important
  }

  .profile-count-value-60 {
    width: 90% !important
  }

  .profile-count-value-50 {
    width: 68% !important
  }
}

@media screen and (min-width: 750px) and (max-width: 959px) {
  .profile-count-value-65 {
    width: 100% !important
  }

  .profile-count-value-60 {
    width: 80% !important
  }

  .profile-count-value-50 {
    width: 70% !important
  }
}

@media screen and (min-width: 600px) and (max-width: 749px) {
  .profile-count-value-65 {
    width: 120% !important
  }

  .profile-count-value-60 {
    width: 100% !important
  }

  .profile-count-value-50 {
    width: 80% !important
  }
}

@media screen and (min-width: 700px) and (max-width: 1057px) {
  .profile-count-values {
    font-size: 35px;
  }

  .profile-count-percentage {
    font-size: 18px;
  }
}



@media screen and (min-width: 620px) and (max-width: 699px) {
  .profile-count-values {
    font-size: 30px;
  }

  .profile-count-percentage {
    font-size: 24px;
  }
}
@media screen and (min-width: 575px) and (max-width: 619px) {
  .profile-count-values {
    font-size: 28px;
  }

  .profile-count-percentage {
    font-size: 22px;
  }
}


@media screen and (min-width: 350px) and (max-width: 575px) {
  .profile-count-values {
    font-size: 22px;
  }

  .profile-count-percentage {
    font-size: 18px;
  }
}

@media screen and (max-width: 349px) {
  .profile-count-values {
    font-size: 16px;
    margin-top: 0 !important;

  }

  .profile-count-percentage {
    font-size: 14px;
    margin-top: 0 !important;
  }

  .home_counting_plus {
    width: 13px !important;
    height: 11px !important;
    margin-right: 3px !important;
  }
}

@media screen and (max-width: 1210px) {
  .third-button {
    margin: 0px
  }


}

@media screen and (max-width: 1025px) {
  .one-button {
    margin: 0px !important
  }

  .listener-follow-btn {
    padding: 2px 7px !important
  }
}

@media screen and (min-width: 1026px) {
  .one-button {
    margin: 12px 0px 12px 23px;
    border: none !important;
  }

  .listener-follow-btn {
    padding: 7px 44px !important
  }
}

@media screen and (max-width: 700px) {
  .self-profile {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 599px) {
  .profile-count-value-60 {

    width: 100% !important;

  }

  .profile-count-value-50 {

    width: 100% !important;

  }

  .profile-count-value-65 {

    width: 100% !important;

  }
}



@media screen and (max-width: 600px) and (min-height: 800px) {
  .profile-count-grid{
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-around !important;
  }
  .profile-count-values{
    padding-left: 5px !important;
  }

  .profile-tab-Container{
    max-height: 38% !important;
  }
 

}

@media screen and (max-width: 600px) {
  .filter_align_right{
    justify-content: flex-end;
  }
}