.Sms_form .ant-input {
    background-color:#020202 !important;
    border-color: #020202 !important;
    border-bottom: 4px solid #242528 !important;
    color: white !important;
    padding: 4px 0px !important;
  }
  .Sms_form .ant-input:focus,
  .ant-input-focused {
    box-shadow: none !important;
  }
  .Sms_form .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
  }
  .Sms_form
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
  }
  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding-bottom: 0px !important;
  }
  .Sms_form .ant-form-item-explain-error {
    text-align: start !important;
    margin-top: 0px !important;
  }
  .Sms_form .ant-form-item-explain,
  .ant-form-item-extra {
    color: white !important;
    text-align: end !important;
    margin-top: 0px !important;
    cursor: pointer !important;
    align-self: start !important;
  }
  .Sms_form .ant-input-affix-wrapper {
    background-color: #17181c !important;
    border-color: #17181c !important;
    border-bottom: 4px solid #242528 !important;
    padding: 0px !important;
  }
  .Sms_form .ant-input-affix-wrapper > input.ant-input {
    border-bottom: none !important;
  }
  .Sms_form .ant-form-item-label > label {
    color: #8f92a1 !important;
  }

  .dialog-Paper-edit {
    background-color: #020202 !important;
    border-radius: 40px !important;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5) !important;
    border: solid 5px #1f1f1f !important;
    width: 373px !important;
    height: 650px !important;
  }

  .red_custom_btn_sms {
    background-color: #fe0000 !important;
    border-color: #fe0000 !important;
    color: white !important;
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;

    width: 265px;
    height: 55px;
  }

  .grey_custom_btn {
    background-color: #1f1f1f !important;
    border-color: #1f1f1f !important;
    color: white !important;
    border-radius: 30px;
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    width: 265px;
    height: 55px;
  }

  .margin-left-div{
    margin-left: 0px
  }  

  @media screen and (min-width: 380px) and (max-width: 410px){

    .margin-left-div{
      margin-left: -20px
    }
    
  }


  @media screen and (min-width: 340px) and (max-width: 380px){

    .margin-left-div{
      margin-left: -35px
    }
    
  }

  @media screen and (max-width: 350px){

    .margin-left-div{
      margin-left: -50px
    }
    
  }
  
  .ant-upload.ant-upload-select-picture-card {
    border: 5px solid #1f1f1f;
    border-radius: 15px;
    width: 135px;
    height: 135px;
  }