/** charts videos screen classes **/
:root {
  --fontFamilykarla: karla !important;
  --fontFamilyNunito: Nunito !important;
  --fontFamilyInter: Inter !important;
  --fontFamilyPoppins: Poppins !important;
}

.vid_Image {
  width: 135px;
  height: 75px;
  border-radius: 10px;
  background-color: #212121;
}

.top-video-images {
  border-radius: 12px;
  height: 80px;
  width: 100%;
  border: 2px solid rgb(33, 33, 33);
  background-position: center center;
  object-fit: cover;
}

.top-video-combined-icon {
  margin-left: -4px;
  display: flex;
  align-items: baseline;
}

.top-video-align {
  display: flex;
  align-items: center;
}

.ml4 {
  margin-left: 4px;
}

.height100 {
  height: 100%;
}

.mB8 {
  margin-bottom: 8px;
}

.overflow-hidden-auto {
  overflow: hidden auto;
}

.top-video-count-grid {
  display: flex;
  flex-direction: column;
  align-items: column;
  justify-content: center;
}
.videos-image-height-width{
  height: 75px;
  width: 135px;
}

.margin-right-top-songs{
  margin-right: 10px;
}

@media screen and (max-width: 400px) {
  .margin-right-top-songs{
    margin-right: 5px;
  }

  .videos-image-height-width{
    height: 75px;
    width: 105px;
  }
}

@media screen and (max-width: 370px) {

  .videos-image-height-width{
    height: 60px;
    width: 90px;
  }
}

.top-video-large-icon {
  height: 20px;
  width: 20px;
}

.top-video-justify-center {
  justify-content: center;
}

/* songList */
.songList-parent-row {
  padding: 10px !important;
  border-radius: 20px !important;
  border: 1.5px solid rgb(50 49 49) !important;
}

.songList-parent-col {
  border-radius: 12px !important;
  height: inherit !important;
}

.width100 {
  width: 100% !important;
}

.dflex {
  display: flex !important;
}

.songList-artist-image {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  object-fit: cover !important;
}

.songList-typography-name {
  font-size: 12px !important;
  overflow: hidden !important;
  display: inline-block !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 170px !important;
}

.songList-typo-type {
  color: #99999f !important;
  margin-top: 2px !important;
  font-size: 10px !important;
}

.songList-typo-title {
  cursor: pointer !important;
  margin-top: -3px !important;
  overflow: hidden !important;
  display: inline-block !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 270px !important;
}

/* playlistNameComp */

.search-playlist-parent {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
}

.search-playlist-grid {
  display: flex !important;
  max-width: 100% !important;
  margin: 5px 0px !important;
}

.search-playlist-artist-id {
  border-radius: 50% !important;
  cursor: pointer !important;
  object-fit: cover !important;
}

.search-playlist-profile {
  display: flex !important;
  flex-direction: column !important;
  cursor: pointer !important;
}

.search-playlist-nowrap {
  overflow: hidden !important;
  display: inline-block !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 116px !important;
  margin-bottom: 1px !important;
}

.search-playlist-type-name {
  color: #99999f !important;
  margin-top: 2px !important;
  font-size: 6px !important;
}

.search-playlist-user-type {
  cursor: pointer !important;
  margin-top: -3px !important;
  color: #8f9092 !important;
  overflow: hidden !important;
  display: inline-block !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 270px !important;
  font-size: 10px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.search-playlist-repost {
  display: flex !important;
  justify-content: flex-start !important;
  margin-top: 5px !important;
}

.search-playlist-repost-btn {
  border-radius: 13px !important;
  background-color: #22252a !important ;
  border-color: rgba(0, 3, 3, 0) !important;
  padding: 3px !important;
  font-size: 9px !important;
  font-weight: 600 !important;
  height: 25px !important;
  margin-right: 5px !important;
  color: #8f9092 !important;
  cursor: pointer !important;
  display: none !important;
}

.search-playlist-share {
  border-radius: 13px !important;
  background-color: #22252a !important ;
  border-color: rgba(0, 3, 3, 0) !important;
  padding: 4px !important;
  font-size: 9px !important;
  font-weight: 600 !important;
  height: 25px !important;
  margin-right: 5px !important;
  color: #8f9092 !important;
  cursor: pointer !important;
}

/* sharePlaylistWeb */
.share-playlist-web-width {
  width: 60% !important;
}

.share-playlist-web-pr15 {
  padding-right: 15px !important;
}

.share-playlist-web-img {
  width: 100% !important;
  border-radius: 15px !important;
}

.share-playlist-cardId {
  margin-right: 5px !important;
  position: absolute !important;
  top: 35% !important;
  left: 35% !important;
  height: 35px !important;
  cursor: pointer !important;
}

.share-playlist-web-name {
  color: #3959f9 !important;
  margin-bottom: 5px !important;
  font-size: large !important;
  font-weight: bolder !important;
}

.share-playlist-web-item {
  color: #8f9092 !important;
}

.share-playlist-web-data {
  max-height: 255px !important;
  overflow: hidden auto;
  background-color: rgb(16 15 15) !important ;
  width: 100% !important;
  border-radius: 20px !important;
}

.share-playlist-no-data {
  text-align: center !important;
  color: grey !important;
  font-size: 20px !important;
  font-weight: bold !important;
  margin-top: 200px !important;
}

/* carasouel  */

.carasouel-menu-item {
  color: white !important;
  background-color: transparent !important;
}

.carasouel-track-div {
  border-radius: 12px !important;
  height: inherit !important;
}

.carousel-track-child {
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.carousel-artwork {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.carousel-artwork-img {
  height: 100% !important;
  width: 100% !important;
  border-radius: 12px !important;
  filter: blur(10px) !important;
  border: 1px solid red !important;
}

.carousel-position {
  position: absolute !important;
}

.carousel-cover-image {
  height: 100px !important;
  width: 100px !important;
  border-radius: 12px !important;
}

.carousel-absolute {
  position: absolute !important;
}

.carousel-song-img {
  height: 25px !important;
  cursor: pointer !important;
}

.carousel-cover1 {
  height: 100% !important;
  width: 100% !important;
  border-radius: 12px !important;
}

.carousel-profile-img {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
}

.carousel-width {
  display: flex !important;
  width: auto !important;
}

.carousel-artist1 {
  flex-grow: 1 !important;
}

.carousel-artist-type {
  color: #99999f !important ;
  margin-top: 2px !important;
  font-size: 10px !important;
  font-family: var(--fontFamilyPoppins) !important;
  flex-grow: 1 !important;
}

.carousel-charts-pd {
  padding: 0px 3px !important;
}

.carousel-numbers-div {
  height: 20px !important;
  width: 20px !important;
  /* margin-top: -4px !important; */
}

.carousel-number-blue {
  height: 15px !important;
  width: 16px !important;
}

.carousel-type1 {
  padding: 0px 3px !important;
  cursor: pointer !important;
}

.carousel-reshared1 {
  height: 20px !important;
  width: 20px !important;
  /* margin-top: -6px !important; */
}

.display-none {
  display: none;
}

.carousel-more {
  font-size: 30px !important;
  margin-top: -9px !important;
  color: gray !important;
}

.carousel-col-hide {
  justify-content: center !important;
  display: flex !important;
}

.carousel-col-btn {
  border-radius: 13px !important;
  background-color: #22252a !important ;
  padding: 4px 15px 4px 12px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  height: 30px !important;
  width: -webkit-fill-available !important;
  margin-right: 5px !important;
  color: #8f9092 !important ;
  font-family: var(--fontFamilyPoppins) !important;
}

.download-carousel-col-btn {
  border-radius: 13px !important;
  background-color: rgb(255, 0, 0) !important ;
  padding: 4px 15px 4px 12px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  height: 30px !important;
  width: -webkit-fill-available !important;
  margin-right: 5px !important;
  color: #fff !important ;
  font-family: var(--fontFamilyPoppins) !important;
}

.carousel-col-dflex {
  justify-content: center !important;
  display: flex !important;
}

/* songs */
.songs-lockicon {
  width: 17px !important;
  height: 17px !important;
  position: absolute !important;
  top: 32% !important;
  left: 29% !important;
}

.songs-carousel-parent {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.songs-head-list {
  border-radius: 20px !important;
  /* margin-bottom: 13px !important; */
  padding: 4px 20px 4px 20px !important;
  margin-top: 30px !important;
  width: 100% !important;
  max-height: 550px !important;
  display: flex !important;
  flex-direction: column !important;
}

.songs-head-list-child {
  padding: 6px 0px 4px 0px !important;
}

.mt4 {
  margin-top: 4px !important;
}

.align-center {
  text-align: center !important;
}

.songs-listof-songs {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.songs-listof-child {
  color: white !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.position-relative {
  position: relative !important;
}

.top-10 {
  top: 10 !important;
}

.songs-play-pause {
  display: flex !important;
  align-items: center !important;
}

.topSongs-parent {
  border-radius: 20px !important;
  margin-bottom: 13px !important;
  width: 100% !important;
  max-height: 415px !important;
  display: flex !important;
  flex-direction: column !important;
}

.topSongs-head-parent {
  padding: 6px 0px 4px 0px !important;
  margin-top: 1% !important;
}

.topSongs-24per {
  margin-top: 4px !important;
  padding-left: 10px !important;
}

.topSongs-7day {
  margin-top: 4px !important;
  text-align: start !important;
  padding-left: 10px !important;
}

.topSongs-coverimg {
  border-radius: 8px !important;
  border: solid 1px gray !important;
}

.topSongs-aligncenter {
  display: flex !important;
  align-items: center !important;
}

.topSongs-alignEnd {
  display: flex !important;
  align-items: flex-end !important;
}

.topSongs-grid-head {
  padding: 6px 0px 4px 0px !important;
  margin-top: 1% !important;
}

.topSongs-7days {
  margin-top: 4px !important;
  text-align: center !important;
}

.topSongs-default-profile {
  border-radius: 10px !important;
  border: solid 1px gray !important;
}

.topSongs-playlist-img {
  border-radius: 8px !important;
  border: solid 1px gray !important;
}

.pT10 {
  padding-top: 10 !important;
}

.topSongs-space-btn {
  display: flex !important;
  justify-content: space-between !important;
}
.p10 {
  padding: 10 !important;
}
.topSongs-expand {
  margin: auto !important;
  cursor: pointer !important;
}

/** charts videos screen classes ***/
.main-scroll {
  width: 100%;
  /* height: 650px; */
  min-height: 60px;
  max-height: 700px;
  /* background-color: #fff; */
  /* border: 1px solid red; */
  overflow-y: auto;
  overflow-x: hidden;
}

.search-main-scroll::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.search-main-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.search-main-scroll::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

.search-main-scroll {
  width: 100%;
  height: 100%;
  /* min-height: 60px; */
  /* max-height: 700px; */
  /* background-color: #fff; */
  /* border: 1px solid red; */
  overflow-y: auto;
  overflow-x: hidden;
}

.main-scroll-withaudio::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.main-scroll-withaudio::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.main-scroll-withaudio::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

.main-scroll-withaudio {
  width: 100%;
  height: 100%;
  /* min-height: 57vh; */
  /* max-height: 57vh; */
  /* background-color: #fff; */
  /* border: 1px solid red; */
  overflow-y: auto;
  overflow-x: hidden;
}

.playSearchTrackContainer {
  display: flex;
  width: "100%";
  margin-bottom: -10px;
}

.play-repost-comment-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.main-scroll-withaudio::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.main-scroll-withaudio::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.main-scroll-withaudio::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

@media screen and (max-width: 450px) {
  .playSearchTrackContainer {
    flex-direction: column;
  }
}

/* Scrollbar */

/* width */
.main-scroll::-webkit-scrollbar {
  width: 15px;
  height: 5px;
}

/* Track */
.main-scroll::-webkit-scrollbar-track {
  /* background: transparent;
  box-shadow: inset 0 0 5px #615f5f;
  border-radius: 7px;
  border-left: 1.5px solid transparent;
  border-right: 1.5px solid transparent; */
  background: #585858;
  border-radius: 10px;
}

/* Handle */
.main-scroll::-webkit-scrollbar-thumb {
  /* background: #afaaaa;
  border-radius: 7px; */
  background-color: rgb(43, 43, 46);
  border-radius: 10px;
}

.song-background {
  background-color: #0a0a0a;
}

.top-head {
  font-size: 22px !important;
  color: white;
  font-weight: bold !important;
  font-family: var(--fontFamilykarla) !important;
  padding-left: 5px;
}

.top-video-cover-img {
  width: 80px !important;
  height: 48px !important;
}

@media (min-width: 601px) and (max-width: 940px) {
  .top-head {
    font-size: 19px !important;
  }

  .top-video-cover-img {
    width: 48px !important;
    height: 48px !important;
  }
}

@media (min-width: 387px) and (max-width: 600px) {
  .top-head {
    font-size: 17px !important;
  }
}

.top-head-Font {
  font-size: 26px !important;
  color: #ffffff;
  font-weight: bold !important;
  font-family: var(--fontFamilyInter) !important;
}

.height_30vh {
  height: 27vh;
}

.height_40vh {
  height: 40vh;
}

.baseline {
  display: flex !important;
  align-content: baseline !important;
}

.mr5 {
  margin: 5px 0px !important;
}

.white {
  color: white !important;
}

.ml10 {
  display: flex !important;
  flex-direction: row !important;
  margin-left: 10px !important;
}

.sn-grid {
  color: white;
}

.count_songs_font {
  font-family: var(--fontFamilyInter) !important;
  font-size: 16px !important;
  font-weight: bold !important;
  color: #ffffff !important;
}

.icon-up {
  width: 13px !important;
  height: 9px !important;
  margin-top: 5px !important;
}

.display {
  display: flex !important;
  /* align-items: center !important; */
  justify-content: flex-end !important;
}

.display-carousel {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.charts-display-carousel {
  display: flex !important;
  align-items: center !important;
  /* justify-content: flex-end !important; */
}

.search-display-carousel {
  display: flex !important;
  align-items: center !important;
  /* justify-content: flex-end !important; */
}

.display-play {
  display: flex !important;
}
.display-TopSongs{
  display: flex !important;
}

.display-play_dark_back {
  display: flex !important;
  width: auto;
}

@media screen and (min-width: 1280px) {
  .video-comment-icons{
    width: 100%; 
    justify-content: space-between;
  }
}



.recommend-play {
  display: flex !important;
}

.display-column {
  display: flex !important;
  flex-direction: column !important;
}

.display-songs {
  display: flex !important;
  flex-direction: column !important;
  margin-left: 8px !important;
}

.charts-songs-display {
  display: flex !important;
  flex-direction: column !important;
  margin-left: 8px !important;
}

.artist-name-width {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: var(--fontFamilykarla) !important;
  font-size: 14px;
  font-weight: bold;
}

.artist-name-width:first-letter {
  text-transform: uppercase;
}

.name_hiddne {
  font-size: 14px !important;
  color: #efefef !important;
  font-weight: bold !important;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 82% !important;
  text-transform: lowercase;
  font-family: var(--fontFamilyInter) !important;
}

.name_hiddne:first-letter {
  text-transform: uppercase;
}

.playlist_video_title {
  font-size: 16px !important;
  color: #efefef !important;
  font-weight: 500 !important;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 99% !important;
  text-transform: lowercase;
}

.playlist_video_title:first-letter {
  text-transform: uppercase;
}

.songs-padding {
  padding: 6px 0px 4px !important;
  display: flex !important;
  align-items: center !important;
}

.song-name-font {
  font-family: var(--fontFamilyInter) !important;
  font-size: 16px !important;
  font-weight: bold !important;
  color: #ffffff !important;
}

.song-name {
  font-size: 16px !important;
  color: #efefef !important;
  font-weight: bold !important;
  text-transform: lowercase;
  font-family: var(--fontFamilyInter) !important;
}

.song-desc {
  font-size: 12px !important;
  color: #9f9f9f !important;
}

.top_size_16 {
  font-size: 16px !important;
  font-weight: bold !important;
  font-family: var(--karlafontFamily) !important;
}

.songs-head-style {
  font-family: var(--fontFamily) !important;
  font-size: 16px !important;
  font-weight: bold;
}

.play-times-videos {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #fff !important;
  justify-content: center !important;
  display: flex !important;
  text-align: center !important;
  font-family: var(--fontFamilyInter) !important;
}

.play-times {
  font-size: 14px !important;
  font-weight: bold !important;
  color: #fff !important;
  justify-content: center !important;
  display: flex !important;
  text-align: center !important;
  font-family: var(--fontFamilyInter) !important;
}

.play-times-charts-songs {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #fff !important;
  justify-content: center !important;
  display: flex !important;
  text-align: center !important;
  font-family: var(--fontFamilyInter) !important;
}

.dflex-align-center {
  display: flex !important;
  align-items: center !important;
}

.play-times_blue {
  font-size: 12px !important;
  /* color: #fff !important; */
  margin-top: 1px !important;
  justify-content: center !important;
  display: flex !important;
  text-align: center !important;
}

.play-songs-times {
  font-family: var(--fontFamilyInter) !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #ffffff !important;
}

.justify-center {
  display: flex !important;
  justify-content: center !important;
}

.profile_header_icons_height{
  height: 200px !important;
}

@media screen and (max-height: 589px) {
  .profile_header_icons_height{
    height: 100px !important;
  }
}

@media screen and (min-height: 590px) and (max-height: 690px) {
  .profile_header_icons_height{
    height: 125px !important;
  }
}

@media screen and (min-height: 690px) and (max-height: 790px) {
  .profile_header_icons_height{
    height: 150px !important;
  }
}

.charts-song-artist {
  font-size: 12px !important;
  color: #9f9f9f !important;
}

.hours-percent {
  font-size: 16px !important;
  color: #8f92a1 !important;
  font-weight: bold !important;
}

.songs-scroll {
  display: block;
  border: 0px solid red;
  /* padding: 5px; */
  scrollbar-width: auto;
  /* align-items: top; */
  margin-top: -1px;
  scrollbar-color: red yellow;
  width: 100%;
  height: 650px;
  overflow-y: scroll;
}

.hours-list {
  /* font-size: 10px !important; */
  font-size: 12px !important;
  color: #fff !important;
  /* margin-top: 4px !important; */
  font-family: var(--fontFamilyPoppins) !important;
  display: flex;
  align-items: baseline;
}

.iconExpand {
  font-size: 10px !important;
  color: #fff !important;
}

.iconDelete {
  right: 0;
  position: absolute;
}

.wave {
  font-size: 10px !important;
  color: #fff;
}

.waveSize {
  height: 46px !important;
  width: 170px !important;
}

.play_whites {
  margin-top: 4px !important;
}

.parent-div-list {
  /* margin-bottom: 13px; */
  /* padding: 4px 1px 4px 20px; */
  padding: 4px 1px 4px 0px;
}

.parent-div-list:hover {
  border: solid 2px #212121;
  /* background-color: rgb(14, 14, 14); */
  border-radius: 8px;
  /* padding-left: 8px; */
}

.main-container-space:hover {
  border: solid 2px #212121;
  border-radius: 8px;
}

.parent-videos-list {
  padding: 4px 1px 4px 0px;
}

.parent-videos-list:hover {
  /* border: solid 1px red; */
  /* background-color: #191919; */
  background-color: #141414;
  /* border-radius: 10px; */
  /* padding-left: 10px; */
  padding-right: 5px;
  /* margin-left: -10px !important; */
}

.video-parent-div:hover {
  border: solid 1px #525252 !important;
  background-color: #191919 !important;
  border-radius: 8px !important;
}

.video-list {
  font-size: 10px !important;
  color: #fff;
  margin-top: 22px !important;
}

/* Handle */
.scroll {
  /* max-height: 224px !important; */
  overflow-y: scroll !important;
  /* overflow-x: scroll !important; */
  max-width: 1500px !important;
}

.scroll-noslider {
  max-height: 100% !important;
  overflow: auto !important;
}

.scroll-playlist {
  max-height: 170px !important;
  overflow-y: scroll !important;
  max-width: 1500px !important;
}

.scroll-noslider-video {
  overflow-y: auto !important;
  max-height: 51vh !important;
  /* min-height: 51vh !important; */
}

.video-with-playList {
  overflow-y: scroll !important;
  /* max-width: 1500px !important; */
  max-height: 123px !important;
}

.song-with-playList {
  overflow-y: scroll !important;
  /* max-width: 1500px !important; */
  max-height: 135px !important;
}

.audio-scroll {
  max-height: 380px !important;
  overflow-y: scroll !important;
  /* overflow-x: scroll !important; */
  max-width: 1500px !important;
}

.videos-parent-div {
  /* border: 1px solid #1f1f1f !important; */
  border-radius: 20px !important;
  /* background-color: #121212 !important; */
  background-color: #000 !important;
  margin-top: 10px !important;
  /* overflow: auto !important; */
  height: 84% !important;
}

#trendingVids_in_CommentsComp {
  padding: 25px !important;
}

.video-parent-div {
  border: 1px solid #1f1f1f !important;
  /* border-radius: 20px !important; */
  /* background-color: #121212 !important; */
  background-color: #121212 !important;
  margin-top: 10px !important;
  overflow: hidden !important;
    margin-bottom: 13px !important;
  padding: 4px 20px 4px 20px !important;
  border-radius: 10px !important;
 }
 
 
 .top-song-parent {
  padding: 0px 20px !important;
  margin-top: 5px !important;
}

.top-videos-parent {
  border: 1px solid #1f1f1f !important;
  border-radius: 20px !important;
  background-color: #121212 !important;
  margin-top: 10px !important;
  overflow: hidden !important;
}

.top-videos-div {
  padding: 0px 20px !important;
  margin-top: 15px !important;
}

 
 .video-div-radius {
  border-radius: 5px !important;
  width: 85px !important;
  height: 50px !important;
  display: block !important;
  text-transform: lowercase;
}

.song-name:first-letter {
  text-transform: capitalize;
}

.justify-center-div {
  display: flex !important;
  justify-content: center !important;
  margin-top: 17px !important;
}

.menu_icon_img {
  height: 28px;
  width: 28px;
}

.carasouel-grid-display {
  display: flex;
}

.remember-typography {
  color: rgb(57, 89, 249);
  font-size: 16px;
  font-weight: 600;
}
 
 .songDesc_in_TopSongs {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #8f92a1;
}

.topsongs_creative_font {
  font-family: var(--fontFamilyInter) !important;
  font-size: 12px !important;
  font-weight: bold !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

.charts-tabs-height {
  /* max-height: 203px !important; */
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  /* max-height: 290px !important; */
  /* max-height: 233px !important; */
}
.aliginment_center{
  align-items: center !important;
}

.download_btn_color {
  color: white !important;
  background-color:rgb(255, 5, 1) !important;
}

.charts-audio-height {
  /* max-height: 80% !important; */
  /* max-height: 153px !important; */

  overflow-y: scroll !important;
}

.charts-videos-height {
  max-height: 303px !important;
  overflow-y: scroll !important;
}

.musicRow {
  cursor: pointer;
}

.musicRow:hover {
  border-radius: 10px !important;
  /* background-color: "#121212"; */
  /* background-color: #121212; */
}

/* Responsive Media Query */
@media (max-width: 1036px) and (min-width: 650px) {
  .carasouel-grid-display {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 577px) {
  .HiddnFullScreen {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .HiddnFullScreen {
    position: absolute;
    right: 0;
    display: contents;
  }

  .song-name {
    font-size: 14px !important;
    color: #efefef !important;
    font-weight: 500 !important;
  }

  .white {
    color: white !important;
    font-size: 14px !important;
  }
}

@media (max-width: 865px) {
  .hiddnGraph {
    display: none !important;
  }
}

.widthGraph {
  /* width: 150px; */
  width: 85%;
  height: 34px;
}

.song-cover-img {
  position: relative;
}

@media screen and (max-width: 1050px) {
  .song-cover-img {
    margin-bottom: 10px;
  }
}

@media (min-width: 960px) and (max-width: 1024px) {
  .widthGraph {
    width: 100px;
    height: 34px;
  }
}

@media (min-width: 1025px) and (max-width: 1100px) {
  .widthGraph {
    width: 120px;
    height: 34px;
  }
}

@media (min-width: 700px) and (max-width: 1380px) {
  .top_size_16 {
    font-size: 12px !important;
    font-weight: 550 !important;
    font-family: var(--karlafontFamily) !important;
  }
}

@media (min-width: 1075px) and (max-width: 1279px) {
  .name_hiddne {
    width: 77% !important;
  }

  .playlist_video_title {
    /* width: 80% !important; */
    width: 95% !important;
  }

  .artist-name-width {
    width: 77% !important;
  }
}

@media (min-width: 870px) and (max-width: 958px) {
  .name_hiddne {
    width: 77% !important;
  }

  .playlist_video_title {
    width: 81% !important;
  }

  .artist-name-width {
    width: 77% !important;
  }
}

/* @media (min-width: 768px) and (max-width: 830px) {
  .profileDetailBlock {
    margin-right: 10px !important;
  }
 } */

@media (min-width: 960px) and (max-width: 1024px) {
  .commentCount {
    padding-left: 20px !important;
  }
}

.iconImg {
  /* margin-right: 10px; */
  margin-right: 5px !important;
}

@media (min-width: 960px) and (max-width: 1185px) {
  .iconImg {
    margin-right: 1px;
  }
}

/* @media (min-width: 960px) and (max-width: 1100px) {
  .profileDetailBlock {
    margin-right: 15px !important;
  }
 } */
@media (min-width: 1031px) and (max-width: 1030px) {
  .profileDetailBlock {
    margin-right: 47px !important;
  }
}

@media (min-width: 577px) and (max-width: 600px) {
  .main-container-space {
    display: flex;
    justify-content: space-between;
  }

  .search-container-space {
    display: flex;
    justify-content: space-between;
  }

  .percentage-hide {
    display: none;
  }
}

@media (max-width: 1600px) {
  .hiddnDaysGraph {
    /* display: none !important; */
    width: 100px;
    height: 23px;
  }
}

@media (max-width: 576px) {
  .hidden_percentage {
    display: none !important;
  }
}

@media (max-width: 700px) {
  .top_size_16 {
    font-size: 12px !important;
    font-family: var(--karlafontFamily) !important;
  }

  .iconImg {
    margin-right: 0px !important;
  }
}

@media (max-width: 739px) {
  .hiddnDaysGraph {
    display: none !important;
  }

  .second-head-percentage {
    justify-content: space-around !important;
    margin-left: -4% !important;
  }
}

@media (min-width: 577px) and (max-width: 869px) {
  .playlist_video_title {
    /* width: 80% !important; */
    width: 75% !important;
  }
}

@media (max-width: 576px) {
  .display-play_dark_back {
    /* display: flex;  */
    border: solid 1px rgba(151, 151, 151, 0.18);
    margin: 7px 0px !important;
    border-radius: 20px;
    padding: 3px;
    background: #232323 !important;
    margin-left: 0px !important;
    width: 100% !important;
  }

  .iconButton {
    display: contents !important;
  }

  .name_hiddne {
    font-size: 16px !important;
    color: #efefef !important;
    font-weight: 500 !important;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* width: 100px; */
    /* width: 73%; */
    width: 100% !important;
  }

  .playlist_video_title {
    font-size: 16px !important;
    color: #efefef !important;
    font-weight: 500 !important;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* width: 100px; */
    /* width: 73%; */
    width: 100% !important;
  }

  .artist-name-width {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}

@media (max-width: 426px) {
  .name_hiddne {
    width: 65% !important;
  }

  /* .playlist_video_title {
    width: 68% !important;
  } */
  .artist-name-width {
    width: 64% !important;
  }
}

@media (min-width: 715px) and (max-width: 764px) {
  .display-play_dark_back {
    display: flex !important;
    width: auto;
  }
}

/* @media (max-width: 767px) {
  .display-play_dark_back {
    width: auto
  }} */
@media (max-width: 600px) {
  .charts-songs-display {
    margin-left: 0px !important;
  }

  .remember-typography {
    font-size: 12px !important;
  }

  .more_icon {
    display: flex;
    /* justify-content: end; */
    justify-content: flex-end;
  }

  .menu_icon_img {
    height: 18px;
    width: 18px;
  }

  .display-play {
    border: solid 1px rgba(151, 151, 151, 0.18);
    margin: 7px 0px !important;
    border-radius: 20px;
    padding: 3px;
    background: grey;
    margin-left: 16px !important;
  }

  .justify-center-div {
    margin-top: 0px !important;
  }

  .recommend-play {
    border: solid 1px rgba(151, 151, 151, 0.18);
    margin: 7px 0px !important;
    border-radius: 20px;
    padding: 3px;
    background: grey;
    /* margin-left: 16px !important; */
  }

  .charts-song-artist {
    display: flex !important;
  }
}

.iconContent {
  margin-right: 5px;
  /* font-family: var(--fontFamilyPoppins) !important; */
}

@media (min-width: 590px) and (max-width: 715px) {
  .more_icon {
    display: none;
  }
}

/* @media (max-width: 767px) {
  .iconButton {
    display: contents;
  } */
/* .iconContent {
    margin-right: 20px;
  } */
/* } */

@media (min-width: 590px) and (max-width: 720px) {
  .percentageData {
    display: none;
  }
}

.percentageData {
  display: contents;
}

@media (max-width: 715px) {
  .iconContent {
    margin-right: 5px !important;
  }
}

@media (max-width: 675px) {
  .iconContent {
    margin-right: 5px !important;
    display: flex !important;
    justify-content: center !important;
  }
}

@media (max-width: 959px) {
  .playButtonContain {
    display: none;
  }
}

@media (min-width: 577px) and (max-width: 730px) {
  .name_hiddne {
    font-size: 14px !important;
    color: #efefef !important;
    font-weight: 500 !important;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* width: 100px; */
    /* width: 73%; */
    width: 60% !important;
  }

  .playlist_video_title {
    font-size: 14px !important;
    color: #efefef !important;
    font-weight: 500 !important;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* width: 100px; */
    /* width: 73%; */
    width: 62% !important;
  }

  .iconImg {
    margin-right: 5px !important;
  }

  .artist-name-width {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 60% !important;
  }
}

/* @media (min-width: 960px) and (max-width: 120px) {
  .playButtonContain {
    margin-right: 10px !important;
  }
 } */

@media (max-width: 510px) {
  .colButton {
    width: 33.33%;
  }
}

@media (max-width: 900px) {
  /* .display-column-chart{
    display: flex;
    flex-direction: column;
  } */
  .menu_icon_img {
    height: 20px;
    width: 20px;
  }

  .songs-list-percent {
    font-size: 14px !important;
    font-weight: 600 !important;
  }

  .video-div-radius {
    width: 83px !important;
    height: 55px !important;
  }
}

@media (max-width: 1397px) {
  .charts-videos-height {
    max-height: 259px !important;
    overflow-y: scroll !important;
    /* overflow-x: scroll !important; */
  }
}

@media (min-width: 960px) and (max-width: 1060px) {
  .charts-tabs-height {
    /* max-height: 222px !important; */
    /* overflow-y: scroll !important; */
    overflow-x: hidden !important;
  }

  .charts-videos-height {
    max-height: 259px !important;
    overflow-y: scroll !important;
    overflow-x: scroll !important;
  }
}

.artist-name {
  font-size: 16px !important;
  color: #efefef !important;
  font-weight: 500 !important;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  /* width: 100px; */
  width: 73%;
  text-transform: lowercase;
  font-family: var(--fontFamilyPoppins) !important;
}


.artist-name:first-letter {
  text-transform: lowercase;
}
 
 
 
 
 .artist-name:first-letter {
  text-transform: uppercase;
}

.title-name {
  font-family: var(--fontFamilyPoppins) !important;
}

@media (min-width: 960px) and (max-width: 1060px) {
  .artist-name {
    font-size: 14px !important;
  }
}

/* @media (min-width: 2000px){
  .icon_in_statsTab{
    max-width: 3% !important;
  }
 }
 
 
 @media (min-width: 2350px){
  .icon_in_statsTab{
    max-width: 2.5% !important;
  }
 } */
@media (min-width: 600px) and (max-width: 1090px) {
  .topsongs-div {
    justify-content: end;
  }

  .vidImage_in_TopSongs {
    border-radius: 8px !important;
    height: 80% !important;
  }
}

@media (min-width: 2380px) and (max-width: 2720px) {
  .songNameWrapper_in_statsTab {
    margin-left: -24px !important;
  }
}

@media (min-width: 2150px) and (max-width: 2380px) {
  .songNameWrapper_in_statsTab {
    margin-left: -16px !important;
  }
}

@media (min-width: 1900px) and (max-width: 2150px) {
  .songNameWrapper_in_statsTab {
    margin-left: -8px !important;
  }
}

@media (min-width: 1750px) and (max-width: 1900px) {
  .songNameWrapper_in_statsTab {
    margin-left: 0px !important;
  }
}

@media (min-width: 1500px) and (max-width: 1750px) {
  .songNameWrapper_in_statsTab {
    margin-left: 8px !important;
  }
}

@media (min-width: 960px) and (max-width: 1500px) {
  .songNameWrapper_in_statsTab {
    margin-left: 16px !important;
  }
}

@media (min-width: 840px) and (max-width: 960px) {
  .songNameWrapper_in_statsTab {
    margin-left: 8px !important;
  }
}

@media (min-width: 775px) and (max-width: 840px) {
  .songNameWrapper_in_statsTab {
    margin-left: 12px !important;
  }
}

@media (min-width: 675px) and (max-width: 775px) {
  .songNameWrapper_in_statsTab {
    margin-left: 16px !important;
  }
}

@media (max-width: 1200px) {
  .songDesc_in_TopSongs {
    padding-left: 0 !important;
    font-size: 12px !important;
    color: #8f92a1;
  }
}

@media (max-width: 501px) {
  .songDesc_in_TopSongs {
    padding-left: 13px !important;
  }
}

@media (max-width: 415px) {
  .vidImage_in_TopSongs {
    height: 70px !important;
  }
}

@media (max-width: 360px) {
  .vidImage_in_TopSongs {
    height: 70px !important;
    margin-left: 4px !important;
  }
}

@media (max-width: 325px) {
  .txtAndBtnsWrapper_in_topSongsTab {
    margin-left: 15px !important;
  }
}

.canvas-width {
  width: 150px;
  height: 50px;
  position: relative;
}
.playlist-canvas-width {
  height: 50px;
  position: relative;
  margin-left: -25% !important;
}

@media screen and (max-width: 650px) {
  .canvas-width {
    width: 50px;
  }
}

@media screen and (min-width: 650px) and (max-width: 750px) {
  .canvas-width {
    width: 60px;
  }
}

@media screen and (min-width: 750px) and (max-width: 850px) {
  .canvas-width {
    width: 100px;
  }
}

@media screen and (min-width: 850px) and (max-width: 960px) {
  .canvas-width {
    width: 130px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1050px) {
  .canvas-width {
    width: 80px;
  }
}

@media screen and (min-width: 1050px) and (max-width: 1150px) {
  .canvas-width {
    width: 120px;
  }
}

@media screen and (min-width: 1150px) and (max-width: 1300px) {
  .canvas-width {
    width: 135px;
  }
}

.icons-padding {
  /* padding-top: 10; */
  margin-left: 0px;
}

@media screen and (min-width: 960px) and (max-width: 1100px) {
  .icons-padding {
    /* padding-top: 10; */
    margin-left: -10px;
  }
}


