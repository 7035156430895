.OfferPreviewBox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 30px;
    border: solid 5px #212121;
    background-color: #070707;
    width: 351px;
    padding: 22px 24px 129px 24px;
}

.OfferPreviewBox .MT32 {
    margin-top: 32px;
}

.OfferPreviewBox .MT23 {
    margin-top: 23px;
}

.OfferPreviewBox .LabelBox {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
}

.LabelBox .AvatarBox {
    display: flex;
    justify-content: center;
    align-content: center;
}

.LabelBox .AvatarBox .Avater {
    width: 46px;
    height: 46px;
}

.LabelBox .LabelInfoBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-left: 24px;
}

.OfferPreviewBox .PVCommonText {
    font-family: "Inter", serif;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.PVCommonText.LabelTitleText {
    font-size: 14px;
    font-weight: bold;
    color: white;
}

.PVCommonText.LabelSubTitleText {
    font-size: 12px;
    font-weight: 500;
    color: #717171;
}

.PVCommonText.OfferingText {
    font-size: 16px;
    font-weight: 800;
    color: #717171;
}

.PVCommonText.OfferingMoneyText {
    font-size: 42px;
    font-weight: 800;
    color: white;
}

.PVCommonText.ForText {
    font-size: 16px;
    font-weight: 800;
    color: #717171;
}

.PVCommonText.DurationText{
    font-size: 14px;
    font-weight: bold;
    color: white;
}

.PVCommonText.FromText{
    font-size: 16px;
    font-weight: 800;
    color: #717171;
}

.OfferPreviewBox .SplitDetailBox {
    display: flex;
    justify-content: space-around;
    align-content: center;
    height: 120px;
}

.SplitDetailBox .Avatar {
    width: 74px;
    height: 74px;
}

.SplitDetailBox .SplitPercentageText {
    padding: 4px 16px;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 800;
    color: white;
    background: #212121;
    border-radius: 30px;
}

.SplitDetailBox .LineBox {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LineBox .ChildBox {
    width: 100%;
    margin-bottom: 32px;
}

.LineBox .Line {
    background: white;
    min-width: 112px;
    height: 5px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.LineBox .Line .Avatar {
    width: 26.5px;
    height: 26.5px;
}

.SplitDetailBox .SplitDurationBox {
    position: absolute;
    bottom: 0px;
    margin-bottom: 28px;
}

.PVAudioVideoContentDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    height: 76px;
    margin-top: 12px;
    z-index: 1;
    position: relative
}

.PVAudioVideoContentDiv .PVAudioVideoImageDiv {
    position: relative;
    border: solid 3px black;
    border-radius: 15px
}

.PVUserImageDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.PVUserImageDiv .Avatar{
    width: 74px;
    height: 74px;
}
.PVAudioVideoContentDiv .PVAudioVideoImage {
    width: 85px;
    height: 85px;
    object-fit: cover;
    border-radius: 15px
}

.PVAudioVideoContentDiv .PVAudioVideoImage.VideoImage {
    width: 96px;
    height: 62px;
}

.PVAudioVideoContentDiv .PVAudioVideoPlayBtn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    height: 30px;
    width: 30px;
    right: 30%;
    top: 30%;
}

.PVAudioVideoPlayBtn.VideoType {
    right: 32%;
    top: 25%;
}

.PVAudioVideoPlayBtn .Icon {
    height: 30px;
    width: 30px;
}

.PVAudioVideoContentDiv .PVAudioVideoInfoDiv {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 76px;
    width: 100%;
    margin-left: 16px;
}

.PVAudioVideoInfoDiv .SongNameText {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: white;
}

.PVAudioVideoInfoDiv .SongWriterText {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #717171;
}

.PVSongInfoBox {
    display: flex;
    flex-direction: column;
    padding-top: 8px;
}



@media screen and (max-width: 599px) {

    .OfferPreviewBox {
        width: 328px;
    }
}