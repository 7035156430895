:root {
  --fontFmaily: Inter;
}

.PlanDialog {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userCurrentPlan {
  /* height: 80%; */
  border: 6px solid #212121;
  background: #000000;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* margin: 5px; */
  margin: 0px 0px;
  padding: 15px;
  padding-left: 50px;
  height: 325px;
  /* width: 300px; */
}

.currentPlan_text {
  /* line-height: 0.5; */
  /* font-size: 10px; */
  /* padding-left: 10px; */
  /* margin-bottom: 5px; */
  font-size: 16px;
  font-weight: 900;
  padding: 5px 5px;
  color: #3959f9;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.no-plans {
  color: white;
  font-size: 20px;
  font-family: var(--fontFmaily);
  font-weight: 700;
  font-style: italic;
}
.subscriber_content {
  display: flex;
  flex-direction: column;
  padding: 15px 0px 0px 8px;
  line-height: 1;
}

.subscriber_title {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: bold;
  font-family: var(--fontFmaily);
}

.subscriber_plan_rupees {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  font-family: var(--fontFmaily);
}

.cancel_button {
  margin-top: 20px;
}

.cancelPlan_button {
  height: 46px;
  font-family: var(--fontFmaily);
  font-size: 20px;
  font-weight: 900;
  color: rgba(255, 255, 255, 0.5);
  border-radius: 60px;
  background-color: #212121;
  border: none;
  width: 200px;
  cursor: pointer;
}

.plan-name-div {
  border-radius: 10px;
  border: solid 3px #212121;
  text-align: center;
  display: flex;
  align-items: center;
  /* padding: 3px; */
  padding: 0px 12px;
}

.plan-name {
  color: white;
  font-size: 30px !important;
  font-weight: 800 !important;
  text-transform: uppercase !important;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1700px) {
  .userCurrentPlan {
    padding: 10px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 1350px) {
  .userCurrentPlan {
    padding: 10px;
    padding-left: 20px;
  }
  .subscriber {
    padding: 12px 0px 0px 8px;
  }
}

@media screen and (max-width: 1270px) {
  .userCurrentPlan {
    padding-left: 40px;
  }
}

@media screen and (max-width: 400px) {
  .userCurrentPlan {
    padding-left: 25px;
  }
}

@media screen and (max-width: 350px) {
  .userCurrentPlan img {
    width: 80%;
  }
  .cancel_button img {
    /* width: 80%; */
  }
}

@media screen and (max-width: 350px) {
  .userCurrentPlan {
    padding: 8px;
  }

  .cancel_button img {
    /* width: 90%; */
  }
}
