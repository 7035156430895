:root {
  --InterfontFamily: Inter;
  --PoppinsfontFamily: Poppins;
}

.month_wise_track {
  /* text-align: center; */

  /* color: darkgray; */
  /* font-weight: 400; */
  /* font-family: sans-serif; */
  display: inline-block;
  /* height: 102px; */
  width: 185px;
  border: solid 3px #212121;
  background-color: #212121;
  color: #ffffff;
  font-family: var(--InterfontFamily) !important;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: 0.24px;
  /* text-align: left; */
  margin: 10px 10px;
  padding: 0px 10px;
  border-radius: 20px;
  text-align: center;
}

.downloadTrack {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
  gap: 5px;
  padding: 10px 0px;
  align-items: center;
  justify-items: center;
  color: #ffffff;
  margin: 0px 20px;
}

div.downloadTrack:hover {
  cursor: pointer;
  background-color: #000000;
  /* margin: 0px 20px; */
  margin: 0px 10px;
  padding: 5px 8px;
  border-radius: 7px;
  border: solid 1px #212121;
}

.track_title {
  display: flex;
  width: 100%;
  /* padding-left: 25px; */
  justify-content: flex-start;
  align-items: center;
}

.track_title span {
  /* padding-top: 10px; */
  font-family: var(--PoppinsfontFamily) !important;
}

.track_name_detail {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  /* height: 5vh; */
  /* justify-content: space-evenly; */
}

.track_name_detail span:first-child {
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  font-family: var(--InterfontFamily) !important;
  text-transform: lowercase;
  /* display: inline-block; */
  display: block;
  width: 200px;
  word-wrap: break-word;
}

@media screen and (max-width: 1350px) {
  .track_name_detail span:first-child {
    width: 150px;
  }
}

@media screen and (max-width: 1180px) {
  .track_name_detail span:first-child {
    width: 100px;
  }
}

@media screen and (max-width: 1100px) {
  .track_name_detail span:first-child {
    width: 95px;
  }
}

@media screen and (max-width: 1040px) {
  .track_name_detail span:first-child {
    /* width: 85px; */
  }
}

@media screen and (max-width: 520px) {
  .track_name_detail span:first-child {
    /* font-size: 16px;
    font-weight: bold;
    line-height: normal;
    font-family: var(--InterfontFamily) !important;
    font-family: var(--InterfontFamily) !important;
    text-transform: lowercase;
    display: inline-block;
    overflow: ellipse;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
    width: 100px;
  }
}

@media screen and (max-width: 520px) {
  .downloadTrack {
    grid-gap: none !important;
    margin: 0px !important;
  }
  .track_name_detail span:first-child {
    font-size: 12px !important;
  }

  .track_genre {
    font-size: 12px !important;
  }
  .downloadBtn {
    font-size: 12px !important;
  }
  .downloadHead {
    margin: 0px !important;
  }
  .download_banner_main {
    padding: 0px 0px 39px 28px !important;
  }
  .banner_heading {
    font-size: 24px !important;
  }
  .banner_track {
    font-size: 14px !important;
  }
}

.track_name_detail span:nth-child(2) {
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  font-family: var(--PoppinsfontFamily) !important;
  color: #919191;
  opacity: 0.67;
  margin-top: 5px;
  text-transform: lowercase;
  display: inline-block;
}

.track_name_detail span:nth-child(2):first-letter {
  text-transform: uppercase;
}
.track_name_detail span:first-child:first-letter {
  text-transform: uppercase;
}
.track_types {
  color: #ff0000;
  padding: 0px 15px;
  border: 1px solid #ff0000;
  border-radius: 20px;
  font-family: var(--InterfontFamily) !important;
  font-size: 12px;
  font-weight: bold;
}

.track_genre {
  font-family: var(--InterfontFamily) !important;
  font-size: 14px;
  font-weight: 600;
}

.downloadBtn {
  background-color: red;
  color: white;
  padding: 5px 30px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  font-family: var(--PoppinsfontFamily);
  font-size: 14px;
  font-weight: 600;
}

@media only screen and (max-width: 1326px) {
  @media (hover: hover) {
    div.downloadTrack:hover {
      background-color: #000000;
      border: solid 1px #212121;
      border-radius: 7px;
      cursor: pointer;
      margin: 0px 10px;
      padding: 5px 8px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .downloadTrack {
    grid-template-columns: 1.3fr 1fr 1fr 1fr 1fr 1fr;
  }

  .downloadBtn {
    padding: 5px 25px;
  }
}

@media only screen and (max-width: 1100px) {
  .downloadTrack {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .downloadBtn {
    padding: 5px 12px;
  }
}

@media only screen and (max-width: 610px) {
  .downloadTrack {
    grid-template-columns: 1fr 1fr 1fr;
    /* grid-template-rows: 1fr 1fr; */
  }
  .downloadTrack span:nth-child(3),
  .downloadTrack span:nth-child(4),
  .downloadTrack span:nth-child(5),
  .downloadTrack span:nth-child(6) {
    display: none;
  }
  .music-download {
    width: 45px !important;
    height: 45px !important;
    display: flex;
  }
}
@media only screen and (min-width: 609px) {
  .music-download {
    display: none;
  }
}
