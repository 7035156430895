.Music-Royalties{
   
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    white-space: nowrap;
    color: #ffff  
}

.This-Week {
    
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.5) !important;
  }

.Swalla {
    width: 40px;
    
    font-size: 12px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    white-space: nowrap;
    color: #ffffff;
  }

.Jason-Derulo {
    width: 78px;
    /* height: 15px; */
    flex-grow: 0;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.5);
  }

.money{
    width: 65px;
    height: 18px;
  /* margin: 35px 39px 8px 10px; */
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    white-space: nowrap
  }

.text-style-1{
    width: 47px;
    height: 18px;
    /* margin: 7px 40px 21px 27px; */
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
    color: rgba(255, 255, 255, 0.5);
    white-space: nowrap
  }

  
.wallet_leftside_container{
    margin-top: 12px;
    height: 54px;
    padding: 0px 14px 0px 5px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.iaiaiaiai{
    width: 476px;
    height: 732px;
    border-radius: 30px;
    border: solid 5px #212121;
    display: flex;
    justify-content: center;
    align-items: center;
}
.shareButton{
    width: 46px;
    height: 46px;
    flex-grow: 0;
    object-fit: contain;
    border-radius: 100px;
    border: solid 1px #ffff;
    background-color: rgba(33, 33, 33, 0.5);
}
.Send {
    width: 36px;
    height: 18px;
    flex-grow: 0;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #ffff;
  
  }
  .wallettop_container{
    gap:3%;
    display:flex
  }
  .wallet_container_element{
    height:50vh;
    width:50%
  }

  @media screen and (max-width: 700px) {
    .wallettop_container{
      display:flex !important;
      gap:290px !important;
      flex-direction: column !important;
    }
    .wallet_container_element{
      width:100% !important;
      height:51vh !important
    }
    .musicdeal_right_side{
      width: 100% !important; 
  
    }
    .rightsidecontainer{
      width: 100% !important;
    }
  }


