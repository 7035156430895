.notification_style .ant-notification-notice {
    background: #141414 !important;
}

.ant-notification-notice {
    background: #141414 !important;
    padding: 10px !important;
    border: 1px solid #212121 !important;
    border-radius: 16px !important;
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
    color: #fff !important;
}

.ant-notification-notice-close {
    color: #fff !important;
}