:root {
  --fontFamily: Inter;
  --fontFamilyPoppins: Poppins !important;
  --fontFamilyNunito: Nunito !important;
}

.playListMain {
  /* style={{
        maxWidth: "100vw",
        overflowX: "hidden",
        backgroundColor: "rgb(31, 31, 31)",
        height: "120vh",
      }} */

  max-width: 100vw;
  overflow: hidden;
  background-color: rgb(31, 31, 31);
  height: 100vh;
}

.playList-body {
  /* margin-right: 15px; */
  /* height: 90vh; */
  overflow-x: hidden;
  overflow-y: auto;
}

.no_data_available{
  /* height: 180px;
  color: white;
  font-size: 25px;
  font-family: var(--fontFamily) !important;
  font-weight: 700; */
  height: 44vh;
  color: white;
  font-size: 25px;
  font-family: var(--karlafontFamily) !important;
  font-weight: 500;
  /* justify-content: center; */
  align-items: center;
  text-align: center;
}

.yourPlay-List {
  height: 77vh;
  margin: 10px 20px !important;
}

.playlist-label {
  display: flex;

  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 25px;
}

.title-part {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 10px;
  align-items: center;
  font-family: var(--fontFamilyNunito) !important;
}
.title-part h1 {
  color: white;
  font-size: xx-large;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: bolder;
}
.selected-prePlaylist {
  background-color: rgb(57, 89, 249) !important;
}
.title-part button {
  /* background-color: rgb(31, 31, 31);
    border: solid 1px rgba(151, 151, 151, 0.18); */
  border-radius: 15px;
  color: white;
  font-family: var(--fontFamilyPoppins) !important;
  font-weight: 700 !important;
}

.title-part button:hover .audio_playlist {
  background: #274af4;
  border-color: #274af4;
}
.title-part button:focus {
  background: #274af4;
  border-color: #274af4;
}
.add-playlist button,
.add-playlist button:hover,
.add-playlist button:focus {
  border-radius: 15px;

  background-color: #d8d8d8;
  color: black;
  border: none;
  padding-left: 10px;
  padding-right: 10px;
}

.song-list {
  margin: 18px 0 0 4px;
  /* padding: 18px 19px 26px 24px;   */
  padding: 12px 10px 10px 12px;
  border-radius: 20px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #1f1f1f;
  background-color: #121212;
}

.song-list .song-div {
  /* height: 478px; */
  /* max-height: 240px; */
  overflow-y: scroll;
  padding-right: 15px;
  margin-left: 0px;
  display: flex;
  flex-direction: column;
}
/* .mostly-customized-scrollbar::-webkit-scrollbar {
  width: 12px;

  border-radius: 6px;

  border: solid 1px #222;

  background-color: #191919;
}


.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 6px;
  border: solid 1px #d3c9c9;
  background-color: #8e8d8d;
} */

.mostly-customized-scrollbar::-webkit-scrollbar-track {
  /* width: 12px;

  border: solid 1px rgba(151, 151, 151, 0.18);
  background-color: #121212; */
  background: #585858;
  border-radius: 10px;
}

/* Add a thumb */
.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
  /* border-radius: 6px;
  border: solid 1px #d3c9c9;
  background-color: #8e8d8d; */
  background-color: rgb(43, 43, 46);
  border-radius: 10px;
}

@media screen and (max-width: 700px) {
  .playlist-label {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 20px;
  }
  .title-part h1 {
    color: white;
    font-size: large;
  }
}
