:root {
    --fontFamily: Inter !important;
  }
.deletemodal-subtitle {
    font-family: var(--fontFamily) !important;
    font-size: 14px !important;
    color: #8f92a1 !important;
    padding: 10px;
    text-align: center;
}

.dialog-delete-message-popup{
    background-color: #020202 !important;
    border-radius: 40px !important;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 50%) !important;
    border: solid 5px #1f1f1f !important;
    width: 428px !important;
    /* height: 350px !important; */
    padding: 30px !important;
    
}

.dialog-delete-head{
    font-size: 22px !important;
    font-weight: bold !important;
    color: white !important;
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-family: var(--fontFamily) !important;
}

.dm-dropdown{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dropdown-border{
    border: solid 3px #212121;
    padding: 5px 28px 5px 9px;
    border-radius: 65px;
    margin: 8px 0px;
}

.view-profile-newconversation{
    color: white;
    padding: 7px 35px;
    border-radius: 100px;
    background-color: #3959f9;
    cursor: pointer;
    font-family: var(--fontFamily) !important;
    font-size: 17px;
    font-weight: 700;
}

.new-conversation-border{
    background-color: #8f92a1;
    border-radius: 14px;
    margin: 11px 0px !important;
}

@media screen and (max-width: 450px) {

.dialog-delete-message-popup{
    background-color: #020202 !important;
    border-radius: 40px !important;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 50%) !important;
    border: solid 5px #1f1f1f !important;
    width: 300px !important;
    /* height: 350px !important; */
    padding: 0px !important;
} 
}