/* welcome.js css  */
:root {
  --fontFamily: Inter !important;
}
.bgDark {
  background-color: black !important;
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 100%;
}
.welcome-text h2 {
  margin-top: 15px;
  margin-bottom: 20px;
  color: white;
}
.welcome-text p {
  color: white;
  margin-bottom: 10px;
  line-height: 20px;
}
.welcome-text {
  text-align: center;
  margin-bottom: 43px;
}

.bgImg-welcome {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
}
.bgImg-account {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
}

.bgImg-welcome-create {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
}
.bgImg-create-acc{
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
}
.image1 {
  /* object-fit: cover; */
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-width: 100%; 
}

.listener-creative-image1 {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-width: 100%; 
}

.image2 {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

.nxzborderlogo{
  height: 50px !important;
  width: 52px !important;
}

.nxzloginhead{
  color: white;
  font-size: 20px;
  font-family: var(--fontFamily) !important;
  width: 100%;
  font-weight: 900;
}

.nxzloginCreatives{
  font-family: var(--fontFamily) !important;
  font-size: 14px;
}

.login_btn_nxz{
  font-family: var(--fontFamily) !important;
  font-size: 18px !important;
  font-weight: bold !important;
  cursor: pointer;
}

.signup-btn {
  width: 65%;
  margin: auto;
}
.welcomeLogo img {
  height: 50px;
}
.signup-btn button {
  width: 100%;
  position: relative;
  padding: 10px 5px;
  border-radius: 50px;
  font-weight: 600px;
  font-size: 16px;
  border: none;
  color: white;
  margin-bottom: 10px;
}
.gglebtn svg {
  height: 20px;
  padding-right: 10px 2px;
  transform: translate(0px, 3px);
}
.gglebtn {
  background-color: rgb(35 36 40);
}
@media screen and (max-width: 768px) {
  /* .bgImg-welcome {
    display: none !important;
  } */
  .signup-btn button {
    font-size: 14px;
  }
  .signup-btn {
    width: 100%;
    margin: auto;
  }
  .welcome-form {
    margin-top: 20px;
  }
  .accType-form {
    margin-top: 20px;
  }

  .otp-button button {
    width: 200px;
    margin-left: 50px;
  }

  .login_btn_nxz{
    font-size: 14px !important;
  }

}
.login_form .ant-input {
  background-color: black !important;
  border-color: black !important;
  border-bottom: 1px solid #7a7e8a !important;
  color: white !important;
  padding: 4px 0px !important;
}
.login_form .ant-input:focus,
.ant-input-focused {
  box-shadow: none !important;
}
.login_form .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}
.login_form
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding-bottom: 0px !important;
}
.login_form .ant-form-item-explain-error {
  text-align: start !important;
  margin-top: -8px !important;
}
.login_form .ant-form-item-explain,
.ant-form-item-extra {
  color: white !important;
  text-align: end !important;
  margin-top: 0px !important;
  cursor: pointer !important;
  align-self: start !important;
}
.login_form .ant-input-affix-wrapper {
  background-color: black !important;
  border-color: black !important;
  border-bottom: 1px solid #7a7e8a !important;
  padding: 0px !important;
}
.login_form .ant-input-affix-wrapper > input.ant-input {
  border-bottom: none !important;
}
.login_form .ant-form-item-label > label {
  color: #8f92a1 !important;
  font-size: 12px !important;
}
.login_btn {
  width: 100%;
  border-radius: 30px;
  height: 60px;
  font-size: 16px;
  font-weight: 700 !important;
}
.google_btn {
  background-color: #232428 !important;
  border: 1px solid #232428 !important;
  width: 100%;
  border-radius: 30px;
  height: 60px;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.google_btn span {
  margin-left: 8px;
}
/* end welcome.js css  */
