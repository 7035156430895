.ant-modal-content {
  background-color: #232323;
  border-radius: 18px;
  color: #fff;
}
.ant-modal-content h2 {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}
.ant-modal-content .ant-modal-close-icon {
  color: #fff;
}
.ant-modal-content .ant-btn {
  height: 50px;
  font-size: 16px;
  border-radius: 30px;
  max-width: 70%;
  text-align: center;
  /* margin-top: 55px; */
  border: none !important
    ;
  color: white !important;
  background-color: rgb(255, 0, 0) !important;
}
.ant-modal-content .ant-select {
  background-color: transparent;
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
  padding: 0px 10px 10px 10px;
  font-size: 16px;
  color: #fff;
}
.ant-modal-content .ant-input {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.4);
  padding: 0px 10px 10px 10px;
  font-size: 16px;
  color: #fff;
}
.ant-modal-content
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: transparent;
  border: 0;
}
.ant-modal-content .ant-select-arrow {
  color: #fff;
}
.ant-modal-content .ant-form-item {
  flex-direction: column;
}
.ant-modal-content .ant-form-item-label > label {
  color: #8f92a1;
}
.ant-modal-content .ant-form-item-label {
  text-align: left;
}
.ant-spin-text {
  color: #999;
}
