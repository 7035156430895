.DVMainPanel {
    overflow-y: auto;
}

.DVMainPanel .ColumnCenter {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.DVMainPanel .AICenter {
    align-items: center;
}

.DVMainPanel .MuiButton-label {
    white-space: nowrap;
}

.VDBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 351px;
    padding: 24px 24px 129px 24px;
}

.VDBox .VFPhoneBox {
    margin-top: 32px;
}

.VDBox .VFPhoneBox .VFPhoneText {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.VDBox .SOBox .SOText {
    font-family: "Inter", sans-serif;
    font-size: 32px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.OTPBox {
    margin-top: 24px;
}

.OTPBox input {
    width: 59px;
    justify-content: center;
    background: black;
    margin: 10px;
    border-bottom: 5px solid rgb(189, 189, 189);
    border-top: none;
    border-left: none;
    border-right: none;
    font-family: "Inter", sans-serif;
    font-size: 62px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    color: white;
    caret-color: #717171;
}

.OTPBox input[data-focusvisible-polyfill=true] {
    border-bottom: 5px solid #3959f9;
    border-top: none;
    border-left: none;
    border-right: none;
}

.VDBox .ResendBox {
    display: flex;
    align-items: center;
    margin-top: 210px;
}

.ResendBox .NoOtpText {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: white;
}

.ResendBox .ResendBtn .MuiButton-label{
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #ff0000;
}

@media screen and (max-width: 1079px) {

    .DVMainPanel {
        margin-top: 48px;
    }

    .VDBox {
        padding: 24px 24px 0px 24px;
    }

    .DVMainPanel .SplitButton.Next{
        margin-top: 4px;
    }

    .DVMainPanel .ActionButtonContainerSm{
        margin-top: 4px;
    }

}

@media screen and (max-width: 599px) {

    .DVMainPanel {
        margin-top: 32px;
    }

    .VDBox {
        width: 328px;
    }
}