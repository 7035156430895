:root {
   --fontFamily: Inter !important;
 }
 
.InboxMainContainer {
   overflow: hidden;
   /* overflow-y: auto; */
   padding-top: 10px;
}

.scroll_cp_profile_cmnt {
   overflow-y: auto;
   overflow-x: hidden;
}

.scroll_cp_profile_cmnt::-webkit-scrollbar {
   display: none;
}

.DMMainContainer {
   padding-top: 10px;
   margin: 5px 0px 0px 0px !important;
   width: 100% !important;
}

.CMNTHeight{
   max-height: calc(100vh + -20%) !important;
}

.CMNTHeightCP{
   max-height: calc(100vh + -20%) !important;
}

.CMNTProfileCP{
   max-height: calc(100vh + -15%) !important;
}

.CommentCpScrollHeight{
   max-height: calc(100vh - 140px) !important;
   overflow: scroll;
}

@media screen and (min-width: 1240px) {
  .lastCommentHeight{
    padding-bottom: 120px;
  }
   
}

.InboxMainContainer::-webkit-scrollbar {
   display: none;
}

 .CommentContainerScroll{
   overflow: hidden;
   overflow-y: auto;
   -ms-overflow-style: none; 
   scrollbar-width: none; 
}


.CommentContainerScroll::-webkit-scrollbar {
   display: none;
}



.CommentGrid {
   width: 500px;
}


.SearchText {
   color: #8f92a1;
}


.SearchIcon {
   font-size: 25px;
}


.SearchIcon .ant-input-prefix {
   margin-right: 4px;
}


.SearchInputBox .ant-input-affix-wrapper {
   border-radius: 60px;
   padding: 8px 12px;
   background-color: rgb(40, 40, 40);
   border: 2px solid rgb(40, 40, 40);
}


.SearchInputBox .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
   box-shadow: none !important;
}


.SearchInputBox .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
   border: none !important;
}


.SearchInputBox .ant-input-affix-wrapper>input.ant-input {
   background-color: rgb(40, 40, 40) !important;
   color: white !important;
}

.UserComments {
   margin-top: 5px;
}


/* DM */
.inboxDMListContainer{
   /* max-height: calc(100vh - 210px) !important; */
   max-height: calc(100vh - 213px) !important;

   overflow: scroll;
}

.audio-inboxDMListContainer{
   /* max-height: calc(100vh - 251px) !important; */
   max-height: calc(100vh - 296px) !important;
   overflow: scroll;
}

/* DM close */
.UserComments .MusicComment {
   display: flex;
   flex-direction: row;
   align-items: center;
}
.MusicComment .LeftImageBox {
   height: 60px;
   width: 60px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: grab;
   margin-bottom: 7px;
   margin-left: 3px;
}

.MusicComment.Active {
   background-color: #141414;
   border: 2px solid #717171;
   border-radius: 16px;
}

 .LeftImageBox .MusicImage {
   height: 64px;
   width: 64px;
   object-fit: cover;
   border-radius: 10px;
}

.LeftImageBox.Video {
   width: 160px;
}

.LeftImageBox .VideoImage {
   height: 60px;
   width: 100px;
   object-fit: cover;
   border-radius: 10px;
}

.MusicComment .ContentBox {
   display: flex;
   flex-direction: column;
   justify-content: center;
   flex: 1;
   height: 100%;
   max-width: 220px;
   padding-left: 8px;
}


.DetailBox {
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   cursor: pointer;
}




.MusicComment .UnreadBox {
   padding: 16px;
   height: 100%;
   display: flex;
   justify-content: flex-end;
   align-items: center;
}


.ContentBox .UserNameText {
   color: white;
   font-size: 16px;
   font-weight: 600;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
}


.UserNameText .RepliedText {
   color: #3959f9;
   margin-left: 5px;
}

.ContentBox .UserReplyText {
   color: #717171;
   font-size: 16px;
   font-weight: 500;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   text-overflow: ellipsis;
   display: -webkit-box;
   overflow: hidden;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
   word-break: break-word;
}


.UnreadBox .BlueDot {
   width: 8px;
   height: 8px;
   border-radius: 100px;
   background-color: #3959f9;
}

.RepliesBox {
   margin: 24px 16px 10px 16px;
}

.RepliesBox .RepliesText {
   color: white;
   font-size: 16px;
   font-weight: 800;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
}


.RepliesText .ReplyNoText {
   color: #3959f9;
}


/* Single Comment */
.CommentContainer {
   width: 100%;
   display: flex;
   flex-direction: row;
   align-items: flex-start;
   margin-top:8px;
   margin-bottom: 8px;
}


.CommentContainer .AvatarBox {
   width: 64px;
   height: 64px;
   display: flex;
   justify-content: center;
   align-items: center;
}


.CommentContainer .AvatarBox .Avatar {
   height: 48px;
   width: 48px;
}


.CommentContainer .CommentBox {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   width: 386px;
}

@media screen and (min-width: 1240px) and (max-width: 1350px) {
   .CommentContainer .CommentBox {
     width: 250px;
   }
}

@media screen and (min-width: 1351px) and (max-width: 1450px) {
   .CommentContainer .CommentBox {
     width: 300px;
   }
}

@media screen and (min-width: 1451px) and (max-width: 1550px) {
   .CommentContainer .CommentBox {
     width: 320px;
   }
}

@media screen and (min-width: 1551px) and (max-width: 1650px) {
   .CommentContainer .CommentBox {
     width: 345px;
   }
}

.CommentContainer .MainCommentBox {
   background-color: #171717;
   border-radius: 20px;
   padding: 8px;
   width: 80%;
}

@media screen and (min-width: 1240px) {
   .CommentContainer .MainCommentBox {
     width: 100%;
   }
}

.CommentContainer .MainCommentBox .FirstRow {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   padding: 4px;
}


.MainCommentBox .FirstRow .UserNameText {
   color: white;
   font-size: 14px;
   font-weight: bold;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
}


.MainCommentBox .FirstRow .TimeText {
   color: #717171;
   font-size: 14px;
   font-weight: 600;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   padding: 4px 24px;
   background-color: #000000;
   border-radius: 30px;
}


.MainCommentBox .CommentTextBox {
   padding: 4px;
}


.CommentTextBox .CommentText {
   color: #dddddd;
   font-size: 14px;
   font-weight: normal;
   line-height: 1.5;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   word-wrap: break-word;
}


.CommentBox .ReplyBtnBox {
   display: flex;
   align-items: center;
   margin-bottom: 16px;
}


.ReplyBtnBox .CommentRepliesBtn {
   color: #717171;
   font-size: 12px;
   font-weight: 600;
   line-height: 1.5;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   cursor: pointer;
   display: flex;
   align-items: center;
}


.ReplyBtnBox .CommentReplyBtn {
   color: #3959f9;
   font-size: 12px;
   font-weight: 600;
   line-height: 1.5;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   cursor: pointer;
   margin-left: 24px;
}


.SubCommentContainer {
   width: 100%;
   display: flex;
   flex-direction: row;
   align-items: flex-start;
}


.SubCommentContainer .AvatarBox {
   width: 48px;
   height: 48px;
   display: flex;
   justify-content: center;
   align-items: center;
}


.SubCommentContainer .AvatarBox .Avatar {
   height: 32px;
   width: 32px;
}


.SubCommentContainer .SubCommentBox {
   background-color: #171717;
   border-radius: 20px;
   padding: 8px;
   width: 100%;
   margin-bottom: 10px;
   margin-left: 5px;
}

@media screen and (min-width: 1240px) and (max-width: 1350px) {
   .SubCommentContainer .SubCommentBox {
       width: 200px
   }
}

@media screen and (min-width: 1351px) and (max-width: 1450px) {
   .SubCommentContainer .SubCommentBox {
       width: 250px
   }
}

@media screen and (min-width: 1451px) and (max-width: 1550px) {
   .SubCommentContainer .SubCommentBox {
       width: 275px
   }
}

@media screen and (min-width: 1551px) and (max-width: 1650px) {
   .SubCommentContainer .SubCommentBox {
       width: 315px
   }
}

@media screen and (min-width: 1651px) and (max-width: 1700px) {
   .SubCommentContainer .SubCommentBox {
       width: 335px
   }
}

@media screen and (min-width: 400px) and (max-width: 475px) {
   .SubCommentContainer .SubCommentBox {
       width: 290px
   }
}

@media screen and (min-width: 350px) and (max-width: 399px) {
   .SubCommentContainer .SubCommentBox {
       width: 245px
   }
}
@media screen and (max-width: 349px) {
   .SubCommentContainer .SubCommentBox {
       width: 200px
   }
}


.SubCommentBox .FirstRow {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   padding: 4px;
}


.SubCommentBox .FirstRow .UserNameText {
   color: white;
   font-size: 16px;
   font-weight: 600;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
}


.SubCommentBox .FirstRow .TimeText {
   color: #717171;
   font-size: 14px;
   font-weight: 600;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   padding: 4px 24px;
   background-color: #000000;
   border-radius: 30px;
}


.SubCommentBox .CommentTextBox {
   padding: 4px;
}


/* Single Comment END */


/* Comment Input*/
.CommentInputBox {
   width: 100%;
   height: 64px;
   border: 2px solid white;
   border-radius: 100px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-direction: row;
   padding: 0px 8px;
}


.CommentInputBox .Avatar {
   width: 48px;
   height: 48px;
   border: 2px solid white;
}


.CommentInputBox .CommentInput {
   margin: 0px 8px;
   flex: 1;
}


.CommentInputBox .CommentInput .MuiInput-input {
   color: #717171;
   font-size: 14px;
   font-weight: 600;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;


}


.MuiInput-input::placeholder {
   opacity: 1 !important;
}


.CommentInputBox .CommentSendBtn {
   background-color: #212121;
   border-radius: 100px;
   padding: 4px 24px;
   margin-right: 8px;
}


.CommentInputBox .CommentSendBtn .MuiButton-label {
   color: #dddddd;
   font-size: 14px;
   font-weight: 600;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   text-transform: none;
}


/* Comment Input END*/


/* Media Info Card*/
.AVIMainContainer {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   padding: 16px;
}


.particularUserWeb{
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   padding: 16px;
   border-radius: 8px;
   /* height: 320px; */
}

@media screen and (max-width: 1239px) {
   .AVIMainContainer {
     width: 290px;
   }
  
}.AVInfoContainer {
   border: 3px solid #212121;
   border-radius: 8px;
   height: 320px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.AVInfoContainer.Audio {
   width: 226px;
   height: 226px;
}

.AVInfoContainer.Video {
   width: 376px;
   height: 226px;
}

@media screen and (min-width: 1240px) and (max-width: 1280px) {
   .AVInfoContainer.Video {
       width: 310px;
       height: 226px;
   }
}

@media screen and (min-width: 1281px) and (max-width: 1350px) {
   .AVInfoContainer.Video {
       width: 325px;
       height: 226px;
   }
}

@media screen and (min-width: 1351px) and (max-width: 1400px) {
   .AVInfoContainer.Video {
       width: 350px;
       height: 226px;
   }
}

.AVInfoContainer .PlayBtn {
   height: 48px;
   width: 48px;
   border-radius: 100px;
   background: #00000088;
}
.AVInfoContainer .PlayBtn:hover{
   background: #00000088;
}


.AVInfoContainer .PlayBtn .MuiSvgIcon-root {
   color: white;
}


.AVIMainContainer .AVICMediaName {
   color: white;
   font-size: 20px;
   font-weight: 800;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   text-transform: none;
   margin-top: 24px;
}


.AVIMainContainer .AVICMediaCreatorName {
   color: #717171;
   font-size: 16px;
   font-weight: 800;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   text-transform: none;
   margin-top: 4px;
}


.AVIMainContainer .ViewProfileBtn {
   margin-top: 24px;
   padding: 16px;
   background-color: #3959f9;
   border-radius: 100px;
   /* width: 80%; */
}

.viewProfileButtonWidthHeight{
  width: 327px;
  height: 60px;
}

@media screen and (min-width: 1240px) and (max-width: 1400px) {
   .viewProfileButtonWidthHeight{
       width: 300px;
       height: 50px;
    }
}

.AVIMainContainer .ViewProfileBtn:hover{
   background-color: #3959f9;
}


.AVIMainContainer .ViewProfileBtn .MuiButton-label {
   color: #ffffff;
   font-size: 16px;
   font-weight: 700;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   text-transform: none;
}


.AVIMainContainer .AVICButtonsContainer {
   width: 80%;
   margin-top: 24px;
   display: flex;
   justify-content: space-between;
   align-items: center;
}


.AVICButtonsContainer .AVICBtnBox {
   display: flex;
   align-items: center;
   flex-direction: column;
}


/* particularUserWeb */






.particularUserWeb.Audio {
   width: 320px;
}


.particularUserWeb.Video {
   width: 420px;
}


.particularUserWeb .PlayBtn {
   height: 48px;
   width: 48px;
   border-radius: 100px;
   background: #00000088;
}
.particularUserWeb .PlayBtn:hover{
   background: #00000088;
}


.particularUserWeb .PlayBtn .MuiSvgIcon-root {
   color: white;
}


.particularUserWeb .AVICMediaName {
   color: white;
   font-size: 20px;
   font-weight: 800;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   text-transform: none;
   margin-top: 24px;
}


.particularUserWeb .AVICMediaCreatorName {
   color: #717171;
   font-size: 16px;
   font-weight: 800;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   text-transform: none;
   margin-top: 4px;
}


.particularUserWeb .ViewProfileBtn {
   margin-top: 24px;
   padding: 16px;
   background-color: #3959f9;
   border-radius: 100px;
   width: 80%;
}


.particularUserWeb .ViewProfileBtn:hover{
   background-color: #3959f9;
}


.particularUserWeb .ViewProfileBtn .MuiButton-label {
   color: #ffffff;
   font-size: 16px;
   font-weight: 700;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   text-transform: none;
}


.particularUserWeb .AVICButtonsContainer {
   width: 80%;
   margin-top: 24px;
   display: flex;
   justify-content: space-between;
   align-items: center;
}






/* particularUserWeb */
.AVICBtnBox .Btn {
   height: 48px;
   width: 48px;
   /* border: 2px solid #717171; */
   border-radius: 100px;
   display: flex;
   justify-content: center;
   align-items: center;
}


.AVICBtnBox .BtnText {
   color: white;
   font-size: 12px;
   font-weight: 600;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   text-transform: none;
   margin-top: 12px;
}


.containerRightSideUserData{
   display: flex;
   background-color: #000000;
}


.MuiMenu-list {
   background-color: #000000 !important;
}


.dropDownBtn{
   display: flex;
   justify-content: center;
   align-items: center;
}


.btnText{
   font-weight: bold;
   color: rgb(255, 255, 255);
}




/* Media Info Card END*/


/* Link Copied Modal */
.CopiedModal {
   display: flex;
   justify-content: center;
   align-items: center;
}


.CopiedModal .CopiedModalBox {
   border: 3px solid #717171;
   border-radius: 50px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   background: black;
   height: 260px;
   width: 373px;
}


.CopiedModalBox .CopiedText {
   color: white;
   font-size: 42px;
   font-weight: 900;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   text-transform: none;
}


.CopiedModalBox .CheckBox {
   height: 42px;
   width: 42px;
   border-radius: 100px;
   background-color: #3959f9;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 16px;
}


.CopiedModalBox .CheckIcon {
   height: 32px;
   width: 32px;
   color: black;
}


/* Link Copied Modal END */


/* Comment DM Selector */
.CDMSBox {
   width: 100%;
   border: 3px solid #212121;
   border-radius: 100px;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   height: 50px;
   margin: 8px 0px;
}


.CDMSBox .CBtn .MuiButton-label {
   color: #ffffff;
   font-size: 16px;
   font-weight: 700;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   text-transform: none;
}


.CDMSBox .DMBtn .MuiButton-label {
   color: #ffffff;
   font-size: 16px;
   font-weight: 700;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   text-transform: none;
}


.CDMSBox .CBtn {
   border-radius: 100px;
   margin: 5px;
}

.CDMSBox .CBtn.SelectedBtn {
   padding: 8px 79px;
}

.CDMSBox .DMBtn {
   border-radius: 100px;
   margin: 5px;
}

.CDMSBox .DMBtn.SelectedBtn {
   padding: 8px 102px;
}

.CDMSBox .SelectedBtn {
   background: blue;
}


.CMT-dropdown{
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}


.CDMSBox .SelectedBtn:hover {
   background: blue;
}


.CDMSBox .NotSelectedBtn {
   flex: 1
}


/* Comment DM Selector END */


/* Comment Chat Dialog */
.CommentChatDialog .MuiPaper-root{
   background-color: black;
   justify-content: space-between;
}
.CommentChatDialog .CommentChatBox {
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   /* width: 100%; */
}

@media screen and (max-width:500px){
   .CommentChatDialog .CommentChatBox {
      width: 100%;
   }
}
.CommentChatDialog .CommentChatBox .CommentChatInput{
   margin-bottom: 16px;
   width: 100%;
   background-color: black;
}
.CommentChatDialog .CommentChatBox .CommentChatContainer{
   overflow-y: auto;
   overflow-x: hidden;
   -ms-overflow-style: none;
   scrollbar-width: none;
   padding-bottom: 50px;
   width: 100%;
}
.CommentChatDialog .CommentChatBox .CommentChatContainer::-webkit-scrollbar {
   display: none;
}
/* Comment Chat Dialog END */
/* Creative+ Media Comment List */
.MediaCommentCPMB {
   /* margin-top: 12px; */
   width: 100%;
   overflow-x: auto;
}
.MediaDMCPMB {
   width: 100%;
   overflow-x: auto;
   margin-top: 5%;
}


.MediaCommentCPMB::-webkit-scrollbar{
   display: none;
}


.MediaCommentCPMB .CommentSummary {
   display: flex;
   flex-direction: row;
   align-items: center;
}


.CommentSummary .LeftImageBox {
   height: 75px;
   width: 95px; 
   /* 98px in DM */
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: grab;
}


.CommentSummary.Active {
   background-color: #141414;
   border: 2px solid #717171;
   border-radius: 16px;
}

.CommentSummary .LeftImageBox .MusicImage {
   height: 60px;
   width: 60px;
   object-fit: cover;
   border-radius: 10px;
}

.CommentSummary .LeftImageBox .DMProfileImage {
   height: 46px !important;
   width: 46px !important;
   object-fit: cover !important;
   border-radius: 50% !important;
   border: solid 2px #212121 !important;
}

.CommentSummary .LeftImageBox.Video {
   width: 160px;
}

.CommentSummary .LeftImageBox .VideoImage {
   height: 60px;
   width: 100px;
   object-fit: cover;
   border-radius: 16px;
}


.MediaCommentCPMB .SummaryBox {
   display: flex;
   flex-direction: column;
   justify-content: center;
   width: 80%;
   height: 100%;
   padding-left: 8px;
   cursor: pointer;
}




.SummaryBox .MediaNameText {
   color: white;
   font-size: 14px;
   font-weight: 800;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
}


.SummaryBox .MediaDMText {
   color: white;
   font-size: 14px !important;
   font-weight: bold !important;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
}

.SummaryBox .MediaDMMessage {
   color: #8f92a1 !important;
   font-family: var(--fontFamily) !important;
   font-size: 12px !important;
   font-weight: 500 !important;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   width: 64% !important;
}

.MediaNameText .RepliedText {
   color: #3959f9;
}
.SummaryBox .CommentCountBox{
   display: flex;
   align-items: center;
   margin-top: 8px;
}

.CommentCountBox .MediaTypeText {
   color: #717171;
   font-size: 12px;
   font-weight: 800;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   padding: 4px 12px;
   border-radius: 100px;
   border: 2px solid #717171;
   margin-right: 7px;
   min-width: 80px;
   display: flex;
   justify-content: center;
}
.MediaTypeText.Blue{
   color: #3959f9;
   border: 2px solid #3959f9;
}
.MediaTypeText.Red{
   color: red;
   border: 2px solid red;
}


.CommentCountBox .CommentNoText{
   color: #717171;
   font-size: 12px;
   font-weight: 800;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   padding: 4px 12px;
   border-radius: 100px;
   border: 2px solid #717171;
}
.CommentNoText .CommentCountText{
   color: #3959f9;
   margin-right: 8px;
}


.CommentCountBox .CPBDContainer{
   width: 32px;
   min-width: 32px;
   display: flex;
   justify-content: center;
   flex: 1;
}
.CommentCountBox .BlueDot {
   width: 8px;
   height: 8px;
   border-radius: 100px;
   background-color: #3959f9;
}


/* Creative+ Media Comment List END */


/* MediaTypeSelector */














/* .DMTypeSelectorBox .TrackBtn.SelectedBtn {
   padding: 8px 64px;
} */


.DMTypeSelectorBox .SongBtn {
   border-radius: 100px;
   margin: 5px;
}


.DMTypeSelectorBox .SongBtn.SelectedBtn {
   padding: 8px 36px;
}


.DMTypeSelectorBox .VideoBtn {
   border-radius: 100px;
   margin: 5px;
}




.DMTypeSelectorBox .NotSelectedBtn {
   flex: 1
}



/* DM Type Selector */


.MediaTypeSelectorBox {
   width: 100%;
   border: 3px solid #212121;
    /* //717171; DM */
   border-radius: 100px;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   height: 50px;
   margin: 8px 0px;
}
 


.MediaTypeSelectorBox .MuiButton-label {
   color: #ffffff;
   font-size: 16px;
   font-weight: 700;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   text-transform: none;
}




.MediaTypeSelectorBox .TrackBtn {
   border-radius: 100px;
   margin: 5px;
}


.MediaTypeSelectorBox .TrackBtn.SelectedBtn {
   padding: 8px 64px;
}


/* DM selector */


.DMTypeSelectorBox {
   width: 100%;
   border: 3px solid #212121;
   border-radius: 100px;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   height: 50px;
   margin: 8px 0px !important;
}






.DMTypeSelectorBox .MuiButton-label {
   color: #ffffff;
   font-size: 16px;
   font-weight: 700;
   line-height: normal;
   letter-spacing: normal;
   font-family: var(--fontFamily) !important;
   font-stretch: normal;
   font-style: normal;
   text-transform: none;
}




.DMTypeSelectorBox .TrackBtn {
   border-radius: 100px;
   margin: 5px;
}














.DMTypeSelectorBox .VideoBtn.SelectedBtn {
   padding: 8px 36px;
}


.DMTypeSelectorBox .SelectedBtn {
   background: red;
}


.DMTypeSelectorBox .SelectedBtn:hover {
   background: red;
}






.DMTypeSelectorBox .TrackBtn.SelectedBtn {
   padding: 8px 36px;
   cursor: pointer;
}
/* DM selector end */


.MediaTypeSelectorBox .SongBtn {
   border-radius: 100px;
   margin: 5px;
}


.MediaTypeSelectorBox .SongBtn.SelectedBtn {
   padding: 8px 64px;
}


.MediaTypeSelectorBox .VideoBtn {
   border-radius: 100px;
   margin: 5px;
}


.MediaTypeSelectorBox .VideoBtn.SelectedBtn {
   padding: 8px 64px;
}


.MediaTypeSelectorBox .SelectedBtn {
   background: red;
}


.MediaTypeSelectorBox .SelectedBtn:hover {
   background: red;
}


.MediaTypeSelectorBox .NotSelectedBtn {
   flex: 1
}
/* End of DM Type Selector */


/* MediaTypeSelector END */


@media screen and (max-width:599px) {
   .CommentGrid {
       width: 90%;
   }


   .LeftImageBox {
       width: 64px;
   }


   .LeftImageBox .MusicImage {
       width: 54px;
       height: 54px;
   }


   .LeftImageBox.Video {
       width: 98px;
   }


   .LeftImageBox .VideoImage {
       width: 84px;
   }




   /* Audio Video Card */
   .AVInfoContainer {
       height: 240px;
   }


   .AVInfoContainer.Audio {
       width: 240px;
   }


   .AVInfoContainer.Video {
       width: 280px;
   }


   /* Audio Video Card END */




   .CommentCountBox .MediaTypeText {
       font-size: 10px;
       padding: 4px 8px;
       margin-right: 8px;
       min-width: 60px;
   }
   .CommentCountBox .CommentNoText{
       font-size: 10px;
       padding: 4px 8px;
   }
   .CommentSummary .LeftImageBox{
       width: 64px;
   }
   .CommentSummary .LeftImageBox.Video{
       width: 98px;
   }
   .CommentSummary .LeftImageBox .VideoImage{
       width: 98px;
   }
}


@media screen and (max-width:410px) {


   .SearchIcon .ant-input-prefix {
       margin-right: 0px;
   }


   .LeftImageBox {
       width: 64px;
   }


   .LeftImageBox .MusicImage {
       width: 54px;
       height: 54px;
   }


   .LeftImageBox.Video {
       width: 98px;
   }


   .LeftImageBox .VideoImage {
       width: 84px;
   }




   /* Media Info Card START*/
   .AVIMainContainer .ViewProfileBtn {
       /* width: 90%; */
   }


   .AVIMainContainer .AVICButtonsContainer {
       width: 90%;
   }


   /* Media Info Card END*/


   /* Comment DM Selector Start */
   .CDMSBox .CBtn.SelectedBtn {
       padding: 8px 36px;
   }


   .CDMSBox .DMBtn.SelectedBtn {
       padding: 8px 58px;
   }
   /* Comment DM Selector END */
   .MediaTypeSelectorBox .TrackBtn.SelectedBtn{
       padding:8px 32px;
   }
   .MediaTypeSelectorBox .VideoBtn.SelectedBtn{
       padding:8px 32px;
   }
   .MediaTypeSelectorBox .SongBtn.SelectedBtn{
       padding:8px 32px;
   }


   .DMTypeSelectorBox .TrackBtn.SelectedBtn{
       padding:8px 32px;
       cursor: pointer;
   }
   .DMTypeSelectorBox .VideoBtn.SelectedBtn{
       padding:8px 32px;
   }
   .DMTypeSelectorBox .SongBtn.SelectedBtn{
       padding:8px 32px;
   }
  
}

@media screen and (min-width: 1240px) and (max-width: 1315px) {
   .CDMSBox .DMBtn.SelectedBtn{
       padding: 8px 86px !important;
   }
}
@media screen and (max-width:300px) {

   .SearchIcon .ant-input-prefix {
       margin-right: 0px;
   }

   .LeftImageBox {
       width: 48px;
   }

   .LeftImageBox .MusicImage {
       width: 42px;
       height: 42px;
   }

   .LeftImageBox.Video {
       width: 70px;
   }

   .LeftImageBox .VideoImage {
       width: 58px;
   }


   /* Audio Video Card */
   .AVInfoContainer {
       height: 180px;
   }

   .AVInfoContainer.Audio {
       width: 180px;
   }

   .AVInfoContainer.Video {
       width: 220px;
   }

   /* Audio Video Card END */

   /* Media Info Card START*/
    /* Media Info Card START*/
   .AVIMainContainer .ViewProfileBtn {
       /* width: 95%; */
   }


   .AVIMainContainer .AVICButtonsContainer {
       width: 95%;
   }


   /* Media Info Card END*/


   /* Link Copied Modal Start */
   .CopiedModal .CopiedModalBox {
       height: 180px;
       width: 180px;
       border-radius: 40px;
   }


   .CopiedModalBox .CopiedText {
       font-size: 32px;
   }


   .CopiedModalBox .CheckBox {
       height: 32px;
       width: 32px;
   }


   .CopiedModalBox .CheckBox .CheckIcon {
       height: 24px;
       width: 24px;
   }


   /* Link Copied Modal END */




   /* Comment DM Selector Start */
   .CDMSBox .CBtn.SelectedBtn {
       padding: 8px 36px;
   }


   .CDMSBox .DMBtn.SelectedBtn {
       padding: 8px 48px;
   }
   /* Comment DM Selector END */


   .CommentSummary .LeftImageBox.Video{
       width: 84px;
   }
   .CommentSummary .LeftImageBox .VideoImage{
       width: 84px;
   }
}


@media screen and (min-width: 1240px) {
   .hide_in_desktop_commentdm{
       display: none !important;
   }
}


@media screen and (min-width: 960px) {
   .hide_in_desktop_menuicon{
       display: none !important;
   }
}


@media screen and (max-width: 1239px) {
   .hide_in_mobile_commentdm{
       display: none !important;
   }
}

@media screen and (max-width: 1239px){
   .inboxDMListContainer {
      max-height: calc(100vh - 300px) !important;
      overflow: scroll;
  } 
}