.AmountDiv {
    padding: 48px 128px;
}

.AmountDiv .MuiInputBase-input.MuiOutlinedInput-input {
    color: white;
    font-family: "Inter", sans-serif;
    font-size: 42px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.38;
    letter-spacing: normal;
    text-align: center;
}

.AmountDiv .MuiOutlinedInput-notchedOutline {
    padding: 0 48px;
}

.AmountDiv .AmountTextField{
    width: 500px;
}

@media screen and (max-width: 599px) {

    .AmountDiv {
        padding: 16px 32px;
    }

    .AmountDiv .MuiInputBase-input.MuiOutlinedInput-input {
        font-size: 32px;
    }

    .AmountDiv .AmountTextField{
        width: 100%;
    }

    .CommonText.InfoTextSm{
        white-space: nowrap;
        font-size: 18px;
    }
}