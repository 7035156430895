:root {
  --fontFamilykarla: Karla !important;
  --fontFamilyPoppins: Poppins !important;
}

.main-scroll-activity {
    width: 100%;
    /* height: 650px; */
    /* min-height: 60px; */
    /* max-height: 700px; */
    /* background-color: #fff; */
    /* border: 1px solid red; */
    overflow-y: auto;
    overflow-x: hidden;
  }

.main-scroll-activity::-webkit-scrollbar {
    width: 15px;
    height: 5px;
  }

  .profile-scroll-activity {
    width: 100%;
    /* height: 650px; */
    /* min-height: 60px; */
    /* max-height: 700px; */
    /* background-color: #fff; */
    /* border: 1px solid red; */
    overflow-y: auto;
    overflow-x: hidden;
  }

.profile-scroll-activity::-webkit-scrollbar {
    width: 15px;
    height: 5px;
  }

  /* Track */
  .profile-scroll-activity::-webkit-scrollbar-track {
    background:   black ;
    border-radius: 10px;
  }
  
  /* Handle */
  .profile-scroll-activity::-webkit-scrollbar-thumb {
    background-color: red;
    border-radius: 10px;
  }

  .caption-reposted{
    font-family:var(--fontFamilykarla) !important;
    font-size: 14px !important;
    font-weight: normal;
    padding: 5px 0px !important
  }

  .activity-duration{
    color: #8f92a1 !important;
    font-family:var(--fontFamilykarla) !important;
    font-size: 14px !important;
  }

  .activity-artistName{
    color: #8f92a1 !important;
    font-family: var(--fontFamilykarla) !important;
    font-size: 14px !important;
    /* font-weight: bold !important; */
  }
  .activity-timeDuration{
    color: #8f92a1 !important;
    font-family: var(--fontFamilykarla) !important;
    font-size: 14px !important;

  }
  .activity-three-data{
    font-family:  var(--fontFamilyPoppins) !important;
    font-size: 13px !important;
    font-weight: normal !important;
    color: #ffffff !important;
    align-items: center !important;
    display: flex !important;
  }

  .activity-user_name{
    font-family: var(--fontFamilykarla) !important;
    font-size: 16px !important;
    font-weight: bold !important;
  }

  .activity-three-head{
    display: flex !important;
    width: 75% !important;
    align-items: center !important;
    justify-content: space-around !important;
    background-color: rgb(13, 11, 11) !important;
    border-radius: 16.5px !important;
    border: solid 1px #2c2c2c !important;
  }

  .activity-head-card{
    background-color: black !important;
    border-radius: 15px !important;
    padding: 5px !important;
    border: 3px solid rgb(29, 28, 28) !important;
  }
  .activity-followers{
    color: #8f92a1;
    font-family: var(--fontFamilykarla) !important;
    font-size: 14px;
  }

  .activity-video-length{
    position: absolute;
    bottom: 10%;
    right: 8%;
    color: #8f92a1;
    font-family: var(--fontFamilykarla) !important;
    font-size: 14px;
  }

  .activity-margin-head{
    margin-top: -11px !important;
  }

  /* Track */
  .main-scroll-activity::-webkit-scrollbar-track {
    /* background: transparent;
    box-shadow: inset 0 0 5px #615f5f;
    border-radius: 7px;
    border-left: 1.5px solid transparent;
    border-right: 1.5px solid transparent;
     #585858 */
    background:   black ;
    border-radius: 10px;
  }
  
  /* Handle */
  .main-scroll-activity::-webkit-scrollbar-thumb {
    /* background: #afaaaa;
    border-radius: 7px; */
    background-color: red;
    border-radius: 10px;
  }
 /* @media screen and (max-width: 1200px) {
    .hide_in_medium-activity{
      display: none !important;
    }
  } */
  @media screen and (max-width: 948px) {
    .hide_in_medium-activity{
      display: none !important;
    }
  }
    /* @media screen and (min-width: 1201px) {
    .hide_in_mobile_and_desktop_activity{
      display: none !important;
    }
  } */

  .activity-notification-ellipses{
    color: gray !important;
  align-items: center !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 250px !important;
   }

   @media screen and (max-width: 771px) {
    .activity-notification-ellipses{
      max-width: 204 !important;
    }
   }

  @media screen and (min-width: 949px) {
    .hide_in_mobile_and_desktop_activity{
      display: none !important;
    }
  }

  .notifications_height{
    max-height: 500px;
  }

  @media screen and (max-width: 650px) {
    .hide_in_mobile_and_desktop_activity{
      display: none !important;
    }
    
    .notifications_height{
      max-height: 200px;
    }

    .hide_for_mobile_view_activity{
      display: none !important;
    }
  }



  @media screen and (min-width: 651px) {
    .hide_in_tab_and_desktop_activity{
      display: none !important;
    }
  }

 




  .row_activity {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 0 4px;
  }
  
  /* Create four equal columns that sits next to each other */
  .column_activity {
    -ms-flex: 33%; /* IE10 */
    flex: 33%;
    max-width: 33%;
    padding: 0 4px;
  }
  
  .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .column_activity {
      -ms-flex: 50%;
      flex: 50%;
      max-width: 50%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column_activity {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
    }
  }