.cover-width{
  width: 300px;
}

.small-div-flex{
  flex-direction: row;
}

.search-videos-scroll::-webkit-scrollbar-thumb {
  background: red; 
  border-radius: 10px;
}

.search-videos-scroll::-webkit-scrollbar {
  width: 15px;
}
.search-videos-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}

.search-videos-scroll {
  width: 100%;
  /* height: 650px; */
  /* min-height: 60px; */
  /* max-height: 700px; */
  /* background-color: #fff; */
  /* border: 1px solid red; */
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
 }

@media screen and (max-width: 1080px) {
  .small-div-flex{
    flex-direction: column;
  }
}

@media screen and (min-width:960px) and (max-width:1156px) {
  .video-grid {
   max-width: 100%;
   padding-bottom: 10px;
  }
  .name-grid{
    max-width: 100%;
  }
}

@media screen and (min-width: 1157px){
  .video-grid {
    width: 33%;
   }
   .name-grid{
    max-width: 67%;
  }

  .profile-img-margin{
    margin-left: 8px !important;
  }
}

@media screen and (min-width: 1280px){
  .repost-icon-margin{
    margin-left: -32px !important;
  }
}

@media screen and (max-width: 1380px){
    .cover-width{
        width: 200px;
      }
}