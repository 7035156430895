.MainLayoutContainer {
    background-color: black;
    overflow: hidden;
}

.MainContent {
    margin: 0px;
    background-color: black;
    display: flex;
    flex-direction: column;
}

.MainContent .MT32 {
    margin-top: 32px;
}

.MainContent .UserChartDiv {
    width: 287px;
    height: 148px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 16px 0px 16px;
}

.MainContent .ChartBottom {
    width: 287px;
    height: 19px;
    border-radius: 5px;
    background-color: #212121;
    display: flex;
    justify-content: center;
}

.MainContent .ChartDataText {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: white;
}


.MainContent .CommonText {
    font-family: "Inter", serif;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
}

.CommonText.StatsTitle {
    font-size: 16px;
    font-weight: 800;
    color: white;
}

.CommonText.StatsCardTitle {
    font-size: 12px;
    font-weight: 800;
    color: rgba(255, 255, 255, 0.5);
}

.CommonText.StatsCardSubTitle {
    font-size: 24px;
    font-weight: 800;
    color: white;
}

.MainContent .InfoTextSm {
    font-size: 20px;
    font-weight: 900;
    line-height: normal;
    color: white;
}

.MainContent .PerformanceStatDiv {
    width: 348px;
    margin-top: 16px;
}

.PerformanceStatDiv .PerformanceCard {
    padding: 5px 20px 2px 21px;
    border-radius: 10px;
    background-color: #121212;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.MainContentDiv {
    display: flex;
    width: 100%;
}

.PlayCountText {
    display: flex;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: white;
}

.UserCard {
    height: 76px;
    width: 348px;
    background-color: #000000;
    border-radius: 100px;
    position: relative;
    margin-top: 8px;
}

.UserCard.Active {
    background-color: #212121;
}

.UserCard .ContentDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    height: 100%;
    padding: 4px 16px;
}

.UserCard .UserIcon {
    width: 52px;
    height: 52px;
    border: 3px solid black;
    object-fit: contain;
    border-radius: 100px;
}

.UserCard .UserInfoDiv {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 68px;
    width: 100%;
    margin-left: 16px;
}

.UserCard .UserNameText {
    font-family: "Inter", sans-serif;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: white;
    display: flex;
    align-items: center;
}

.UserNameText .CheckIcon{
    background-color: #3959f9;
    border-radius: 100px;
    height: 16px;
    width: 16px;
    margin-left: 4px;
}

.UserCard .UserEquityText {
    display: flex;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: white;
}

.MainContentDiv .LgRightPanel {
    width: 100%;
    height: 90vh;
    overflow: hidden;
    position: relative;
    border-left: 5px solid #212121;
    border-top: 5px solid #212121;
    border-top-left-radius: 100px;
}


.LgRightPanel .ActionButtonContainer {
    position: absolute;
    bottom: 15%;
    width: 100%;
}




@media screen and (max-width: 1079px) {
    .MainContentDiv {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 599px) {
    .MainContent .PerformanceStatDiv{
        width: 328px;
    }

    .MainContent .ActionButtonContainerSm {
        margin-top: 4px;
    }
}