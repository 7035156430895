/* otp css */
.infoforotp {
  margin: 6px auto;
  /* margin-left: 180px; */
}

.infoforotp input {
  background-color: rgb(24, 24, 24);
  border-bottom: 1px solid rgb(189, 189, 189);
  border-top: none;
  border-left: none;
  border-right: none;
  color: white;
  /* width: 500px; */
  height: 20px;
}

.infoforotp label {
  color: rgb(121, 121, 121);
  float: left;
}

.otp-button button {
  width: 100%;
  position: relative;
  padding: 10px;
  border-radius: 50px;
  font-weight: 600px;
  font-size: 16px;
  border: none;
  background-color: red;
  color: white;
  margin-top: 20px;
  margin-bottom: 10px;
}

.formotp {
  width: 85%;
  margin: auto;
}

.otp-text h2 {
  margin-top: 15px;
  margin-bottom: 20px;
  color: white;
}
.otp-text p {
  color: white;
  margin-bottom: 20px;
  line-height: 20px;
}
.otp-text {
  margin-bottom: 43px;
}
.otp-input {
  justify-content: center;
  margin: 50px 0 8px 0;
}
.otp-input input {
  width: 2em !important;
  justify-content: center;
  background-color: black;
  margin: 10px;
  border-bottom: 3px solid #212121;
  border-top: none;
  border-left: none;
  border-right: none;
  color: white;
}

.otp-input input:focus-visible{
  border-bottom: 3px solid #3959f9 !important;
  outline: 0;
}
.time {
  color: rgb(189, 189, 189);
}
.back-btn button {
  background-color: #262626;
  color: white;
  width: 50px;
  border: none;
  border-radius: 20%;
}

.button-back button {
  /* color: white; */
  color: #8b8b8b !important;
  width: 80px;
  background-color: #17181c;
  border: none;

  text-align: left;
  border-radius: 20%;
}
.button-back {
  margin: 47px 20px;
}
.button-back button span {
  padding: 5px;
}
.logo-img {
  height: 42px;
  margin: 40px 20px;
}

/* otp.js css ends */
