.ForListener {
  background-color:black;
  margin: 0;
  padding: 0;
  height: 100% !important;
}
.listener-image img {
  width: 50px;
  height: auto;
  left: 0;
  position: absolute;
  margin: 35px;
}
.listener-back-btn {
  text-align: start;
  margin-top: 10px;
}
.listener-plan {
  padding: 20px !important;
  align-items: center !important;
    display: flex !important;
    flex-direction: column !important;
    margin: auto !important;
}
.listener-back-btn {
  text-align: start;
}
.listener-back-btn button {
  text-align: center;
  background-color: #181818;
  color: #8b8b8b !important;
  width: 80px;
  margin: 10px;
  border: none;
  border-radius: 20%;
  margin-left: 10px;
  cursor: pointer;
}
.listener-image {
  background-image: url("./ForListener.png");
  height: 100vh;
  /* height: 100%; */
  border-radius: 0 15px 15px 0;
  background-size: cover;
}
.listener-head h1 {
  color: white !important;
  font-size: 28px;
}
.listener-head h3 {
  color: white;
}
.listener-buttons {
  justify-content: space-evenly;
  margin: 40px 0;
  display: flex;
  flex-direction: column !important;
  width: 100% !important;
  align-items: center;
}

.plan_align{
  display: flex !important;
  padding: 10px 20px !important;
  flex-direction: row !important;
}

@media screen and (max-width: 480px) {
  .plan_align{
    flex-direction: column !important;
  }
  .plan_name_acc{
    justify-content: center !important;
  }
}

.plan_name_acc{
  justify-content: left ;
    display: flex !important;
}

.listener-buttons h1 {
  color: white !important;
  font-weight: bolder;
}
.listener-buttons h2 {
  color: white;
}
.listener-buttons button {
  color: white !important;
  background-color: black !important;
  border-radius: 15px;
  border: none;
  width: 98%;
  /* margin: 10px 0; */
  padding: 10px 0;
margin: 0;
  transition: 0.5s;
  cursor: pointer;
}

.listener-buttons button:hover {
  background-color: black(53, 53, 255);
}
.listener-continuebtn button {
  width: 80%;
  position: relative;
  padding: 10px;
  border-radius: 50px;
  font-weight: 600px;
  font-size: 16px;
  border: none;
  background-color: red;
  color: white !important;
  margin: 10px 0;
  height: 50px;
  cursor: pointer;
}
.listener-section {
  width: 75%;
  margin: auto;
}

.button-back button {
  color: #8b8b8b !important;
  width: 100px;

  text-align: left;
  background-color: #17181c;
  border: none;
  border-radius: 20%;
}
.button-back {
  margin: 47px 20px;
}
.listener-back-btn button span {
  /* padding: 5px; */
  padding: 0px 5px 4px 0px;
}
.logo-img {
  height: 42px;
  margin: 40px 20px;
}
@media screen and (max-width: 767px) {
  .listener-buttons {
    margin: 20px 0;
  }
  .listener-back-btn {
    margin: 5px 0;
  }
  .listener-plan {
    padding-top: 0;
  }
  .listener-buttons button {
    margin: 5px;
    padding: 3px 0;
  }
  .listener-head h1 {
    font-weight: 20px;
    padding: 0;
  }
  .listener-continuebtn button {
    margin: 5;
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .listener-buttons {
    margin: 20px 0;
  }
}
.listener_btn button {
  width: 100%;
  max-width: 400px;
  /* position: relative; */
  padding: 10px;
  border-radius: 50px;
  font-weight: 600px;
  font-size: 16px;
  border: none;
  background-color: red;
  color: white !important;
  margin: 10px 0;
  height: 50px;
  cursor: pointer;
}
.listener_btn button:disabled {
  width: 100%;
  max-width: 400px;
  /* position: relative; */
  padding: 10px;
  border-radius: 50px;
  font-weight: 600px;
  font-size: 16px;
  border: none;
  background-color: rgb(63, 63, 63);
  color: white !important;
  margin: 10px 0;
  height: 50px;
  cursor: not-allowed;
}
