.button_dashboard{
    border-bottom-right-radius: 20.5px !important;
    border-top-right-radius: 20.5px !important;
    color: #ffffff !important;
   /* background-color: #171717 !important; */
    background-color: #212121 !important;
    width: 68px !important;
    cursor: pointer !important;
    height: 35px !important;
    margin-left: -14px !important;
    margin-top: 5px;
}


.button_dashboard:disabled {
    background-color: #274af4 !important;
    cursor: not-allowed !important;
  }