:root {
  --fontFamily: Inter !important;
 }
 
 
 .defaultDMcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 20px;
 }
 
 .showPrivateMessageList{
  color: white !important;
    padding: 7px 35px !important;
    font-family: var(--fontFamily) !important;
    font-size: 32px !important;
    font-weight: 900 !important;
    text-align: center !important;
}

 .deafultcaption {
  text-align: center;
  margin-top: -10px;
  color: white;
  font-family: var(--fontFamily) !important;
  font-size: 16px;
  font-weight: 600;
 }
 
 
 .defaultHeading {
  color: white;
  font-size: 24px;
  font-weight: bold;
 }
 
 
 
 
 .defaultButton {
  color: white;
  padding: 7px 35px;
  border-radius: 100px;
  background-color: #3959f9;
  cursor: pointer;
  font-family: var(--fontFamily) !important;
  font-size: 22px;
  font-weight: 900;
 }
 
 
 
 
 /* Comment Chat Dialog */
 .DMChatDialog .MuiPaper-root{
  background-color: black;
  justify-content: space-between;
 }
 .DMChatDialog .DMChatBox {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  width: 60%;
 }
 .DMChatDialog .DMChatBox .CommentChatInput{
  margin-bottom: 16px;
  width: 100%;
  background-color: black;
 }
 .DMChatDialog .DMChatBox .CommentChatContainer{
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 50px;
  width: 100%;
 }
 .DMChatDialog .DMChatBox .CommentChatContainer::-webkit-scrollbar {
  display: none;
 }
 /* Comment Chat Dialog END */
 .self-profile-particularuser{
  border-radius: 50% !important;
  height: 150px !important;
  width: 150px !important;
  cursor: pointer !important;
  object-fit: cover !important;
  border: solid 2px #212121 !important;
 }

 .self-profile-particularuser1{
  border-radius: 50% !important;
  height: 45px !important;
  width: 45px !important;
  cursor: pointer !important;
  object-fit: cover !important;
  border: solid 2px #212121 !important;
 }
 
 .direct-message-track-artwork{
  height: 60px;
  width: 60px;
  border-radius: 60px;
  border-width: 5px;
  border-color: rgb(20, 20, 20);
  position: absolute;
  margin-left: -30px;
  z-index: 99;
  background-color: rgb(20, 20, 20);
  padding: 8px;
 }

 .direct-message-track-middle-artwork{
  height: 29px;
  width: 29px;
  border-radius: 29px;
 }

 .dm-creative-defaultimg{
  height: 29px;
  width: 29px;
  border-radius: 29px;
  border: 1px solid #212121;
 }

 .dm-child-creative-defaultimg{
  display: initial !important;
  width: 65% !important;
  height: 55% !important;
  object-fit: cover !important;
  margin-top: 0% !important;
  margin-left: 19% !important;

}

.trackDm-artist-style {
  color: rgb(255, 255, 255) !important;
  font-size: 12px !important;
  font-weight: bold !important;
  font-family: var(--fontFamily) !important;
}

.trackDm-creativeType-style {
  color: rgba(143, 146, 161, 0.5) !important;
  font-size: 12px !important;
  font-weight: bold !important;
  font-family: var(--fontFamily) !important;
}

.textMsgCard-parent{
    display: flex !important;
    justify-content: flex-end !important;
  }

  .textMsgCard-child-div{
    display: flex !important;
    width: 100% !important;
    justify-content: flex-end !important;
  }

  .text-receiver-width{
    color: white !important;
    width: 276px !important;
  }

 @media screen and (max-width: 830px) {
  .DMChatDialog .DMChatBox {
    width: 80%;
  }
 } 