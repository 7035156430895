:root {
  --fontFamily: Inter !important;
}

.deactive-title {
  color: red;
  font-size: 18px;
  font-family: var(--fontFamily) !important;

  font-weight: 800;
  text-align: center;
}

.deactive-heading {
  font-size: 19px;
  font-weight: 800;
  color: white;
}

.deactive-body {
  color: white;
  font-family: var(--fontFamily) !important;
}
