* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.MainBody {
  width: 85%;
  background-color: black;
}
.image img {
  width: 100%;
  height: 50vh;
  background-attachment: fixed;
  object-fit: cover !important;
}
.image h3 {
  color: black !important;
  z-index: 1 !important;
  position: absolute;
}

.head {
  justify-content: space-between;
  display: flex;
  padding: 0px 15px;

  /* padding-top: 10px; */
  /* padding: 20px; */
}
.head h2 {
  font-size: 24px;
  color: white;
}
.head h4 {
  font-weight: lighter;
  color: rgb(204, 204, 204);
  letter-spacing: 1px;
}

.box::-webkit-scrollbar {
  display: none;
}
audio {
  width: 100% !important;
  background-color: black;
}

.nxz-sound {
  margin: 0 40px;
}
.nxz-row1 {
  display: flex;
}
.nxz-row2 {
  display: flex;
}
.nxz-sound .distribution {
  width: 68%;
  border: 1px solid rgb(104, 104, 104);
  border-radius: 8px;
  margin: 10px;
}
.nxz-sound .marketing {
  width: 32%;
  border: 1px solid rgb(104, 104, 104);
  border-radius: 8px;
  margin: 10px;
}
.nxz-col {
  width: 33%;
  border: 1px solid rgb(104, 104, 104);
  border-radius: 8px;
  margin: 10px;
}
.nxz-sound img {
  width: 100%;
  border-radius: 8px;
  z-index: -1;
  height: 15rem;
}
.nxz-sound h2 {
  color: black !important;
  position: absolute;
  z-index: 1;
  /* bottom: 0; */
}

.nxz-financing {
  background-image: url("http://images.unsplash.com/photo-1470225620780-dba8ba36b745?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max");
  text-align: center;
  padding: 150px;
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  /* background-size: cover; */
  background-repeat: no-repeat;
  background-size: cover;
  margin: 20px 10px;
  border-radius: 8px;
}
.nxz-financing h1 {
  color: white;
  font-size: 2.4rem;
}
.nxz-financing h3 {
  color: rgb(187, 187, 187);
  font-size: 1.4rem;
  padding: 10px;
  font-weight: lighter;
  letter-spacing: 3px;
}
.nxz-financing button {
  background-color: black;
  color: white;
  border: none;
  padding: 8px 50px;
  font-size: 16px;
  border-radius: 12px;
  cursor: pointer;
}

.nxz-download {
  background-color: black;
  /* margin: 10px; */
  border-radius: 8px;
  padding: 100px;
  text-align: center;
}
.nxz-download h1 {
  color: white;
  font-size: 26px;
  font-family: "Poppins";
}
.nxz-download h3 {
  color: white !important;
  font-size: 16px;
  font-weight: normal;
  font-family: "Poppins";
}

/* ----------RowAlbum------------ */

.head {
  justify-content: space-between;
  display: flex;
  padding: 0px 15px;

  /* padding-top: 10px; */
  /* padding: 20px 50px; */
}
.head h2 {
  font-size: 24px;
  color: white;
}
.head h4 {
  font-weight: lighter;
  color: rgb(204, 204, 204);
  letter-spacing: 1px;
}
.box {
  display: flex;
  margin: 0 40px;
  overflow-y: hidden;
  overflow-x: scroll;
}
.box::-webkit-scrollbar {
  display: none;
}

/* ---------MusicBox------------- */

.music-Box video {
  width: 170px;
  height: 160px;
}
video {
  border-radius: 20px;
}
.music-Box {
  padding: 20px 20px 20px 0px;
}

.song-info {
  color: white;
  padding: 0 10px;
}
.song-info h2 {
  font-weight: lighter;
  font-size: 17px;
  font-family: "karla";
}
.song-info p {
  color: rgb(187, 187, 187);
}

/* ------------TopVideos--------- */

.music-Box iframe {
  width: 300px;
  height: 170px;
  border-radius: 15px;
}

/* -----------Creative------------ */

.music-Box img {
  /* width: 170px;
  height: 150px; */
  width: 175px;
  height: 175px;
  border-radius: 50%;
  object-fit: cover;
}
.singer-info {
  color: white;
  padding: 10px 0;
  text-align: center;
}
.singer-info h2 {
  font-weight: bold;
  font-size: 17px;
}
.singer-info p {
  color: rgb(187, 187, 187);
}
