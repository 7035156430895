/* .tab-body{
    color: #fff;
   
}
.ant-tabs{
    width: 100% !important;
    color: white !important;
}
#search_custom_tab .ant-tabs-tab{
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -1px;
    color: #8f92a1;
}
.ant-tabs-tab:hover{
 color: white;
}
#search_custom_tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white;
  font-weight: bolder;
  font-size: large;
    text-shadow: 0 0 0.25px currentcolor;
}
#search_custom_tab .ant-tabs-ink-bar {
   
    border-bottom: 2px solid red;
}

#search_custom_tab .ant-tabs-tab + .ant-tabs-tab {
    margin:0px 0px 0px 32px;
    
}
.ant-tabs-top > .ant-tabs-nav::before{
    opacity: 0.2;
    border-bottom: 1px solid #c6e5ff;
    
    
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
}

.chats-header{
    display: flex;
    justify-content: space-between;
}
.ant-tabs-tab-btn {
    
    transition: none;
}

input::placeholder {
    color: #8f92a1;
    font-size: 12px;
  font-weight: normal;
  padding:0px 11px;
}
.loader-charts{
    position: fixed;
    left: 0px;
    top:0px;
    width: 120%;
    height: 120%;
    z-index: 9999;
}

.loader-top{
    top: 134px !important; 
} */

.tab-body {
  color: #fff;
}

.active-chart-tab {
  text-transform: capitalize !important;
  color: white !important;
  font-weight: 700 !important;
  font-size: large !important;
  text-shadow: currentcolor 0px 0px 0.25px !important;
  border-bottom: 0.3px solid !important;
  min-width: 90px !important;
}

.non-active-chart-tab {
  text-transform: capitalize !important;
  color: rgb(105, 108, 119) !important;
  font-weight: 700 !important;
  font-size: large !important;
  text-shadow: currentcolor 0px 0px 0.25px !important;
  border-bottom: 0.3px solid !important;
  min-width: 90px !important;
}

#search_custom_tab .ant-tabs-tab {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -1px;
  color: #8f92a1;
  /* border-bottom: 1px solid gray; */
}

.ant-tabs-tab:hover {
  color: white;
}

.example::-webkit-scrollbar {
  display: none;
}

#search_custom_tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white;
  font-weight: bold;
  font-size: large;
  text-shadow: 0 0 0.25px currentcolor;
}

#search_custom_tab .ant-tabs-ink-bar {
  border-bottom: 2px solid red;
}

#search_custom_tab .ant-tabs-tab + .ant-tabs-tab {
  margin: 0px 0px 0px 32px;
}

.search_bar .ant-input-affix-wrapper {
  border-radius: 20px;
  background-color: rgb(40, 40, 40);
  border: 2px solid rgb(40, 40, 40);
}
.search_bar .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}
.search_bar
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border: none !important;
}
.search_bar .ant-input-affix-wrapper > input.ant-input {
  background-color: rgb(40, 40, 40) !important;
  color: white !important;
}

.search_tra {
  /* width: 107px; */
  border-radius: 28px;
  background-color: rgb(9, 9, 9);

  height: 30px;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -1px;
  color: #8f92a1;
}

.search_tra .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-bottom: 0px !important;
}

.track_type {
  margin-right: 30px;
}

@media screen and (max-width: 750px) {
  .track_type {
    margin-left: 25px;
    margin-right: 10px;
    margin-top: -10px;
  }
}

@media screen and (max-width: 575px) {
  .menuDrop {
    font-size: 10px;
  }
}

@media screen and (min-width: 575px) {
  .search-bar {
    display: none !important;
  }
  .search_bar {
    display: none !important;
  }
}

.mostly-customized-scrollbar::-webkit-scrollbar-track {
  /* width: 12px;
  
  border-radius: 6px;

  border: solid 1px #222;

  background-color: #191919; */

  background: #ec0606;
  border-radius: 10px;
}

/* Add a thumb */
.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
  /* border-radius: 6px;
  border: solid 1px #d3c9c9;
  background-color: #8e8d8d; */
  background-color: rgb(43, 43, 46);
  border-radius: 10px;
}
