.dialog-Paper-share-popup {
    background-color: #020202 !important;
    border-radius: 40px !important;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5) !important;
    border: solid 5px #1f1f1f !important;
    width: 701px !important;
    height: 270px;
    /* height: 250px !important; */
  }

  .container_share{
    display: flex;
     justify-content: space-between;
      padding: 50px; 
      align-items: center;
  }

  .mobile_margin_top{
    margin-top: 0px;
}
  @media screen and (max-width: 599px) {
    .container_share{
        flex-direction: column;
        padding: 15px 0px 0px 0px;
    }
    .mobile_margin_top{
        margin-top: 15px;
    }
  }

  .qrCode-img-playlist{
    width: 140px;
    height: 140px;
  }