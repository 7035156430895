.topstatscontainer {
    margin-top: 20px;
    height: 54px;
    padding: 7px 64.9px 7px 26px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #212121 !important;
    color:white
}
.topAnaliticbox{
    height: 600px;
    overflow-x: scroll;
    scrollbar-color: red;
    margin-top: 20px;
    padding: 0px 60px 0px 16px;
    margin-bottom: 10px;
}
.analytics_topheader_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 60px 0px 16px 
}
.analytics_topheader_element{
    border-radius: 20px;
    background-color: #212121;
    display: flex;
    justify-content: space-evenly;
    padding: 6px
}
.topstats_container_element{
    display: flex;
    gap: 8%;
}
.allplays_stats{
    display: flex;
    gap: 8%;
}
.analytics_slider{
    width: 381px;
}
.Top-141 {
    font-size: 22px !important;
    font-weight: 900 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.36 !important;
    letter-spacing: normal !important;
    text-align: left !important;
    color: #ffff !important;
  }
  .stats_tab{
    font-size: 16px !important;
    font-weight: 900 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.36 !important;
    letter-spacing: normal !important;
    color: #ffff !important;
    border-radius: 35px !important
  }
  



/* responsive */
@media screen and (max-width:600px) {

  
    .topAnaliticbox{
        padding: 0px 10px 0px 16px;
        /* flex-direction: column; */
    }
    .analytics_topheader_container{
        flex-direction: column-reverse;
        align-items: normal;
        width: 392px;
        gap: 10px;

    }
    .topstatscontainer{
        flex-direction: column;
        height: auto;
        align-items: normal;
        padding: 7px 12.9px 7px 26px;
    }
    .allplays_stats{
        display: flex;
        gap: 8%;
        justify-content: space-between;
    }
    .analytics_slider{
        width: auto;
    }
}
@media (min-width: 601px) and (max-width: 950px) {
    .analytics_slider {
      /* Adjust styles for screens with a width between 600px and 900px */
     width: 160px;
    }
    .topAnaliticbox{
        padding: 0px 10px 0px 16px;
        /* flex-direction: column; */
    }
  }