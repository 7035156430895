/* welcome.js css  */
:root {
  --fontFmaily: Inter;
}

.bgDark {
  background-color: black !important;
  /* height: 100vh; */
  /* height: 100%; */
}
 .ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon{
  display: none !important;
}
.welcome-text h2 {
  margin-top: 15px;
  margin-bottom: 20px;
  color: white;
}
.welcome-text p {
  color: white;
  margin-bottom: 10px;
  line-height: 20px;
}
.welcome-text {
  margin-bottom: 43px;
}
.inter{
  font-family: var(--fontFmaily) !important;
}

.bgImg-account{
  background-image: url("../../email-account-registration/assets/Account type background.webp");
  background-repeat:no-repeat;
  background-size:contain;
  /* background-position:center; */
}
.signup-btn {
  width: 65%;
  margin: auto;
}
.welcomeLogo img {
  height: 50px;
}
.signup-btn button {
  width: 100%;
  position: relative;
  padding: 10px 5px;
  border-radius: 50px;
  font-weight: 600px;
  font-size: 16px;
  border: none;
  color: white;
  margin-bottom: 10px;
}
.gglebtn svg {
  height: 20px;
  padding-right: 10px 2px;
  transform: translate(0px, 3px);
}
.gglebtn {
  background-color: rgb(35 36 40);
}
@media screen and (max-width: 767px) {
  .bgImg-welcome {
    display: none !important;
  }

}
@media screen and (max-width: 600px) {
  .bgImg-account {
    display: none !important;
  }

}
@media screen and (max-width: 991px) {
.bgImg-create-acc{
  display: none !important;

}
}

@media screen and (max-width: 959px) {
  .bgImg-welcome-create {
    display: none !important;
  }

}

@media screen and (max-width: 768px) {

  .signup-btn button {
    font-size: 14px;
  }
  .signup-btn {
    width: 100%;
    margin: auto;
  }
  .welcome-form {
    margin-top: 20px;
  }
  .accType-form {
    margin-top: 20px;
  }

  .otp-button button {
    width: 200px;
    margin-left: 50px;
  }
}
.login_form .ant-input {
  background-color: black !important;
  border-color: black !important;
  border-bottom: 1px solid #7a7e8a !important;
  color: white !important;
  padding:4px  0px !important;
}
.login_form .ant-input:focus,
.ant-input-focused {
  box-shadow: none !important;
}
.login_form .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}
.login_form
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}
.login_form .ant-form-item-explain-error {
  text-align: start !important;
  margin-top: -8px !important;
}
.login_form .ant-form-item-explain,
.ant-form-item-extra {
  color: white !important;
  text-align: end !important;
  margin-top: 13px !important;
  cursor: pointer !important;
}
.login_form .ant-input-affix-wrapper {
  background-color: black !important;
  border-color: black !important;
  border-bottom: 1px solid #7a7e8a !important;
  padding: 0px !important;
}
.login_form .no_border .ant-input-affix-wrapper{
  border-bottom: none !important;
}
.login_form .ant-input-affix-wrapper > input.ant-input {
  border-bottom: none !important;
}
.login_form .ant-input-borderless, .ant-input-borderless:hover, .ant-input-borderless:focus, .ant-input-borderless-focused, .ant-input-borderless-disabled, .ant-input-borderless[disabled]{
  outline: none !important;
}
.login_form .ant-input-suffix{
  display: none !important;
}
.login_form .ant-form-item-label > label {
  color: #8f92a1 !important;
}
.login_btn {
  width: 100%;
  border-radius: 30px;
  height: 60px;
  font-size: 16px;
}
.reset_input .ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon{
  display: none !important;
}
.google_btn {
  background-color: #232428 !important;
  border: 1px solid #232428 !important;
  width: 100%;
 
  border-radius: 30px;
  height: 60px;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.google_btn span {
  margin-left: 8px;
}
/* end welcome.js css  */
