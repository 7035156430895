.AudioVideoCard {
    height: 76px;
    width: 348px;
    background-color: #212121;
    border-radius: 20px;
    position: relative;
    margin-top: 4px;
    margin-bottom: 4px;
}


.AudioVideoCard .AudioVideoBackgroundLeft {
    width: 130px;
    height: 76px;
    background-color: #333333;
    border-radius: 20px;
    position: absolute;
    z-index: 0;
}

.AudioVideoCard .ContentDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    height: 76px;
    padding: 4px 16px;
    z-index: 1;
    position: relative
}

.AudioVideoCard .AudioVideoImageDiv {
    position: relative;
    border: solid 3px black;
    border-radius: 5px
}


.AudioVideoCard .AudioVideoImage {
    width: 52px;
    height: 52px;
    object-fit: cover;
}

.AudioVideoImage.VideoImage {
    width: 80px;
}

.AudioVideoCard .AudioVideoPlayBtn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    height: 30px;
    width: 30px;
    right: 20%;
    top: 25%;
}
.AudioVideoPlayBtn .Avatar{
    height: 26.5px;
    width: 26.5px;
}

.AudioVideoPlayBtn.VideoType {
    right: 30%;
    top: 25%;
}

.AudioVideoCard .AudioVideoInfoDiv {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 76px;
    width: 100%;
    margin-left: 16px;
}

.AudioVideoInfoDiv .SongNameText {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: white;
}

.AudioVideoInfoDiv .SongWriterText {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: white;
}

.AudioVideoInfoDiv .PlayCountText {
    display: flex;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: white;
}

@media screen and (max-width: 599px) {

    .AudioVideoCard {
        width: 328px;
    }
}