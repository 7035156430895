:root {
  --fontFmaily: Inter;
}

.spinloadder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* .trackMain {
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: rgb(31, 31, 31);
  max-height: 100vh;
} */

.trackLayout {
  /* background-color: rgb(31, 31, 31);
  height: 100vh; */
  /* max-width: 100vw; */
  overflow: hidden;
  background-color: rgb(31, 31, 31);
  /* height: 100vh; */
}

/* .trackContent {
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: rgb(9, 9, 9);
} */

.track-content {
  padding: 10px 10px;
  background-color: #000;
}

.track_charts {
  /* border: 2px solid red; */
  /* height: 100%; */
}

.singlesong_carasoul_height{
  height: 970px;
}

@media screen and (max-width:600px) {
  .singlesong_carasoul_height{
    height: 935px;
  }
}
.track_container {
  /* max-height: 92vh; */
  /* height: auto; */
  overflow-x: hidden;
  overflow-y: auto;
  /* border: 2px solid blue; */
}

.profile_bg_text {
  /* background-color: rgba(0, 0, 0, 0.4); */
  color: white;
  /* font-weight: bold; */
  /* font-size: 80px; */
  /* border: 10px solid #f1f1f1; */
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.blur_bg_profile_img {
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 288px;
  -webkit-filter: blur(100px);
  filter: blur(10px);
  margin: 5px;
  width: 100%;
}

.MuiAccordion-root.Mui-expanded{
  margin: 0px;
}

.track-image-dialog {
  /* width: 50%;
    height: 50%; */
  width: 150px;
  height: 150px;
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.playbtn_image {
  margin-right: 5px;
  position: absolute;
  top: 29%;
  left: 45%;
  height: 36px;
  cursor: pointer;
}

.track_title_name {
  font-family: var(--fontFamily) !important;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  /* padding-left: 125px; */
  /* padding: 10px 0px; */
}

.track_username_info {
  /* width: 200px; */
  width: 100%;
  height: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.track_username_font {
  /* width: 47px; */
  height: 15px;
  font-family: var(--fontFamily);
  font-size: 12px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
}

.veryfied_user {
  width: 26px;
  height: 20px;
  background-color: #3959f9;
  border-radius: 122px;
}

.display_play_icon {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 20px;
}

/* chart contain */

.chart_container {
  /* width: 380px; */
  height: 620px;
  margin: 10px 0px;
  border-radius: 15px;
  background-color: #040404;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center;*/
}

.display_chart_font {
  font-family: var(--fontFamily);
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

.chart_body {
  /* width: 345px; */
  height: 520px;
  margin: 10px;
  padding: 20px;
  border-radius: 15px;
  background-color: #141414;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center;*/
}

.chart_bar {
  /* height: 182px; */
  /* margin: 0 0 34px; */
  /* width: 330px; */
  /* height: 230px; */
  padding: 15px;
  border-radius: 20px;
  background-color: #000000;
}

.chart-Bar-line {
  /* width: 400px;
  height: 150px; */
  position: relative;
}

@media screen and (min-width: 250px) and (max-width: 300px) {
  .chart-Bar-line {
    width: 150px;
  }
}

@media screen and (min-width: 300px) and (max-width: 350px) {
  .chart-Bar-line {
    width: 200px;
  }
}

@media screen and (min-width: 350px) and (max-width: 400px) {
  .chart-Bar-line {
    width: 250px;
  }
}
@media screen and (min-width: 400px) and (max-width: 450px) {
  .chart-Bar-line {
    width: 300px;
  }
}

@media screen and (min-width: 450px) and (max-width: 510px) {
  .chart-Bar-line {
    width: 350px;
  }
}

@media screen and (min-width: 510px) and (max-width: 600px) {
  .chart-Bar-line {
    width: 400px;
  }
}
@media screen and (min-width: 600px) and (max-width: 650px) {
  .chart-Bar-line {
    width: 175px;
  }
}

@media screen and (min-width: 650px) and (max-width: 750px) {
  .chart-Bar-line {
    width: 200px;
  }
}

@media screen and (min-width: 750px) and (max-width: 850px) {
  .chart-Bar-line {
    width: 250px;
  }
}

@media screen and (min-width: 850px) and (max-width: 960px) {
  .chart-Bar-line {
    width: 300px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1010px) {
  .chart-Bar-line {
    width: 250px;
  }
}

@media screen and (min-width: 1010px) and (max-width: 1100px) {
  .chart-Bar-line {
    width: 275px;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1150px) {
  .chart-Bar-line {
    width: 325px;
  }
}

@media screen and (min-width: 1150px) and (max-width: 1200px) {
  .chart-Bar-line {
    width: 350px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1280px) {
  .chart-Bar-line {
    width: 375px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1300px) {
  .chart-Bar-line {
    width: 225px;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1450px) {
  .chart-Bar-line {
    width: 250px;
  }
}

@media screen and (min-width: 1450px) and (max-width: 1520px) {
  .chart-Bar-line {
    width: 300px;
  }
}

@media screen and (min-width: 1520px) and (max-width: 1590px) {
  .chart-Bar-line {
    width: 325px;
  }
}

@media screen and (min-width: 1590px) and (max-width: 1800px) {
  .chart-Bar-line {
    width: 350px;
  }
}


.this_year_graph_singleTrack {
  width: 250px;
  /* height: 46px; */
  text-align: center;
  background-color: #000000;
  color: #ffffff;
  font-family: var(--fontFamily);
  font-size: 14px;
  font-weight: bold;
  margin-top: -10px;
  margin-bottom: 20px;
  border-radius: 20px;
}

.split_category_font {
  font-family: var(--fontFamily);
  font-size: 12px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
}

/*comment*/
.comment_container {
  width: 100%;
  height: 970px;
  border-radius: 20px !important;
  background-color: #000000 !important;
  /* margin: 10px; */
}

.expand_comment_container {
  width: 100%;
  /* height: 970px; */
  border-radius: 20px !important;
  background-color: #000000 !important;
  /* margin: 10px; */
}

.comment_title {
  padding: 10px;
  background-color: #101010;
  border-radius: 20px 20px 0px 0px;
  margin-bottom: 15px;
}

.comment_font {
  font-family: var(--fontFamily);
  font-size: 22px;
  font-weight: 900;
  color: #ffffff;
}

.sellAll_font {
  font-family: var(--fontFamily);
  font-size: 14px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
}

.comment_body {
  /* max-width: 100%; */
  height: 770px;
  padding: 15px !important;
  margin: 10px 0px !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.expand_comment_body {
  /* max-width: 100%; */
  height: 75%;
  padding: 15px !important;
  margin: 10px 0px !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.expand_message_body {
  /* max-width: 100%; */
  height: 75%;
  padding: 15px !important;
  margin: 10px 0px !important;
  overflow-x: hidden;
  overflow-y: auto;
}

/* margin: "8px" , position: 'absolute', bottom: 50 ,width: '90%' */

.bottom_comment_send {
  margin: 8px;
  position: absolute;
  bottom: 0px;
  width: 95%;
}

@media screen and (max-width: 600px) {
  .expand_comment_body {
    height: 80%;
  }
  .bottom_comment_send{
    bottom: 0px;
  }
}
.user_comment_msg {
  /* width: 282px;
    height: 80px; */
  /* margin: 10px; */
  /* padding: 10px !important; */
  border-radius: 15px;
  background-color: #171717;
}

.comment_username {
  font-family: var(--fontFamily);
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
}

.comment_time_font {
  width: 60px;
  height: 15px;
  border-radius: 16px;
  background-color: #070707;
  font-family: var(--fontFamily);
  font-size: 10px;
  font-weight: bold;
  color: #717171;
  text-align: center;
}

.comment_msg {
  font-family: var(--fontFamily);
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  word-break: break-word;
  margin-top: 15px;
}

.comment_send_body {
  padding: 0px 12px;
  /* margin: 0px 10px;
  width: 326px !important; */
  height: 60px;
  border-radius: 60px;
  border: solid 2px #ffffff;
  background-color: #000000;
  /* display: flex; */
  width: 500px;
  align-items: center;
}

.comment_send_text {
  border-radius: 20px;
  /* border: 1px solid rgb(143, 146, 161); */
  border: none;
  font-family: var(--fontFamily);
  font-size: 15px !important;
  font-weight: 500;
  background-color: transparent;
  height: 42px;
  width: 100%;
  padding: 0px 5px;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
  outline: none;
}

.comment_send_btn {
  width: 60px;
  height: 30px;
  font-family: var(--fontFamily);
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  background-color: #212121;
  border-radius: 25px;
  padding: 5px 10px;
  cursor: pointer;
}

/* message box */

.message_box {
  /* margin: 10px; */
  /* padding: 10px !important; */
  border-radius: 15px;
  background-color: #3959f9;
}

.filter_Items {
  border: none;
  margin: 10px;
  cursor: pointer;
}

.filter_time_btn {
  /* height: 30px; */
  margin: 10px 0px;
  border-radius: 60px;
  border: none;
  padding: 15px 30px;
  width: 250px;
  font-family: var(--fontFamily);
  font-weight: bold;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.chartTypeFont {
  color: #fff;
  font-family: var(--fontFamily);
}

.filter_Content {
  padding: 20px;
  background-color: rgb(18, 18, 18);
  border-radius: 20px;
}
