.custom_card {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  /* background-color: rgb(31, 31, 31); */
  background-color: black !important;

  border: none !important;
}

.originals-fonts-head {
  color: white;
  font-weight: bold !important;
  margin-top: 10px;
  font-size: 14px !important;
  margin-bottom: 0px;
  font-family: "Karla" !important;
}

.originals-subhead-font {
  font-size: 14px;
  color: rgb(143, 146, 161);
  font-family: "Karla" !important;
}
.custom_card .ant-card-body {
  padding: 10px;
}

@media screen and (max-width: 786px) {
  .custom_card {
    width: 100%;
  }
}
@media screen and (max-width: 1020px) {
  .custom_card {
    width: 100%;
  }
}
