.Music-Split-Deals {
  width: 135px;
  height: 19px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: white;
  margin-bottom: 10px;
  cursor: pointer;
}
.deal_timing{
  
  color: #ffff;
  width: 355px;
  height: 41px;
  flex-grow: 0;
  object-fit: contain;
  background-color: #212121;
  border-radius: 0 0 30px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Deal-Expires-in-72h {
  width: 153px;
  height: 20px;
  /* font-family: Inter; */
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
}

.This-week {
  width: 80px;
  height: 19px;
  margin: 50px 39px 15px 301px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: rgba(255, 255, 255, 0.5);
}

.musicdeal_leftside_container:hover {
  border-radius: 10px;
  background-color: #212121;
}

.selected_deal {
  margin: 10px;
  height: 68px;
  padding: 0px 14px 0px 9px;
  /* border-radius: 8px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 10px;
  background-color: #212121;
}

.musicdeal_leftside_container {
  margin: 10px;
  height: 68px;
  padding: 0px 14px 0px 9px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.boximage {
  width: 70%;
  height: 402px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.musicdeal_right_side {
  width: 50%;
  height: 658px;
  padding: 0 2px 0 0;
  border-radius: 30px;
  border: solid 5px #212121;
  display: flex;
  justify-content: center;
  align-items:center;
  background-color: rgba(0, 0, 0, 0.75);
}
.topdealers_musicdeal_right_side {
  width: 50%;
  height: 658px;
  padding: 0 2px 0 0;
  border-radius: 30px;
  /* border: solid 5px #212121; */
  display: flex;
  justify-content: center;
  /* align-items: end; */
  /* background-color: rgba(0, 0, 0, 0.75); */
}
.musicdeal_right_side_contain{
  border-radius: 30px;
  border: solid 5px #212121;
  display: flex;
  width: 476px;
  height: 658px;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  flex-direction: column;
}

.split {
  width: 113px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 6px 24px;
  object-fit: contain;
  border-radius: 30px;
  background-color: #212121;
  margin-left: 66%;
 margin-bottom: 13%
}

.Music-Split {
  width: 65px;
  height: 18px;
  flex-grow: 0;
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffff;

}

.verfied {
  width: 13px;
  height: 13px;
  flex-grow: 0;
  object-fit: contain;
}

.sold {
  width: 182px;
  height: 76px;
  background-color: #212121;
  border-radius: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.logoverified {
  width: 50px;
  height: 50px;
  /* border-radius: 50px; */
}

.deal_elements {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.deal_elements_firstchild {
  width: 27px;
  height: 18px;
  /* font-family: Inter; */
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
}

.deal_elements_secondchild {
  width: 44px;
  height: 18px;
  /* font-family: Inter; */
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: normal;
  text-align: left;
  color: #ffff;
}

.Musicdealtop_container {
  display: flex;
  gap: 2.9%;
  justify-content: space-between;
}

.Deal-Offers {
  /* width: 90px; */
  /* height: 16px; */
  /* font-family: Inter; */
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #ffff;
  cursor: pointer;
}

.clickedDealoofers {
  width: 169px;
  height: 33px;
  flex-grow: 0;
  object-fit: contain;
  border-radius: 60px;
  background-color: #3959f9;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  font-weight: 800;
}

.DealinsideBox {
  border-radius: 30px;
  height: 47px;
  width: 355px;
  background-color: rgb(33, 33, 33);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Pending {
  width: 49px;
  height: 16px;
  /* font-family: Inter; */
  font-size: 12px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
}

.pendingStats {
  width: 27px;
  height: 16px;
  flex-grow: 0;
  /* font-family: Inter; */
  font-size: 42px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.38;
  letter-spacing: normal;
  text-align: left;
  color: #ffff;
}

.dealoffer_container {
  display: flex;
  justify-content: space-between;
  gap: 5px
}

.dealofferstats {
  width: 164px;
  height: 83px;
  flex-grow: 0;
  object-fit: contain;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
  flex-direction: column;
  background-color: #121212;
}

.Musicdealtop_container_element {
  width: 50%;
  height: 51vh
}

.change_status {
  width: 351px;
  height: 617px;
  object-fit: contain;
  border-radius: 30px;
  border: solid 3px #212121;
  background-color: #070707;
}

.leftside_music_deal:hover {
  height: 80px;
  padding: 7px 24px 10px 16px;
  object-fit: contain;
  border-radius: 10px;
  background-color: #212121;
}

.leftside_second_element {
  display: flex;
  justify-content: space-between;
  align-items: 'center'
}

.leftside_music_deal {
  display: flex;
  justify-content: space-between;
  align-items: 'center';
  height: 80px;
  padding: 7px 24px 10px 16px;
  object-fit: contain;
  border-radius: 10px;
}

.dealselection_right_box {
  display: flex;
  flex-direction: column;
  width: 98%;
  align-items: center;
  margin-top: 1%;
}

.deal_image {
  width: 60px;
  height: 60px;
  object-fit: contain;
  border-radius: 60px
}

.button_box {
  display: flex;
  margin-top: 18px;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  padding: 0px 0px 0px 42px;
}

.Make-An-Offer---Button {
  width: 208px !important;
  height: 40px !important;
  flex-grow: 0;
  object-fit: contain !important;
  border-radius: 30px !important;
  background-color: #212121 !important;
  color: white !important;
}

.dashboardDeal_offer_preview {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 30px;
  width: 351px;
  padding: 22px 24px 129px 24px;
}

.dashboardDeal_offer_preview .LabelBox {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.dashboardDeal_offer_preview .SplitDetailBox {
  display: flex;
  justify-content: space-around;
  align-content: center;
  height: 120px;
}

.Accepted {
  width: 299px;
  height: 55px;
  /* font-family: Inter; */
  font-size: 42px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.38;
  letter-spacing: normal;
  text-align: center;
  color: #ffff;
  margin-top: 22%;
}




/* screen size */
@media screen and (max-width: 700px) {
  .Musicdealtop_container {
    display: flex !important;
    gap: 290px !important;
    flex-direction: column !important;
  }

  .Musicdealtop_container_element {
    width: 100% !important;
    height: 51vh !important
  }

  .musicdeal_right_side {
    width: 100% !important;

  }

}