.creative-box {
  /* width: 270px; */
  height: 82px;

  color: white;
  display: flex;
  /* align-items: center; */

  border-radius: 10px;

  border: solid 1px rgba(151, 151, 151, 0.18);

  background-color: #121212;
  padding: 10px;

  margin-left: auto;
  margin-right: auto;
}

.creative-height {
  height: 275px !important;
}

/* @media screen and (max-width: 600px) {
  .creative-height {
    height: 330px !important;
  }
} */

.creative-box-number {
  width: 100%;
  height: 100%;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.creative-box-number #name {
  /* height: 20px; */
  /* margin: 0 13px 2px 0; */
  /* font-family: Karla; */
  margin-top: 8px;
  font-size: 14px;
  /* word-break: break-word; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -1px;
  color: #fff;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* width: 100px; */
  width: 61%;
  font-family: karla !important;
}
.creative-icon {
  display: flex;
  gap: 10px;
}
.creative-box-number #artist {
  width: 31px;

  height: 20px;

  margin-bottom: 0;

  font-family: Karla;

  font-size: 12px;

  font-weight: 500;

  font-stretch: normal;

  font-style: normal;

  line-height: 1.67;

  letter-spacing: normal;

  color: #8f92a1;
}
.creative-box-number #icon {
  font-family: Poppins;

  font-size: 10px;

  font-weight: 300;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: -0.2px;

  color: #3959f9;

  display: flex;
  align-items: center;
}
.creative-icon p {
  margin-bottom: 0;
}

.badge-icon {
  color: #ffffff;
  font-size: small;
  margin-top: 51px !important;
  right: 5.5px !important;
  padding: 2px;
  padding-right: 2.5px;
  padding-top: 2.5px;
}
.play_count_ellipse {
  display: inline-block;
  text-overflow: ellipsis;
  width: 27px;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
  margin-left: -6px !important;
}
/* 
@media screen and (max-width: 575px) {
  .creative-box {
    justify-content: center;
  }
  .creative-box-number {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
   
  }
  .creative-icon {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .creative-icon {
    display: flex;
   
  }
}

@media screen and (max-width: 1269px) {
  .creative-box {
    margin-bottom: 2px !important;
  
  }
}

@media screen and (min-width: 1920px) {
  .creative-height {
    height: 228px !important;
  }
} */
@media screen and (max-width: 488px) {
  .card_width {
    width: -webkit-fill-available !important;
    gap: 14px !important;
  }
  .outer_card_width {
    width: -webkit-fill-available !important;
  }
  .creative-box-number #name {
    margin-top: 0px !important;
  }
}

/* responsive card  */
/* @media screen and (min-width: 413px) and (max-width: 489px) {
  .card_width {
    width: 300px;
  }
} */
@media screen and (max-width: 555px) {
  .creative-box-number #name {
    margin-top: 8px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -1px;
    color: #fff;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 71%;
  }
}
@media screen and (min-width: 555px) and (max-width: 599px) {
  .card_width {
    width: 240px;
  }
  .creative-box-number #name {
    margin-top: 8px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -1px;
    color: #fff;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 41%;
  }
}
@media screen and (min-width: 599px) and (max-width: 615px) {
  .card_width {
    width: 261px;
  }
  .creative-box-number #name {
    margin-top: 8px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -1px;
    color: #fff;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 51%;
  }
}
@media screen and (min-width: 615px) and (max-width: 660px) {
  .card_width {
    width: 269px;
  }
  .creative-box-number #name {
    margin-top: 8px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -1px;
    color: #fff;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 49%;
  }
}
@media screen and (min-width: 660px) and (max-width: 700px) {
  .card_width {
    width: 292px;
  }
}
@media screen and (min-width: 700px) and (max-width: 810px) {
  .card_width {
    width: 300px;
  }
}

@media screen and (min-width: 810px) and (max-width: 880px) {
  .card_width {
    width: 240px;
  }
  .creative-box-number #name {
    margin-top: 8px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -1px;
    color: #fff;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 49%;
  }
}
@media screen and (min-width: 880px) and (max-width: 900px) {
  .card_width {
    /* width: 258px; */
  }
}
@media screen and (min-width: 900px) and (max-width: 960px) {
  .card_width {
    /* width: 269px; */
  }
}
@media screen and (min-width: 960px) and (max-width: 1015px) {
  .card_width {
    /* width: 300px; */
  }
}
@media screen and (min-width: 1015px) and (max-width: 1122px) {
  .card_width {
    /* width: 300px; */
  }
}
@media screen and (min-width: 1122px) and (max-width: 1160px) {
  .card_width {
    /* width: 260px; */
  }
}
@media screen and (min-width: 1160px) and (max-width: 1240px) {
  .card_width {
    /* width: 272px; */
  }
}
@media screen and (min-width: 1240px) and (max-width: 1345px) {
  .card_width {
    /* width: 299px; */
  }
}
@media screen and (min-width: 1345px) and (max-width: 1440px) {
  .card_width {
    /* width: 245px; */
  }

  .creative-box-number #name {
    margin-top: 8px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -1px;
    color: #fff;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 55%;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1700px) {
  .card_width {
    /* width: 250px; */
  }
}

@media screen and (min-width: 1700px) {
  .creative-box {
    /* width: 245px; */
  }
}
@media screen and (min-width: 1900px) {
  .creative-box {
    /* width: 270px !important; */
  }
}

@media screen and (max-width: 600px) {
  .creative-block {
    /* overflow-y: scroll; */
    justify-content: center;
  }

  .scroll-creatives{
    overflow-y: auto;
  }
}
@media screen and (min-width: 960px) and (max-width: 1183px) {
  .creative-box-number #name {
    margin-top: 0px;
  }
  .creative-box-number #icon {
    margin-left: -6px !important;
  }
  .play_count_ellipse {
    width: 22px !important;
  }
}
